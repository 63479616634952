import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { newRecoveryCodes2FA, setNewRecoveryCodes2FAError } from "../../../feature/dashboard/professionalProfile/google2fa/google2faSlice";

function ChangeRecoveryCodes() {
  const dispatch = useDispatch();
  const newRecoveryCodes2FAStates = useSelector((state) => state.google2fa.newRecoveryCodes2FA) || { success: null, error: null, loading: false };
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [isFading,setIsFading] = useState(false);

  const navigate = useNavigate();

  const handleGenerateNewRecoveryCodes = async () => {
    await dispatch(newRecoveryCodes2FA()).then(({ payload }) => {

      setIsFading(true);

      if (payload && payload.recovery_codes) {
        setRecoveryCodes(payload.recovery_codes);
      }
    });
  };

  const handleCopy = () => {
    const text = recoveryCodes.join('\n');
    navigator.clipboard.writeText(text).then(() => {
      alert('Recovery codes copied to clipboard!');
    });
  };

  const handleDownload = () => {
    const text = recoveryCodes.join('\n');
    const element = document.createElement('a');
    const file = new Blob([text], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'fullidcheck-recovery-codes.txt';
    document.body.appendChild(element);
    element.click();
  };

  const handlePrint = () => {
    const text = recoveryCodes.join('\n');
    const newWindow = window.open('', '', 'width=600,height=400');
    newWindow.document.write(`<pre>${text}</pre>`);
    newWindow.document.close();
    newWindow.print();
  };

  useEffect(() => {
    dispatch(newRecoveryCodes2FA()).then(({ payload }) => {

      setIsFading(true);

      if (payload && payload.recovery_codes) {
        setRecoveryCodes(payload.recovery_codes);
      }
    });
  },[dispatch]);

  useEffect(() => {
    if(isFading){
      const timer = setTimeout(() => {
        setIsFading(false);
        setNewRecoveryCodes2FAError(null);
      },5000);

      return () => clearTimeout(timer);
    }
  },[isFading]);

  return (
    <div className="min-h-[89vh]">
      <div className="w-full">
        <div className="self-start tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
          <span
            onClick={() => navigate("/dashboard/account-settings")}
            className="cursor-pointer hover:underline hover:text-blue-500"
          >
            Security Settings
          </span>{" "}
            &gt; Recovery Codes
          </div>
          <div className="mt-10 text-xl font-semibold text-neutral-800 max-sm:mr-auto text-start">
            Two-factor recovery codes
          </div>
          <div className="mt-2 text-base text-zinc-700 text-opacity-80 max-sm:text-sm max-md:text-md">
            Recovery codes can be used to access your account in the event you lose access to your device and cannot receive two-factor authentication codes.
          </div>
          <div className="p-2 mt-2 bg-gray-100 rounded-lg">
            <p className="p-2 text-sm font-bold text-gray-600">
              Keep your recovery codes in a safe spot. These codes are the last resort for accessing your account in case you lose your password and second factors. If you cannot find these codes, you will lose access to your account.
            </p>
          </div>
          <div className="px-5 my-4 border border-gray-200 rounded-2xl">
            <ul className="grid grid-cols-2 gap-2 px-5 mt-4 text-center list-disc">
              {recoveryCodes.map((code, index) => (
                <li key={index} className="p-1 text-sm font-bold text-start">
                  {code}
                </li>
              ))}
            </ul>
            <div className="flex justify-start gap-4 my-4">
              <button onClick={handleDownload} className="flex items-center px-5 py-3 border border-gray-200 rounded-3xl text-devRegBlack">
                <i className="mr-2 fas fa-download"></i>
                <span>Download</span>
              </button>
              <button onClick={handlePrint} className="flex items-center px-5 py-3 border border-gray-200 rounded-3xl text-devRegBlack">
                <i className="mr-2 fas fa-print"></i>
                <span>Print</span>
              </button>
              <button onClick={handleCopy} className="flex items-center px-5 py-3 border border-gray-200 rounded-3xl text-devRegBlack">
                <i className="mr-2 fas fa-copy"></i>
                <span>Copy</span>
              </button>
            </div>
          </div>
          <button
            disabled={newRecoveryCodes2FAStates.loading}
            onClick={handleGenerateNewRecoveryCodes}
            className="w-full px-10 py-3 mt-4 text-white bg-blue-900 sm:w-auto rounded-3xl"
          >
            Generate New Recovery Code
          </button>
          {newRecoveryCodes2FAStates.loading && (
            <div className="my-3 text-center text-sky-600">Generating new recovery codes...</div>
          )}
          {newRecoveryCodes2FAStates.error && isFading && (
            <div className="my-3 text-center text-red-600">{newRecoveryCodes2FAStates.error}</div>
          )}
      </div>
    </div>
  );
}

export default ChangeRecoveryCodes;
