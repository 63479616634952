import NavbarDash from "./Dashboard/NavbarDash";
import SidebarDashboard from "./Dashboard/SidebarDashboard";
import FooterDash from "./Dashboard/FooterDash";

const DashboardScreenWrapper = ({ children }) => {
  return (
    <>
      <div className="flex flex-col h-full w-full">
        <div>
          <NavbarDash />
        </div>
        <div className="flex flex-grow ">
          <SidebarDashboard />
          <div className="w-full pl-10 pr-10 max-sm:pr-4 max-sm:p-4">
            {children}
          </div>
        </div>
        <FooterDash />
      </ div>
    </>
  )
}

export default DashboardScreenWrapper;