import React, { useState, useEffect } from "react";
import StripePayment from "./StripePayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Navigate } from "react-router-dom";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import { auth_api } from "../../api/api";

function Stripe() {
  const { shouldRedirect, redirectTo } = useInspectRoute('/register/process-payment');
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    // Load Stripe asynchronously when the component mounts
    const loadStripeKey = async () => {
      try {
        const data = await auth_api.stripeKey();
        const stripeKey = data.key;
        const stripe = await loadStripe(stripeKey);
        setStripePromise(stripe);
      } catch (error) {
        console.error("Error loading Stripe:", error);
      }
    };

    loadStripeKey();
  }, []);

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />;
  }

  if (!stripePromise) {
    // You can show a loading spinner or handle this case as needed
    return null;
  }

  return (
    <Elements stripe={stripePromise}>
      <StripePayment />
    </Elements>
  );
}

export default Stripe;
