import React from "react";
import profileResgiteredIcon from "../../assets/Profile/profileRegisteredIcon.png";
import ProfilePic from "../../assets/Profile/ProfilePic.jpg";
import trophyProfile from "../../assets/Profile/trophyProfile.png";
import featuredProj from "../../assets/Profile/featuredProj.png";
import ProfileSidePanel from "../../components/ProfileSidePanel";
import usePreviewProfile from "../../feature/dashboard/professionalProfile/profileEdit/previewProfile/usePreviewProfile";
import WorkHistoryItem from "../../components/Dashboard/profileEdit/WorkHistoryItem";
import Bubble from "../../components/Bubble";
import EducationCertItem from "../../components/Dashboard/profileEdit/EducationCertItem";
import HonorAwardsItems from "../../components/Dashboard/profileEdit/HonorAwardsItem";
import { CiCircleMinus, CiCircleCheck } from "react-icons/ci";
import { useRef, useEffect, useState } from "react";
import { FcUp } from "react-icons/fc";
import { Link } from 'react-router-dom';


function Profile() {
  const { success, error, loading,
    user,
    getProfileCover,
    getAllLanguages,
    featuredProject,
    splitKeywords,
    workHistories,
    educationalCert,
    honors,
    socialLinks,
  } = usePreviewProfile();

  const sectionRefs = {
    section0: useRef(null),
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
    section6: useRef(null),
  };

  const [position, setPosition] = useState('');
  const [isSticky, setIsSticky] = useState(false);


  const scrollToSection = (section) => {
    setPosition(sectionRefs[section].current.offsetTop);

    if (section === 'section0') {
      setIsSticky(false);
    } else {
      setIsSticky(true);
    }
  };

  useEffect(() => {
    if (position !== 0) {
      window.scrollTo({ top: position, behavior: 'smooth' });
    }
  }, [position]);

  return (
    <>
      <div className=" max-w-[1366px] mx-auto ">
        {!getProfileCover() &&
          <>
            <div className="flex overflow-hidden relative flex-col justify-center px-8 py-7 mt-4 text-white min-h-[97px] max-w-[1346px] max-md:px-5 max-md:max-w-full m-auto">
              <img
                alt="img"
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/8894b37ebcd17fd7f2eff898d1c991259450ae9e69e79a2ddef3b750e95f966a?"
                className="object-cover absolute inset-0 size-full"
              />
              <div className="flex relative gap-5 max-sm:gap-2 justify-between w-full max-md:flex-wrap max-md:max-w-full">
                <div className="flex gap-5 max-sm:gap-2 justify-between text-sm leading-8 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e0c242a5fccf0f5b24f9ec04447f2e12c1c52db1c703710129f660b0323f769b?"
                    className="shrink-0 max-w-full aspect-[4.76] w-[210px] max-sm:mr-auto max-sm:w-full max-sm:max-w-[232px]"
                  />
                  <div className="my-auto">GOVERNANCE IN PREVENTING FRAUD</div>
                </div>
                <div className="my-auto text-2xl font-semibold max-sm:text-xl">
                  My Profile
                </div>
              </div>
            </div>
            <div className="m-auto justify-center items-center px-16 pt-24 pb-20 w-full text-2xl text-center text-white bg-[#20396B] max-w-[1346px] max-md:px-5 max-md:max-w-full max-sm:text-base">
              Profile Cover / Ad Space (1000px x 200px)
            </div>
          </>
        }

        {getProfileCover() &&
          <img
            alt="img"
            loading="lazy"
            src={getProfileCover().upload_path}
            className="m-auto justify-center items-center w-full text-2xl text-center text-white bg-[#20396B] max-w-[1346px] max-md:px-5 max-md:max-w-full max-sm:text-base"
          />
        }

        {/* Profile Info */}
        <div className="justify-between py-9 w-full bg-white rounded-3xl max-w-[1366px] px-10 max-md:max-w-full m-auto">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[57%] max-md:ml-0 max-md:w-full">
              <div className="max-md:mt-9 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                  <div className="flex flex-col w-[22%] max-md:ml-0 max-md:w-full">
                    <img
                      alt="img"
                      loading="lazy"
                      srcSet={user.profile_photo_url || ProfilePic}
                      className="shrink-0 max-w-full rounded-full aspect-square w-[150px] max-md:mt-4 my-auto "
                    />
                  </div>
                  <div className="flex flex-col ml-5 w-[78%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow self-stretch max-md:mt-4 max-md:max-w-full">
                      <div className="flex gap-4 max-sm:gap-1 max-md:flex-wrap">
                        <div className="text-3xl font-semibold leading-10 text-neutral-800 max-sm:text-center">
                          {`${user.fullname} ${user.aka ? `(${user.aka})` : ''}`}
                        </div>
                        <div className="flex gap-1 justify-center px-2 py-1 my-auto text-sm	 text-blue-400 border border-blue-300 border-solid bg-blue-400 bg-opacity-20 rounded-[100px]">
                          <CiCircleCheck className="text-xl my-auto w-4 aspect-square align-middle" />
                          <div className="align-middle pr-1">Pro Member</div>
                        </div>
                      </div>
                      <div className="text-xl leading-8 text-neutral-600 max-md:max-w-full">
                        {user.position || 'Full Stack Developer'}
                      </div>
                      <div className="flex gap-2 mt-1 text-xl leading-8 text-neutral-600 max-md:flex-wrap">
                        <img
                          alt="img"
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4be2fc51c9027ef48302f9b839e4135b0ef34d05ce4f5a81661fe4d87c137765?"
                          className="shrink-0 my-auto w-4 aspect-square"
                        />
                        <div className="max-md:max-w-full text-start">
                          {`${user.city}, ${user.state}, ${user.country}, ${user.postal_code}`}
                        </div>
                      </div>
                      <div className="flex gap-2 mt-1 text-xl leading-8 text-neutral-600 max-md:flex-wrap">
                        <img
                          alt="img"
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8067e891d8e7d65732af70f1091364baf9638a24f25689077c35e0a457be2fe?"
                          className="shrink-0 my-auto w-4 aspect-square"
                        />
                        <div className="max-md:max-w-full">
                          {`I speak ${getAllLanguages()}`}
                        </div>
                      </div>
                      <div className="flex gap-2 mt-1 text-xl leading-8 text-neutral-600 max-md:flex-wrap">
                        <img
                          alt="img"
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8067e891d8e7d65732af70f1091364baf9638a24f25689077c35e0a457be2fe?"
                          className="shrink-0 my-auto w-4 aspect-square"
                        />
                        <div className="max-md:max-w-full">
                          {`${user.salary_rate_currency} ${user.salary_rate}/${user.salary_rate_frequency} (hidden)`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[43%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow items-end pl-20 max-sm:pl-0 max-md:mt-9 max-md:max-w-full">
                <img
                  alt="img"
                  loading="lazy"
                  srcSet={profileResgiteredIcon}
                  className="max-w-full aspect-[1.64] w-[152px] max-sm:mr-auto"
                />
                <div className="mt-2.5 text-xl font-semibold leading-8 text-sky-600 max-sm:mx-auto">
                  {user.serial_number}
                </div>

                <div className="flex flex-wrap items-center justify-center gap-4 pr-3.5 mt-10 max-sm:mx-auto">
                  {
                    socialLinks.instagram.link !== '' &&
                    <a href={socialLinks.instagram.link} target="_blank" rel="noopener noreferrer">
                      <img
                        alt="img"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1d7e3633d257a7a7e332af5855b08b3bfd4cc547dd711e3078ddf2eb6e2ae2ae?"
                        className="shrink-0 w-6 aspect-square"
                      />
                    </a>
                  }

                  {socialLinks.facebook.link !== '' &&
                    <a href={socialLinks.facebook.link} target="_blank" rel="noopener noreferrer">
                      <img
                        alt="img"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ef7d187c435b319a4d45266d8072a816b3823db115ef1616858f850369e12e7?"
                        className="shrink-0 self-start w-6 aspect-[1.04]"
                      />
                    </a>
                  }

                  {socialLinks.linkedIn.link !== '' &&
                    <a href={socialLinks.linkedIn.link} target="_blank" rel="noopener noreferrer">
                      <img
                        alt="img"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b1004beebd7f5c42c5945575d17b03265ae09c02b044c20d167d690203913db5?"
                        className="shrink-0 w-6 aspect-square"
                      />
                    </a>
                  }

                  {socialLinks.youtube.link !== '' &&
                    <a href={socialLinks.youtube.link} target="_blank" rel="noopener noreferrer">
                      <img
                        alt="img"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a7b594e53a34401e179038ba3b890886f32acd350f572ec9ffc25ba70991b73b?"
                        className="shrink-0 my-auto w-6 aspect-[1.41]"
                      />
                    </a>
                  }

                  {socialLinks.discord.link !== '' &&
                    <a href={socialLinks.discord.link} target="_blank" rel="noopener noreferrer">
                      <img
                        alt="img"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c01eff91790244c9d1897a0ad8dd370f731f543b57cadcac817d10352c7f5894?"
                        className="shrink-0 my-auto w-6 aspect-[1.27]"
                      />
                    </a>
                  }

                  {socialLinks.tiktok.link !== '' &&
                    <a href={socialLinks.tiktok.link} target="_blank" rel="noopener noreferrer">
                      <img
                        alt="img"
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/bbb2e53d8c766350182b7229306fbd6d264bcbc85d009bfc4e467ecd6069ff57?"
                        className="shrink-0 w-6 aspect-square"
                      />
                    </a>
                  }
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* ---------------------------------------- */}
        <div className="justify-end self-center mt-1 mb-[200px] w-full m-auto max-w-[1366px] max-md:mt-1 max-md:max-w-full ">
          <div className="flex gap-0 max-md:flex-col max-md:gap-0 ">
            <div className="flex flex-col w-[80%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow self-stretch px-2 max-md:mt-10 max-md:max-w-full">
                {/* Menu */}
                <div ref={sectionRefs.section0} className="m-auto flex gap-5 justify-between w-full py-2 pr-2 max-w-full text-xl leading-8  border-b border-solid border-neutral-400 text-neutral-700  max-md:flex-wrap">
                  <div className="font-bold text-blue-900"><button onClick={() => scrollToSection('section1')}>Overview</button></div>
                  <div><button onClick={() => scrollToSection('section6')}>Features</button></div>
                  <div><button onClick={() => scrollToSection('section2')}>Work History</button></div>
                  <div><button onClick={() => scrollToSection('section3')}>Skill & Expertise</button></div>
                  <div><button onClick={() => scrollToSection('section4')}>Education</button></div>
                  <div className="max-md:max-w-full" onClick={() => scrollToSection('section5')}><button>Awards</button></div>
                </div>

                {/* INFO */}
                <div className="flex gap-3 justify-between max-md:flex-wrap mt-5 items-center">
                  <div className="flex gap-2 items-center">
                    <img
                      alt="img"
                      loading="lazy"
                      src={trophyProfile}
                      className="shrink-0 my-auto w-9 border-2 border-solid aspect-square border-neutral-400 stroke-[2px] stroke-neutral-400"
                    />
                    <div className="flex-col leading-4 ">
                      <div className="flex-1 font-bold text-neutral-500">
                        {`${user.year_of_experience || 0} Years`}
                      </div>
                      <div className="flex-1 text-neutral-300">
                        Work Experienced
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <img
                      alt="img"
                      loading="lazy"
                      src={trophyProfile}
                      className="shrink-0 my-auto w-9 border-2 border-solid aspect-square border-neutral-400 stroke-[2px] stroke-neutral-400"
                    />
                    <div className="flex-col leading-4 ">
                      <div className="flex-1 font-bold text-neutral-500">
                        {`${user.projects_completed || 0} Projects`}
                      </div>
                      <div className="flex-1 text-neutral-300">Completed</div>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img
                      alt="img"
                      loading="lazy"
                      src={trophyProfile}
                      className="shrink-0 my-auto w-9 border-2 border-solid aspect-square border-neutral-400 stroke-[2px] stroke-neutral-400"
                    />
                    <div className="flex-col leading-4 ">
                      <div className="flex-1 font-bold text-neutral-500">
                        {`${user.honorsAndAwards.length || 0}`} Awards
                      </div>
                      <div className="flex-1 text-neutral-300">
                        Achievements
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <img
                      alt="img"
                      loading="lazy"
                      src={trophyProfile}
                      className="shrink-0 my-auto w-9 border-2 border-solid aspect-square border-neutral-400 stroke-[2px] stroke-neutral-400"
                    />
                    <div className="flex-col leading-4 ">
                      <div className="flex-1 font-bold text-neutral-500">
                        {`${user.educationAndCertifications.length || 0}`} Certificates
                      </div>
                      <div className="flex-1 text-neutral-300">
                        Accomplishment
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-4">
                  {/* ABOUT USER */}
                  <div ref={sectionRefs.section1} className="flex flex-col max-md:mt-2 py-4 mt-6 bg-white rounded-xl max-md:max-w-full">
                    <div className="text-3xl font-semibold leading-10 text-neutral-800 max-md:max-w-full">
                      {`About ${user.first_name}`}
                    </div>
                    <div className="mt-3 text-base leading-7 text-neutral-600 max-md:max-w-full">
                      {`${user.about}`}
                    </div>
                  </div>

                  {/* Featured Project */}
                  {featuredProject !== null &&
                    <div ref={sectionRefs.section6} className="flex flex-col py-4 mt-6 text-3xl leading-10 bg-white rounded-xl text-neutral-800 max-md:max-w-full">
                      <div className="font-semibold max-md:max-w-full">Features</div>
                      <a href={featuredProject?.link || '#'}>
                        <img
                          alt="img"
                          loading="lazy"
                          srcSet={featuredProject?.banner || featuredProj}
                          className="mt-2 mb-5 w-full aspect-[2.38] max-md:max-w-full"
                        />
                      </a>
                      <div className="font-semibold max-md:max-w-full">{featuredProject?.title || ''}</div>
                      <div className="mt-3 text-base leading-7 text-neutral-600 max-md:max-w-full">
                        {`${featuredProject?.content || ''}`}
                      </div>
                      <div className="mt-3 text-base leading-7 text-neutral-600 max-md:max-w-full">
                        {splitKeywords?.length > 0 && `Keywords:`}
                        {splitKeywords?.map((item) => {
                          return <div className="inline-block justify-center mx-1 px-3 rounded-2xl bg-neutral-100">{item}</div>
                        })}
                      </div>
                    </div>
                  }

                  {/* Work History */}
                  {workHistories !== null &&
                    <div ref={sectionRefs.section2} className="flex flex-col py-4 mt-6 w-full m-auto">
                      <div className="text-3xl font-semibold leading-10 text-neutral-800 max-md:max-w-full">
                        Work history
                      </div>

                      {workHistories?.map((item, index) => {
                        return <WorkHistoryItem data={item} index={index} showControls={false} />
                      })}
                    </div>
                  }

                  {/* SKILL EXPERTISE */}
                  {user?.skillsAndExpertise?.length > 0 &&
                    <div ref={sectionRefs.section3} className="flex flex-col items-start py-4 mt-6 text-base leading-6 bg-white rounded-xl text-neutral-600 max-md:max-w-full">
                      <div className="self-stretch text-3xl font-semibold text-neutral-800 max-md:max-w-full">
                        Skills & Expertise
                      </div>
                      <div className="flex flex-wrap mt-3">
                        {
                          user.skillsAndExpertise.map((item, index) => {
                            return <Bubble showControls={false} title={item.name} />
                          })
                        }
                      </div>
                    </div>
                  }

                  {/* Educational Cert */}
                  {educationalCert !== null &&
                    <div ref={sectionRefs.section4} className="flex flex-col py-4 mt-6 text-base leading-6 bg-white rounded-xl text-neutral-600 max-md:max-w-full">
                      <div className="text-3xl font-semibold text-neutral-800 max-md:max-w-full">
                        Educational & Certification
                      </div>
                      {educationalCert?.map((item, index) => {
                        return <EducationCertItem data={item} index={index} showControls={false} />
                      })}
                    </div>
                  }

                  {/* HONOR AWAWRDS */}
                  {honors !== null &&
                    <div ref={sectionRefs.section5} className="flex flex-col py-4 mt-6 text-base leading-6 bg-white rounded-xl text-neutral-600 max-md:max-w-full">
                      <div className="text-3xl font-semibold text-neutral-800 max-md:max-w-full">
                        Honors & awards
                      </div>
                      {honors?.map((item, index) => {
                        return <HonorAwardsItems data={item} index={index} showControls={false} />
                      })}
                    </div>
                  }
                  <div
                    className={`fixed right-4 bottom-4 lg:bottom-10 bg-blue-100 bg-opacity-50 text-white px-4 py-2 border border-solid border-blue-500	rounded-full transition-transform transform ${(isSticky) ? 'sticky' : 'hidden'
                      }`}
                    style={{
                      position: isSticky ? 'fixed' : 'absolute',
                    }}
                  >
                    <FcUp size={30} onClick={() => scrollToSection('section0')} />
                  </div>
                </div>

              </div>
            </div>
            <ProfileSidePanel data={
              {
                availability: user.availability || '',
                work_arrangement: user.work_setup_preferences || '',
                work_arrangement_other: user.work_arrangement_other,
                languages: user.languages,
              }
            } />
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
