import React, { useState, useEffect } from "react";
import useWorkHistory from "../../../feature/dashboard/professionalProfile/profileEdit/workHistory/useWorkHistory";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import WorkHistoryItem from "../../../components/Dashboard/profileEdit/WorkHistoryItem";
import dateHelper from "../../../utils/dateHelper";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";


function WorkHistory() {

  const {
    success, error, loading,
    showAddButton, showEditButton,
    displayedWorkHistories,
    postWorkHistory, setPostWorkHistory,
    currentWorkProjects, screenshots, handleAddContainer, handlePopulateContainer, handleProjectImages,
    thumbnail,
    work_arrangements,
    handleOnload,
    handleAddbutton,
    handleEditButton, handleUpdateButton,
    handleRemoveButton,
    handleImageUpload,
    validationErrorMessage, showNotif, isSafeToSubmit,
    validateCompany, validatePostion, validateStarted, validateEnded, validateWorkArrangement,
    validateDescription, validateProjectTitle, validateProjectCategory
  } = useWorkHistory();

  useEffect(() => {
    handleOnload();
  }, []);

  return (
    <>
      {/* ADD WORK SECTION */}
      <div className="flex flex-col pb-6 border-b border-solid border-neutral-300 max-md:max-w-full">
        <div className="flex justify-end h-4">
          {
            showNotif && (
              <>
                {!loading && success && <SuccessIndicator message='Account updated.' />}
                {!loading && !success && error && <FailureIndicator message={error || 'Account not updated.'} />}
              </>
            )
          }
        </div>

        {/* ADD company logo */}
        <div className="mt-4 max-md:max-w-full ">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex relative flex-col w-[16%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-center p-4 text-sm leading-4 text-center rounded-2xl border border-solid border-neutral-300 text-stone-300 max-md:mt-4">
                <img alt="img"
                  loading="lazy"
                  src={thumbnail || postWorkHistory.company_logo || "https://cdn.builder.io/api/v1/image/assets/TEMP/176be2ea66493ab805bd3d9e5c7cd1f706a0de3766487bde8e82a9332aba88cd?"}
                  className="self-center aspect-[0.95]"
                />
                {!thumbnail && !postWorkHistory.company_logo && <div>Company Logo</div>}
              </div>
              <input type="file"
                accept="image/*"
                value=""
                onChange={handleImageUpload}
                className="opacity-0  absolute top-0 left-0 w-full h-full cursor-pointer"
              />
            </div>

            {/* ---------------- */}
            <div className="flex flex-col ml-5 w-[84%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col justify-center self-stretch my-auto text-base leading-5 placeholder-text-stone-300 max-md:mt-5 max-md:max-w-full">
                <input
                  placeholder="Name of Company"
                  value={postWorkHistory.company_name || ''}
                  autoComplete="organization"
                  onChange={(e) => {
                    setPostWorkHistory({ ...postWorkHistory, company_name: e.target.value });
                    validateCompany(e.target.value);
                  }}
                  className="justify-center
               p-4 rounded-2xl border border-solid border-neutral-300 max-md:max-w-full"
                />
                <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.company?.errorMessage} />

                <div className="flex gap-4 items-center mt-4 max-md:flex-wrap max-md:max-w-full">
                  <div className="flex flex-wrap flex-1 gap-4 content-start">
                    <div className="flex flex-col">
                      <label className="text-[13px]"> Postion</label>
                      <input
                        placeholder="Position"
                        value={postWorkHistory.position || ''}
                        autoComplete="organization-title"
                        onChange={(e) => {
                          setPostWorkHistory({ ...postWorkHistory, position: e.target.value });
                          validatePostion(e.target.value);
                        }}
                        className="px-4 py-2 rounded-2xl border border-solid border-neutral-300"
                      />
                      <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.position?.errorMessage} />

                    </div>
                    <div className="flex flex-col">
                      <label className="text-[13px]"> Date Started</label>
                      <input
                        type="date"
                        placeholder="Date Started"
                        autoComplete="on"
                        value={
                          dateHelper.isDatetimeCheckerNewApi(postWorkHistory.date_started) ? dateHelper.datetimeToInputDateNewApi(postWorkHistory.date_started) : postWorkHistory.date_started
                        }
                        onChange={(e) => {
                          setPostWorkHistory({ ...postWorkHistory, date_started: e.target.value });
                          validateStarted(e.target.value);
                        }}
                        className="  px-4 py-2 rounded-2xl border border-solid border-neutral-300 "
                      />
                      <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.started?.errorMessage} />
                    </div>

                    <div className="flex flex-col">
                      <label className="text-[13px]"> Date Ended</label>
                      <input
                        type="date"
                        placeholder="Date Ended"
                        autoComplete="on"
                        value={dateHelper.isDatetimeCheckerNewApi(postWorkHistory.date_ended) ? dateHelper.datetimeToInputDateNewApi(postWorkHistory.date_ended) : postWorkHistory.date_ended}
                        onChange={(e) => {
                          setPostWorkHistory({ ...postWorkHistory, date_ended: e.target.value });
                          validateEnded(e.target.value);
                        }}
                        className="  px-4 py-2 rounded-2xl border border-solid border-neutral-300 "
                      />
                      <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.ended?.errorMessage} />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
          Work Arrangement
        </div>
        <div
          className="flex gap-5 pr-2 mt-4 text-base leading-5 whitespace-nowrap text-stone-300 max-md:flex-wrap max-md:pr-5"
        >
          <div
            className="flex gap-2 py-0.5 cursor-pointer"
            onClick={() => setPostWorkHistory({ ...postWorkHistory, work_arrangement: work_arrangements[0].value })}
          >
            <div className={`shrink-0 w-4 h-4 rounded-full ${postWorkHistory.work_arrangement === work_arrangements[0].value ? "bg-teal-400" : "bg-zinc-300"}`} />
            <div
              className={`my-auto ${postWorkHistory.work_arrangement === work_arrangements[0].value
                ? "font-bold text-devRegBlack"
                : "font-normal text-stone-300"
                }`}
            >
              Freelance
            </div>
          </div>
          <div
            className="flex gap-2 py-0.5 cursor-pointer "
            onClick={() => setPostWorkHistory({ ...postWorkHistory, work_arrangement: work_arrangements[1].value })}
          >
            <div
              className={`shrink-0 w-4 h-4  rounded-full ${postWorkHistory.work_arrangement === work_arrangements[1].value ? "bg-teal-400" : "bg-zinc-300"
                }`}
            />
            <div
              className={`my-auto ${postWorkHistory.work_arrangement === work_arrangements[1].value
                ? "font-bold text-devRegBlack"
                : "font-normal text-stone-300"
                }`}
            >
              Fulltime
            </div>
          </div>
          <div
            className="flex gap-2 py-0.5 cursor-pointer "
            onClick={() => setPostWorkHistory({ ...postWorkHistory, work_arrangement: work_arrangements[2].value })}
          >
            <div
              className={`shrink-0 w-4 h-4  rounded-full ${postWorkHistory.work_arrangement === work_arrangements[2].value ? "bg-teal-400" : "bg-zinc-300"
                }`}
            />
            <div
              className={`my-auto ${postWorkHistory.work_arrangement === work_arrangements[2].value
                ? "font-bold text-devRegBlack"
                : "font-normal text-stone-300"
                }`}
            >
              Part-time
            </div>
          </div>
          <div
            className="flex gap-2 py-0.5 cursor-pointer "
            onClick={() => setPostWorkHistory({ ...postWorkHistory, work_arrangement: work_arrangements[3].value })}
          >
            <div
              className={`shrink-0 w-4 h-4  rounded-full ${postWorkHistory.work_arrangement === work_arrangements[3].value ? "bg-teal-400" : "bg-zinc-300"
                }`}
            />
            <div
              className={`my-auto ${postWorkHistory.work_arrangement === work_arrangements[3].value
                ? "font-bold text-devRegBlack"
                : "font-normal text-stone-300"
                }`}
            >
              Contract
            </div>
          </div>
          <div
            className="flex gap-2 py-0.5 cursor-pointer "
            onClick={() => setPostWorkHistory({ ...postWorkHistory, work_arrangement: work_arrangements[4].value })}
          >
            <div
              className={`shrink-0 w-4 h-4  rounded-full ${postWorkHistory.work_arrangement === work_arrangements[4].value ? "bg-teal-400 " : "bg-zinc-300"
                }`}
            />
            <div
              className={`my-auto ${postWorkHistory.work_arrangement === work_arrangements[4].value
                ? "font-bold text-devRegBlack"
                : "font-normal text-stone-300"
                }`}
            >
              Others
            </div>
          </div>
        </div>
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.workArrangement?.errorMessage} />

        <div className="mt-4 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
          Job Description
        </div>
        <textarea
          placeholder="Responsibilities"
          autoComplete="on"
          rows="5"
          value={postWorkHistory.job_description}
          onChange={(e) => {
            setPostWorkHistory({ ...postWorkHistory, job_description: e.target.value });
            validateDescription(e.target.value);
          }}
          className="justify-center
            items-start px-4 pt-4 pb-14 mt-4 text-base leading-5
            rounded-2xl border border-solid border-neutral-300 placeholder-text-stone-300 
            max-md:pr-5 max-md:max-w-full"
        />
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.description?.errorMessage} />

        <div className="flex gap-4 self-start mt-4 text-base tracking-tight leading-4">
          <div className="text-neutral-600">Add Projects / Portfolio</div>
          <button onClick={() => handleAddContainer()} className="font-bold text-sky-600">
            Add More
          </button>
        </div>

        {/* ADD MORE WORK FORM */}
        {currentWorkProjects.map((container, index) => (
          <div key={index} className="mt-4 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow text-base leading-5 placeholder-text-stone-300 max-md:mt-4">
                  <input
                    placeholder="Project title"
                    type="text"
                    autoComplete="on"
                    value={currentWorkProjects[index].title}
                    onChange={(e) => {
                      handlePopulateContainer(index, "title", e.target.value);
                      validateProjectTitle(e.target.value);
                    }}
                    className="justify-center items-start p-4 rounded-2xl border border-solid border-neutral-300 max-md:pr-5"
                  />
                  <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.projectTitle?.errorMessage} />

                  <input
                    placeholder="Category"
                    type="text"
                    autoComplete="on"
                    value={currentWorkProjects[index].category}
                    onChange={(e) => {
                      handlePopulateContainer(index, "category", e.target.value);
                      validateProjectCategory(e.target.value);
                    }}
                    className="justify-center items-start p-4 mt-4 whitespace-nowrap rounded-2xl border border-solid border-neutral-300 max-md:pr-5"
                  />
                  <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.projectCategory?.errorMessage} />

                </div>
              </div>
              <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full relative">
                <div className="grow flex flex-col justify-center items-center self-stretch p-2.5 text-lg leading-6 text-center rounded-2xl border border-solid border-neutral-300 text-stone-300 max-md:px-5 max-md:mt-4">
                  <img
                    alt="img"
                    loading="lazy"
                    src={screenshots[index]?.imageSrc || "https://cdn.builder.io/api/v1/image/assets/TEMP/176be2ea66493ab805bd3d9e5c7cd1f706a0de3766487bde8e82a9332aba88cd?"}
                    className="self-center"
                  />
                  {(!screenshots[index] || !screenshots[index].imageSrc) &&
                    <div>
                      Drag or Upload images here
                      <br />
                      <span className="text-base">{`(Image size: 1024px x 687px)`}</span>
                      <span className="text-base">{`PNG, JPG up to 2MB`}</span>
                    </div>
                  }
                </div>
                <input
                  type="file"
                  accept="image/*"
                  value=""
                  onChange={(e) => handleProjectImages(e, index)}
                  className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                />

              </div>
            </div>
          </div>
        ))}

        {/* Submit */}
        {showAddButton &&
          <div className="flex flex-col justify-center items-start self-end px-16 py-2 mt-10 max-w-full text-base font-semibold text-white w-[309px] max-md:pl-5">
            <button className={`${isSafeToSubmit === true ? '' : 'disabled'} justify-center whitespace-nowrap px-6 py-3 bg-devRegDarkBlue rounded-2xl max-md:px-5 max-md:ml-2`}
              onClick={() => handleAddbutton()}
            >
              Add Work Experience
            </button>
            {
              showNotif && (
                <>
                  {loading && (<LoadingIndicator loading={loading} styling='px-16 max-md:pl-5' />)}
                </>
              )
            }
          </div>
        }

        {showEditButton &&
          <div className="flex flex-col justify-center items-start self-end px-16 py-2 mt-10 max-w-full text-base font-semibold text-white w-[309px] max-md:pl-5">
            <button className={`${isSafeToSubmit === true ? '' : 'disabled'} justify-center whitespace-nowrap px-6 py-3 bg-devRegDarkBlue rounded-2xl max-md:px-5 max-md:ml-2`}
              onClick={() => handleUpdateButton()}
            >
              Update Work Experience
            </button>
            {
              showNotif && (
                <>
                  {loading && (<LoadingIndicator loading={loading} styling='px-16 max-md:pl-5' />)}
                </>
              )
            }
          </div>
        }


      </div>

      {/* WORK PREVIEW */}
      <div className="flex flex-col p-4 max-md:max-w-full pb-[250px]">
        {displayedWorkHistories?.length > 0 && <div className="text-3xl font-semibold leading-10 text-neutral-800 max-md:max-w-full">
          My Work history
        </div>}

        {
          displayedWorkHistories?.map((item, index) => {
            return <WorkHistoryItem data={item} index={index} onEdit={handleEditButton} onDelete={handleRemoveButton} />
          })
        }

      </div>

    </>
  );
}

export default WorkHistory;
