import { basicAxios, authAxios, fetchCsrfToken } from "../config/common";

export const auth_api = {
	login: async (email, password, code) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append('email', email);
			formData.append('password', password);
			if (code) formData.append('code', code);

			return basicAxios.post('/api/login', formData)
				.then(response => JSON.parse(response.data))
				.then(data => {
					if (data.errors) {
						reject(data);
					} else {
						resolve(data);
					}
				}).catch(err => {
					reject(err);
				});

		});
	},
	resendLink: async () => {
		return new Promise((resolve, reject) => {
			return authAxios().post('/api/email/verification-notification').then(response => JSON.parse(response.data)).then(data => {
				resolve(data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	logout: async () => {
		return new Promise((resolve, reject) => {
			return authAxios().post('/api/logout').then(response => response.data).then(data => {
				resolve(data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	register: async (pData) => {

		return new Promise((resolve, reject) => {
			const keys = Object.keys(pData);
			const formData = new FormData();
			keys.forEach(item => {
				formData.append(item, pData[item]);
			});

			return basicAxios.post('/api/register', formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors) {
					reject(data);
				} else {
					resolve(data);
				}
			}).catch(err => {
				reject(err);
			});

		});
	},
	personalInfo: async (id, data) => {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(data);
			const formData = new FormData();
			keys.forEach(item => {
				formData.append(item, data[item]);
			});
			return authAxios().post(`/api/profile`, formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	emergencyContact: async (id, data) => {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(data);
			const formData = new FormData();
			keys.forEach(item => {
				formData.append(item, data[item]);
			});
			return authAxios().post(`/api/emergency-contacts`, formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},

	stripeKey: async () => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/subscriptions/key`).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},

	processPayment: async (id, data) => {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(data);
			const formData = new FormData();
			keys.forEach(item => {
				formData.append(item, data[item]);
			});
			return authAxios().post(`/api/subscriptions/registration-payment`, formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	validateCoupon: async (id, data) => {
		return new Promise((resolve, reject) => {
			return authAxios().post(`/api/coupon-codes/${data.code}/validate`).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	membershipValues: async (id) => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/user/${id}/membership-values`)
				.then(response => JSON.parse(response.data)).then(data => {
					if (data.errors)
						reject(data);
					else
						resolve(data);
				}).catch(err => {
					reject(err);
				})
		});
	},
	uploadDocument: async (id, data) => {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(data);
			const formData = new FormData();
			keys.forEach(item => {
				formData.append(item, data[item]);
			});
			return authAxios().post(`/api/documents`, formData)
				.then(response => JSON.parse(response.data)).then(data => {
					if (data.errors) {
						reject(data);
					}
					else {
						resolve(data);
					}
				}).catch(err => {
					reject(err);
				})
		});
	},
	getDocuments: async () => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/documents`)
				.then(response => JSON.parse(response.data)).then(data => {
					if (data.errors) {
						reject(data);
					}
					else {
						resolve(data);
					}
				}).catch(err => {
					reject(err);
				})
		});
	},
	deleteDocument: async (id, data) => {
		return new Promise((resolve, reject) => {
			return authAxios().delete(`/api/documents/${data.id}`).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	compileDocuments: async (id, data) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			data.ids.forEach(item => {
				formData.append('ids[]', item);
			});
			return authAxios().post(`/api/documents/compile`, formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	qrCode: async (id, data) => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/qr-code?used_for=${data.used_for}`).then(response => response.data).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	faceRecognitionStatus: async (id, data) => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/face-recognition/status`).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	scanAndVerify: async (data) => {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(data);
			const formData = new FormData();
			keys.forEach(item => {
				if (item === 'images') {
					for (let x = 0; x < data[item].length; x++) {
						formData.append('images[]', data[item][x]);
					}
				} else {
					formData.append(item, data[item]);
				}
			});
			return basicAxios.post(`/api/face-verify`, formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	scheduleInterview: async (id, data) => {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(data);
			const formData = new FormData();
			keys.forEach(item => {
				formData.append(item, data[item]);
			});
			return authAxios().post(`/api/interview-schedules`, formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	countryManagers: async () => {
		return new Promise((resolve, reject) => {
			return basicAxios.get('/api/country-managers').then(response => JSON.parse(response.data)).then(data => {
				if (data.errors) {
					reject(data);
				} else {
					resolve(data);
				}
			}).catch(err => {
				reject(err);
			});

		});
	},
	getProfileInfo: async (id) => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/user/${id}/profile`)
				.then(response => JSON.parse(response.data)).then(data => {
					if (data.errors)
						reject(data);
					else
						resolve(data);
				}).catch(err => {
					reject(err);
				})
		});
	},
	downloadCertificate: async (id, data) => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/user/${id}/download-certificate`).then(response => response.data).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
}