import React, { useEffect, useRef, useState } from "react";
import BacktoLogin from "./BacktoLogin";
import UpdatePassword from "./UpdatePassword";
import { useDispatch, useSelector } from "react-redux";
import {
  resendOTPViaEmailNoLogin,
  setResendOTPViaEmailNoLoginSuccess,
  setVerifyOTPPasswordResetSuccess,
  verifyOTPPasswordReset,
} from "../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";
import Loader from "../../components/Loader";

function TwoStepVerification({ email }) {
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const otpRefs = useRef([]);
  const [isFading, setIsFading] = useState(false);
  const [ismodalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const resendOTPViaEmailNoLoginStates = useSelector(
    (state) => state.settings.resendOTPViaEmailNoLogin
  ) || { success: null, error: null, loading: false };
  const verifyOTPPasswordResetStates = useSelector(
    (state) => state.settings.verifyOTPPasswordReset
  ) || { success: null, error: null, loading: false };

  useEffect(() => {
    if (
      verifyOTPPasswordResetStates.success === "Verification code is valid!"
    ) {
      setShowUpdatePassword(true);
      dispatch(setVerifyOTPPasswordResetSuccess(null));
    }

    if (resendOTPViaEmailNoLoginStates.success === "OTP sent") {
      setIsFading(true);
      if (isFading) {
        const timerResend = setTimeout(() => {
          setIsFading(false);
          dispatch(setResendOTPViaEmailNoLoginSuccess(null));
        }, 5000);
        return () => clearTimeout(timerResend);
      }
    }

    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [
    verifyOTPPasswordResetStates.success,
    resendOTPViaEmailNoLoginStates.success,
    isFading,
    dispatch,
  ]);

  const handleResendOTPViaEmailNoLoginClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        resendOTPViaEmailNoLogin({
          data: { email },
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];

    if (e.key === "Backspace") {
      if (newOtp[index] === "") {
        if (index > 0) {
          otpRefs.current[index - 1].focus();
        }
      } else {
        newOtp[index] = "";
      }
    } else if (/^[0-9]$/.test(value)) {
      newOtp[index] = value;
      if (index < 5) {
        otpRefs.current[index + 1].focus();
      }
    }

    setOtp(newOtp);
  };

  const handleOtpPaste = (event) => {
    const pasteData = event.clipboardData
      .getData("text")
      .slice(0, 6)
      .split("")
      .filter((char) => /^[0-9]$/.test(char));
    const newOtp = [...otp];

    pasteData.forEach((char, index) => {
      newOtp[index] = char;
      if (otpRefs.current[index]) {
        otpRefs.current[index].value = char;
      }
    });

    setOtp(newOtp);

    const nextIndex = pasteData.length < 6 ? pasteData.length : 5;
    otpRefs.current[nextIndex].focus();
  };

  const renderOtpInputFields = () => (
    <div className="flex flex-row max-w-full gap-4 pr-3 mt-4">
      {[...Array(6)].map((_, i) => (
        <input
          key={i}
          type="text"
          maxLength="1"
          className="flex-grow border border-solid border-neutral-400 h-[58px] min-w-5 text-center rounded-md"
          value={otp[i]}
          onChange={(e) => handleOtpChange(e, i)}
          onKeyDown={(e) => handleOtpChange(e, i)}
          onPaste={handleOtpPaste}
          ref={(el) => (otpRefs.current[i] = el)}
        />
      ))}
    </div>
  );

  const handleVerifyOTPClick = async (e) => {
    e.preventDefault();

    setIsModalOpen(true);

    try {
      await dispatch(
        verifyOTPPasswordReset({
          data: {
            email,
            code: otp.join(""),
          },
        })
      );
    } catch (err) {
      console.error("Error verifying OTP:", err);
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      {!showUpdatePassword && (
        <div className="absolute flex flex-col items-center justify-center min-w-full min-h-screen py-12 bg-sky-600">
          <BacktoLogin />
          <div className="flex flex-col p-3 mx-3 md:mx-0 md:p-12 my-3 max-w-[420px] w-full bg-white rounded-3xl shadow-sm">
            <img
              alt="img"
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/0515c2622ed9199edf94bde5a446c72d0f096dd64c6ce9f50af8b84b945c06d4?apiKey=3ae96302e69649f5be368f89230b0596&"
              className="self-center w-full aspect-[4.17]"
            />
            <div className="mt-10 text-xl font-semibold text-neutral-800 max-sm:mr-auto text-start">
              Two Step Verification
            </div>
            <div className="mt-2 text-base text-zinc-700 text-opacity-80 max-sm:text-sm max-md:text-md">
              We sent a verification code to your email. Enter the code from the
              mobile in the field below.
            </div>
            {renderOtpInputFields()}
            <button
              onClick={handleVerifyOTPClick}
              disabled={verifyOTPPasswordResetStates.loading}
              className="items-center justify-center py-3 mt-4 text-base font-semibold text-center text-white bg-blue-900 cursor-pointer px-9 whitespace-nowrap rounded-3xl max-md:px-5"
            >
              Submit
            </button>

            {verifyOTPPasswordResetStates.loading && (
              <div className="my-3 text-center text-blue-600">
                Processing...
              </div>
            )}
            {verifyOTPPasswordResetStates.error && (
              <div className="my-3 text-center text-red-600">
                {verifyOTPPasswordResetStates.error}
              </div>
            )}

            <div className="self-center mt-4 text-base text-center text-zinc-700">
              <span>Didn’t get the email?&nbsp;</span>
              <button
                id="resend-new-otp-link"
                onClick={handleResendOTPViaEmailNoLoginClick}
                className="underline cursor-pointer text-sky-600"
                disabled={resendOTPViaEmailNoLoginStates.loading}
              >
                Resend
              </button>
            </div>
            <div className="mt-2 text-center max-sm:mr-auto">
              {resendOTPViaEmailNoLoginStates.loading && (
                <span className="text-blue-600">Resending OTP...</span>
              )}
              {resendOTPViaEmailNoLoginStates.error && (
                <span className="text-red-600">
                  {resendOTPViaEmailNoLoginStates.error}
                </span>
              )}
              {resendOTPViaEmailNoLoginStates.success && isFading && (
                <span className="text-green-600">OTP resent successfully</span>
              )}
            </div>
          </div>
        </div>
      )}

      {showUpdatePassword && (
        <UpdatePassword
          email={email}
          session_key={verifyOTPPasswordResetStates.sessionKey}
        />
      )}
      <Loader loadingText={"Verifying..."} isOpen={ismodalOpen} />
    </>
  );
}

export default TwoStepVerification;
