import React from "react";
import { FaEdit } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import profileRegisteredIcon from "../../assets/Profile/profileRegisteredIcon.png";
import ProfilePicture from "../../assets/Profile/ProfilePic.jpg";
import { useNavigate } from "react-router-dom";

function BannerProfile({ data, styling }) {
  const ProfilePicture = "/ProfilePic.png";
  const profileRegisteredIcon = "/profileRegisteredIcon.png";
  const navigation = useNavigate();

  return (
    <div className={`flex justify-between w-full bg-[#f4f4f4] px-10 max-sm:px-2 max-[962px]:px-6 py-3 rounded-2xl rounded-br-none rounded-tl-none ${styling}`}>
      <div className="pic-name-container flex item-center gap-2  max-sm:gap-0">
        <img
          alt="profilepicbanner"
          src={data?.profile_photo_url || ProfilePicture}
          className="profile-pic w-16 h-16 bg-blue-400  rounded-full border-[2px] border-white my-auto max-sm:my-1 max-sm:w-14 max-sm:px-6 max-sm:h-14"
        />
        <div className="name-job flex flex-col justify-center leading-7  max-sm:pl-2">
          <div className="flex gap-3 max-sm:flex-col max-sm:gap-0  ">
            <div className="name text-[25px] font-semibold  max-[962px]:text-[22px] text-[#1C3775] max-sm:text-[20px] max-sm:leading-6">
              {data?.fullname}
            </div>
            <div className="flex  items-center max-sm:h-6 max-sm:w-[40%] max-sm:mt-1 whitespace-nowrap justify-center gap-1 text-[#515151] border border-[#515151] rounded-full px-2 cursor-pointer">
              <FaEdit size={12} />
              <div
                className="job max-sm:text-[9px] text-[12px] cursor-pointer"
                onClick={() =>
                  navigation("/dashboard/edit-profile?tab=overview")
                }
              >
                Edit Profile
              </div>
            </div>
          </div>
          <div className="flex text-[#0082BA] gap-3   max-sm:gap-1 min-[962px]:text-lg  max-[962px]:text-md max-sm:text-[11px]">
            <div className="job ">{data?.profession}</div>
            <div className="location flex item-center justify-center">
              <div>
                <FaLocationDot className="mt-1 max-sm:mt-2" />
              </div>
              <div>{data?.city}, {data?.country}</div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={profileRegisteredIcon}
        alt="profileRegisteredIcon "
        className="max-w-[120px] max-h-[70x] max-[962px]:w-[110px] max-[962px]:h-[70px] max-sm:w-[80px] max-sm:h-[50px]"
      />
    </div>
  );
}

export default BannerProfile;
