import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  checkPassword,
  resendOTPViaEmail,
  resendOTPViaNewEmail,
  sendOTPViaEmail,
  sendOTPViaNewEmail,
  setChangePasswordError,
  setCheckPasswordSuccess,
  setResendOTPViaEmailSuccess,
  setResendOTPViaNewEmailSuccess,
  setSendOTPViaEmailSuccess,
  setSendOTPViaNewEmailSuccess,
  setVerifyOTPViaEmailSuccess,
  setVerifyOTPViaNewEmailSuccess,
  verifyOTPViaEmail,
  verifyOTPViaNewEmail,
} from "../../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";
import { maskEmail } from "../../../config/common";
import { logout } from "../../../feature/authSlice";

function ChangeEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentView, setCurrentView] = useState("verify-pass-email");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [newEmailOtp, setNewEmailOtp] = useState(new Array(6).fill(""));
  const [newEmail, setNewEmail] = useState("");
  const [maskedNewEmail, setMaskedNewEmail] = useState("");
  const [isFading, setIsFading] = useState(false);

  const otpRefs = useRef([]);
  const newEmailOtpRefs = useRef([]);
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  const maskedEmail = userData?.email ? maskEmail(userData.email) : "";

  const auth = useSelector((state) => state.auth);
  const checkPasswordStates = useSelector((state) => state.settings.checkPassword) || { success: null, error: null, loading: false };
  const sendOTPViaEmailStates = useSelector((state) => state.settings.sendOTPViaEmail) || { success: null, error: null, loading: false };
  const resendOTPViaEmailStates = useSelector((state) => state.settings.resendOTPViaEmail) || { success: null, error: null, loading: false };
  const verifyOTPViaEmailStates = useSelector((state) => state.settings.verifyOTPViaEmail) || { success: null, error: null, loading: false };
  const sendOTPViaNewEmailStates = useSelector((state) => state.settings.sendOTPViaNewEmail) || { success: null, error: null, loading: false };
  const resendOTPViaNewEmailStates = useSelector((state) => state.settings.resendOTPViaNewEmail) || { success: null, error: null, loading: false };
  const verifyOTPViaNewEmailStates = useSelector((state) => state.settings.verifyOTPViaNewEmail) || { success: null, error: null, loading: false };

  useEffect(() => {

    if (checkPasswordStates.success === "Password is correct!") {
      dispatch(setCheckPasswordSuccess(null));

      setCurrentView("send-otp-to-email");
    }

    if (sendOTPViaEmailStates.success === "OTP sent") {
      setCurrentView("verify-otp-email");
      dispatch(setSendOTPViaEmailSuccess(null));
    }

    if (resendOTPViaEmailStates.success === "OTP sent") {
      setIsFading(true);
      const resendTimer = setTimeout(() => {
        setIsFading(false);
        dispatch(setResendOTPViaEmailSuccess(null)); // Reset success state
      }, 5000);
      return () => clearTimeout(resendTimer);
    }

    if (verifyOTPViaEmailStates.success === "OTP is valid!") {
      setCurrentView("input-new-email");

      dispatch(setVerifyOTPViaEmailSuccess(null));
    }

    // New Email
    if (sendOTPViaNewEmailStates.success === "OTP sent") {
      setCurrentView("verify-new-otp");

      dispatch(setSendOTPViaNewEmailSuccess(null));
    }

    if (resendOTPViaNewEmailStates.success === "OTP sent") {
      setIsFading(true);
      const resendNewTimer = setTimeout(() => {
        setIsFading(false);
        dispatch(setResendOTPViaNewEmailSuccess(null)); // Reset success state
      }, 5000);
      return () => clearTimeout(resendNewTimer);
    }

    if (verifyOTPViaNewEmailStates.success === "Email changed successfully!") {
      
      setCurrentView("success");
      
      dispatch(setVerifyOTPViaNewEmailSuccess(null));
    }

    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }

    setMaskedNewEmail(maskEmail(newEmail));
  }, [
    checkPasswordStates.success,
    sendOTPViaEmailStates.success,
    resendOTPViaEmailStates.success,
    verifyOTPViaEmailStates.success,
    sendOTPViaNewEmailStates.success,
    resendOTPViaNewEmailStates.success,
    verifyOTPViaNewEmailStates.success,
    isFading,
    dispatch,
    newEmail,
  ]);

  const handleCheckPasswordClick = async (e) => {
    e.preventDefault();

    if (password.trim() === "") {
      dispatch(setChangePasswordError("Password cannot be blank"));
      return;
    }

    try {
      await dispatch(checkPassword({ id: userData.id, data: { password } }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleSendOtpViaEmailClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(sendOTPViaEmail({ id: userData.id }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleSendOtpViaNewEmailClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(sendOTPViaNewEmail({ id: userData.id, data: { new_email: newEmail, new_email_confirmation: newEmail } }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleResendOtpViaEmailClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(resendOTPViaEmail({ id: userData.id }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleResendOtpViaNewEmailClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(resendOTPViaNewEmail({ id: userData.id, data: { new_email: newEmail, new_email_confirmation: newEmail } }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleOtpChange = (e, index, setOtpFunc, otpRefsArray, otpArray) => {
    const { value } = e.target;
    const newOtp = [...otpArray];

    if (e.key === "Backspace") {
      if (newOtp[index] === "") {
        if (index > 0) {
          otpRefsArray.current[index - 1].focus();
        }
      } else {
        newOtp[index] = "";
      }
    } else if (/^[0-9]$/.test(value)) {
      newOtp[index] = value;
      if (index < 5) {
        otpRefsArray.current[index + 1].focus();
      }
    }

    setOtpFunc(newOtp);
  };

  const handleOtpPaste = (event, setOtpFunc, otpRefsArray, otpArray) => {
    const pasteData = event.clipboardData.getData("text").slice(0, 6).split("").filter(char => /^[0-9]$/.test(char));
    const newOtp = [...otpArray];

    pasteData.forEach((char, index) => {
      newOtp[index] = char;
      if (otpRefsArray.current[index]) {
        otpRefsArray.current[index].value = char;
      }
    });

    setOtpFunc(newOtp);

    const nextIndex = pasteData.length < 6 ? pasteData.length : 5;
    otpRefsArray.current[nextIndex].focus();
  };

  const handleVerifyOTP = (otpArray, verifyAction) => {
    const code = otpArray.join("");

    if (code.length !== 6 || isNaN(code)) return;

    dispatch(verifyAction({ id: userData.id, data: { otp: code, new_email: newEmail } }));
  };

  const handleSuccessContinueClicked = async () => {    
    await dispatch(logout()).then((data) => {
      navigate('/login');
    });
    
  }

  const renderOtpInputFields = (otpArray, setOtpFunc, otpRefsArray) => (
    <div className="flex gap-4 pr-3 mt-4 max-w-full w-[362px]">
      {[...Array(6)].map((_, i) => (
        <input
          key={i}
          type="text"
          maxLength="1"
          className="shrink-0 p-2.5 border border-solid border-neutral-400 h-[58px] w-[45px] text-center"
          value={otpArray[i]}
          onChange={(e) => handleOtpChange(e, i, setOtpFunc, otpRefsArray, otpArray)}
          onKeyDown={(e) => handleOtpChange(e, i, setOtpFunc, otpRefsArray, otpArray)}
          onPaste={(e) => handleOtpPaste(e, setOtpFunc, otpRefsArray, otpArray)}
          ref={(el) => (otpRefsArray.current[i] = el)}
        />
      ))}
    </div>
  );

  const renderView = () => {
    switch (currentView) {
      case "verify-pass-email":
        return (
          <div className="flex flex-col items-center px-8 pb-20 text-base max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <form onSubmit={handleCheckPasswordClick}>
              <input
                placeholder="Enter Password"
                className="justify-center items-start px-4 py-3 mt-32 max-w-full text-base leading-5 rounded-xl border border-solid border-neutral-400 text-devRegBlack w-[346px] max-md:px-5 max-md:mt-10"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="on"
              />
              <div className="flex gap-4 mt-6 max-w-full font-semibold text-white whitespace-nowrap w-[346px]">
                <button
                  type="button"
                  className="justify-center px-6 py-2 cursor-pointer bg-sky-600 rounded-3xl max-md:px-5"
                  onClick={() => navigate("/dashboard/account-settings")}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="items-center justify-center flex-1 px-6 py-2 text-center bg-blue-900 cursor-pointer rounded-3xl max-md:px-5"
                  disabled={checkPasswordStates.loading}
                >
                  Continue
                </button>
              </div>
              </form>
            {checkPasswordStates.error && <div className="mt-2 text-red-600 w-[346px]">{checkPasswordStates.error}</div>}
            {checkPasswordStates.loading && <div className="mt-2 text-blue-600 w-[346px]">Processing...</div>}
          </div>
        );
      case "send-otp-to-email":
        return (
          <div className="flex flex-col items-end pb-20 text-base text-neutral-600 max-md:mt-10 max-md:max-w-full">
            <div className="mx-auto mt-28 tracking-tight leading-5 text-center w-[390px] max-md:mt-10 max-md:max-w-full">
              An OTP (One-Time Passcode) will be sent to email address <strong>{maskedEmail}</strong>
              <br />
              Would you like to continue?
            </div>
            <div className="flex gap-4 mx-auto mt-6 font-semibold text-white max-md:flex-wrap">
              <button
                className="justify-center px-6 py-2 cursor-pointer whitespace-nowrap bg-sky-600 rounded-3xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                No
              </button>
              <button
                className="justify-center flex-1 py-2 pr-12 bg-blue-900 cursor-pointer pl-11 rounded-3xl max-md:px-8 max-md:max-w-full"
                onClick={handleSendOtpViaEmailClick}
                disabled={sendOTPViaEmailStates.loading}
              >
                Yes, Send me OTP
              </button>
            </div>
            {(sendOTPViaEmailStates.error || sendOTPViaEmailStates.loading) && (
              <div className="mx-auto mt-4 tracking-tight leading-5 w-[390px] max-md:mt-10 max-md:max-w-full">
                {sendOTPViaEmailStates.error && <div className="px-8 mt-2 text-red-600">{sendOTPViaEmailStates.error}</div>}
                {sendOTPViaEmailStates.loading && <div className="px-8 mt-2 text-blue-600">Processing...</div>}
              </div>
            )}
          </div>
        );
      case "verify-otp-email":
        return (
          <div className="flex flex-col items-center px-8 pb-7 max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="mt-12 text-2xl font-bold text-blue-900 max-md:mt-10">Verification</div>
            <div className="mt-4 text-base text-black text-opacity-80 w-[350px]">
              Enter OTP (One-Time Passcode) we've sent to email address{" "}
              <span className="font-bold">{maskedEmail}</span>
            </div>
            {renderOtpInputFields(otp, setOtp, otpRefs)}
            <div className="mt-6 text-base text-black text-opacity-80">
              Did not receive the code?{" "}
              <span
                id="resend-otp-link"
                onClick={handleResendOtpViaEmailClick}
                className="text-blue-500 underline cursor-pointer"
              >
                Resend
              </span>
              {resendOTPViaEmailStates.loading && <span className="text-blue-600">&nbsp;|&nbsp;Resending OTP...</span>}
              {resendOTPViaEmailStates.error && <span className="text-red-600">&nbsp;|&nbsp;{resendOTPViaEmailStates.error}</span>}
              {resendOTPViaEmailStates.success && isFading && <span className="text-green-600">&nbsp;|&nbsp;OTP resent successfully</span>}
            </div>
            <div className="flex gap-4 mt-6 max-w-full text-base font-semibold text-white whitespace-nowrap w-[362px]">
              <button
                className="justify-center px-6 py-2 cursor-pointer bg-sky-600 rounded-3xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
              <button
                className="items-center justify-center flex-1 px-6 py-2 text-center bg-blue-900 cursor-pointer rounded-3xl max-md:px-5"
                onClick={() => handleVerifyOTP(otp, verifyOTPViaEmail)}
                disabled={verifyOTPViaEmailStates.loading}
              >
                Apply
              </button>
            </div>
            {verifyOTPViaEmailStates.loading && <div className="mt-3 text-blue-600 w-[362px]">Processing...</div>}
            {verifyOTPViaEmailStates.error && <div className="mt-3 text-red-600 w-[362px]">{verifyOTPViaEmailStates.error}</div>}
          </div>
        );
      case "input-new-email":
        return (
          <div className="flex flex-col items-center px-8 pb-20 text-base max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="mt-32">Enter new Email Address</div>
            <input
              placeholder="New Email"
              type="email"
              className="justify-center items-start px-4 py-3 max-w-full text-base leading-5 rounded-xl border border-solid border-neutral-400 text-devRegBlack w-[346px] max-md:px-5 max-md:mt-10"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <div className="flex gap-4 mt-6 max-w-full font-semibold text-white whitespace-nowrap w-[346px]">
              <button
                className="justify-center px-6 py-2 cursor-pointer bg-sky-600 rounded-2xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
              <button
                className="items-center justify-center flex-1 px-6 py-2 text-center bg-blue-900 cursor-pointer rounded-2xl max-md:px-5"
                onClick={handleSendOtpViaNewEmailClick}
                disabled={sendOTPViaNewEmailStates.loading}
              >
                Continue
              </button>
            </div>
            {(sendOTPViaNewEmailStates.loading || sendOTPViaNewEmailStates.error) && (
              <div className="flex gap-4 mt-6 max-w-full font-semibold text-white whitespace-nowrap w-[346px]">
                {sendOTPViaNewEmailStates.loading && <div className="mt-2 text-blue-600 w-[362px]">Processing...</div>}
                {sendOTPViaNewEmailStates.error && <div className="mt-2 text-red-600 w-[362px]">{sendOTPViaNewEmailStates.error}</div>}
              </div>
            )}
          </div>
        );
      case "verify-new-otp":
        return (
          <div className="flex flex-col items-center px-8 pb-7 max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="mt-12 text-2xl font-bold text-blue-900 max-md:mt-10">Verification</div>
            <div className="mt-4 text-base text-black text-opacity-80 w-[350px]">
              Enter OTP (One-Time Passcode) we've sent to email address{" "}
              <span className="font-bold">&nbsp;{maskedNewEmail}</span>
            </div>
            {renderOtpInputFields(newEmailOtp, setNewEmailOtp, newEmailOtpRefs)}
            <div className="mt-6 text-base text-black text-opacity-80">
              Did not receive the code?{" "}
              <span
                id="resend-new-otp-link"
                onClick={handleResendOtpViaNewEmailClick}
                className="text-blue-500 underline cursor-pointer"
              >
                Resend
              </span>
              {resendOTPViaNewEmailStates.loading && <span className="text-blue-600">&nbsp;|&nbsp;Resending OTP...</span>}
              {resendOTPViaNewEmailStates.error && <span className="text-red-600">&nbsp;|&nbsp;{resendOTPViaNewEmailStates.error}</span>}
              {resendOTPViaNewEmailStates.success && isFading && <span className="text-green-600">&nbsp;|&nbsp;OTP resent successfully</span>}
            </div>
            <div className="flex gap-4 mt-6 max-w-full text-base font-semibold text-white whitespace-nowrap w-[362px]">
              <button
                className="justify-center px-6 py-2 cursor-pointer bg-sky-600 rounded-3xl max-md:px-5"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </button>
              <button
                className="items-center justify-center flex-1 px-6 py-2 text-center bg-blue-900 cursor-pointer rounded-3xl max-md:px-5"
                onClick={() => handleVerifyOTP(newEmailOtp, verifyOTPViaNewEmail)}
                disabled={verifyOTPViaNewEmailStates.loading}
              >
                Apply
              </button>
            </div>
            {verifyOTPViaNewEmailStates.loading && <div className="text-blue-600 w-[362px]">Processing...</div>}
            {verifyOTPViaNewEmailStates.error && <div className="text-red-600 w-[362px]">{verifyOTPViaNewEmailStates.error}</div>}
          </div>
        );
      case "success":
        return (
          <div className="flex flex-col items-center px-8 pb-20 text-base max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="flex justify-center items-center px-16 mt-32 max-w-full text-lg font-bold tracking-tight leading-5 text-blue-900 w-[733px] max-md:px-5 max-md:mt-10">
              <div className="flex gap-4">
                <img
                  alt="img"
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/acec081d89f740a85c7d3323f4e60b6e3ed1913bb0063e44989cb12cc6bbc4e5?"
                  className="shrink-0 my-auto aspect-square w-[18px]"
                />
                <div>Email Address Updated</div>
              </div>
            </div>
            <div className="mt-2 text-base leading-5 text-center text-neutral-600 max-md:max-w-full">
              You had successfully changed your Email Address
            </div>
            <div className="shrink-0 mt-2 max-w-full h-px border border-solid bg-neutral-100 border-neutral-100 w-[435px]" />
            <div
              className="justify-center px-6 py-2 mt-2 font-semibold text-white bg-blue-900 cursor-pointer whitespace-nowrap rounded-3xl max-md:px-5"
              onClick={handleSuccessContinueClicked}
            >
              Continue
            </div>
            
            {auth.loading && (
              <div className="flex items-center py-3 text-blue-900 px-14">
                Logging out to apply changes...
              </div>
            )}
            {auth.error && (
              <div className="flex items-center py-3 text-red-900 px-14">
                {auth.error}...
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-[89vh]">
      <div className="w-full">
        <div className="self-start tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
          <span
            onClick={() => navigate("/dashboard/account-settings")}
            className="cursor-pointer hover:underline hover:text-blue-500"
          >
            Security Settings
          </span>{" "}
          &gt; Change Email
        </div>
        {renderView()}
      </div>
    </div>
  );
}

export default ChangeEmail;
