import React, { useEffect, useRef, useState } from "react";
import BacktoLogin from "./BacktoLogin";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "qrcode.react";
import { enable2FA, verifyEnabling2FA } from "../../feature/dashboard/professionalProfile/google2fa/google2faSlice";
import RecoveryCodes from "./RecoveryCodes";
import { setTwoFactored } from "../../feature/authSlice";
import Loader from "../../components/Loader";

const Google2FA = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const otpRefs = useRef([]);
  const [isFading, setIsFading] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [showRecoveryCodes, setShowRecoveryCodes] = useState(false);

  const dispatch = useDispatch();
  const verifyEnabling2FAStates = useSelector(
    (state) => state.google2fa.verifyEnabling2FA
  ) || { success: null, error: null, loading: false };

  const enable2FAStates = useSelector(
    (state) => state.google2fa.enable2FA
  ) || { success: null, error: null, loading: false };

  const [qr_code_url, setQr_code_url] = useState("");
  const [secret, setSecret] = useState("");

  // Do request QRCode once
  useEffect(() => {
    if (!qr_code_url && !secret) {
      dispatch(enable2FA()).then(({ payload }) => {
        if (payload) {
          setQr_code_url(payload?.qr_code_url || "");
          setSecret(payload?.secret || "");
        }
      });
    }
  }, []);

  // Show Recovery Code Screen
  useEffect(() => {
    if(recoveryCodes && recoveryCodes.length > 0){
      // console.log(recoveryCodes);
      setShowRecoveryCodes(true);
    }

    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [recoveryCodes,isFading]);

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];

    if (e.key === "Backspace") {
      if (newOtp[index] === "") {
        if (index > 0) otpRefs.current[index - 1].focus();
      } else {
        newOtp[index] = "";
      }
    } else if (/^[0-9]$/.test(value)) {
      newOtp[index] = value;
      if (index < 5) otpRefs.current[index + 1].focus();
    }

    setOtp(newOtp);
  };

  const handleOtpPaste = (event) => {
    const pasteData = event.clipboardData
      .getData("text")
      .slice(0, 6)
      .split("")
      .filter((char) => /^[0-9]$/.test(char));
    const newOtp = [...otp];

    pasteData.forEach((char, index) => {
      newOtp[index] = char;
      if (otpRefs.current[index]) otpRefs.current[index].value = char;
    });

    setOtp(newOtp);
    const nextIndex = pasteData.length < 6 ? pasteData.length : 5;
    otpRefs.current[nextIndex].focus();
  };

  const renderOtpInputFields = () => (
    <div className="flex flex-row max-w-full gap-4 pr-3 mt-4">
      {[...Array(6)].map((_, i) => (
        <input
          key={i}
          type="text"
          maxLength="1"
          className="flex-grow border border-solid border-neutral-400 h-[58px] min-w-5 text-center rounded-md"
          value={otp[i]}
          onChange={(e) => handleOtpChange(e, i)}
          onKeyDown={(e) => handleOtpChange(e, i)}
          onPaste={handleOtpPaste}
          ref={(el) => (otpRefs.current[i] = el)}
        />
      ))}
    </div>
  );

  const handleVerifyOTPClick = (e) => {
    e.preventDefault();
    const uData = JSON.parse(localStorage.getItem('user'));
    dispatch(verifyEnabling2FA({ otp: otp.join(""), email: uData?.email || '' })).then(({ payload }) => {
      setIsFading(true);
      
      if (payload && payload.recovery_codes) {
        dispatch(setTwoFactored(true));
        setRecoveryCodes(payload.recovery_codes);
      }
    });
  };

  return (
    <>
      {showRecoveryCodes && recoveryCodes.length > 0 ? (<RecoveryCodes codes={recoveryCodes} />) : (
        <div className="absolute flex flex-col items-center justify-center min-w-full min-h-screen py-12 bg-sky-600">
          <BacktoLogin />
          <div className="flex flex-col p-3 mx-3 md:mx-0 md:p-12 my-3 max-w-[420px] w-full bg-white rounded-3xl shadow-sm">
            <img
              alt="img"
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/0515c2622ed9199edf94bde5a446c72d0f096dd64c6ce9f50af8b84b945c06d4?apiKey=3ae96302e69649f5be368f89230b0596&"
              className="self-center w-full aspect-[4.17]"
            />
            {enable2FAStates.error && (
              <div className="mt-10 text-xl font-semibold text-neutral-800 max-sm:mr-auto text-start">
                Verify Code
              </div>
            )}

            {!enable2FAStates.error && (
              <div>
                <div className="mt-10 text-xl font-semibold text-neutral-800 max-sm:mr-auto text-start">
                  Scan QR Code
                </div>

                {/* QR Code Display */}
                <div className="flex flex-col items-center gap-4 mt-4 text-center">
                  <QRCode value={qr_code_url} size={150} />
                  <div className="mt-2 text-base text-zinc-700 text-opacity-80">
                    Scan this code using your authenticator app to get the authentication code.
                  </div>
                  <div className="hidden mt-2 text-base text-zinc-700 text-opacity-80">
                    Having problem scanning? Use this setup key instead: <strong>{secret}</strong>
                  </div>
                </div>
              </div>
            )}

            {/* OTP Input Fields */}
            <div className="hidden mt-4 text-base text-zinc-700 text-opacity-80">Verify to enable 2FA</div>
            {renderOtpInputFields()}

            <button
              onClick={handleVerifyOTPClick}
              disabled={verifyEnabling2FAStates.loading || enable2FAStates.loading}
              className="items-center justify-center py-3 mt-4 text-base font-semibold text-center text-white bg-blue-900 cursor-pointer px-9 whitespace-nowrap rounded-3xl max-md:px-5"
            >
              Confirm 2FA
            </button>

            {verifyEnabling2FAStates.loading && (
              <div className="my-3 text-center text-blue-600">Processing 2FA Confirmation...</div>
            )}
            {verifyEnabling2FAStates.error && isFading && (
              <div className="my-3 text-center text-red-600">
                {verifyEnabling2FAStates.error}
              </div>
            )}

            {enable2FAStates.error && (
              <div className="my-3 text-center text-red-600">
                {enable2FAStates.error}
              </div>
            )}
          </div>
        </div>
      )}
      <Loader loadingText={"Verifying..."} isOpen={verifyEnabling2FAStates.loading} />
    </>
  );
};

export default Google2FA;
