import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { personalInfo, emergencyContact } from "../../feature/authSlice";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import { Country, State, City } from "country-state-city";
import PhoneNumberWithFlag from "../../components/PhoneNumberWithFlag";
import Loader from "../../components/Loader";

function PersonalInfo() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const serial_number = userData?.serial_number;

  const [fName, setFName] = useState("");
  const [mName, setMName] = useState("");
  const [lName, setLname] = useState("");
  const [toggleSex, setToggleSex] = useState("male");
  const [birthDate, setBirthDate] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [unit, setUnit] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [stateOrProvince, setStateOrProvince] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [backupEmail, setBackUpEmail] = useState("");
  const [isValidBackupEmail, setIsValidBackupEmail] = useState(true);
  const [statesToSelect, setStatesToSelect] = useState([]);
  const [selectedCountryIsoCode, setSelectedCountryIsoCode] = useState("");
  const [selectedStateIsoCode, setSelectedStateIsoCode] = useState("");
  const [countriesToSelect, setCountriesToSelect] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const fullName = `${fName} ${mName} ${lName}`;
  //Emergency Contact
  const [fullNameEmergency, setFullNameEmergency] = useState("");
  const [unitEmergency, setUnitEmergency] = useState("");
  const [streetEmergency, setStreetEmergency] = useState("");
  const [postalCodeEmergency, setPostalCodeEmergency] = useState("");
  const [cityEmergency, setCityEmergency] = useState("");
  const [phoneEmergency, setPhoneEmergency] = useState("");
  const [emailEmergency, setEmailEmergency] = useState("");
  const [isValidEmergencyEmail, setIsValidEmergencyEmail] = useState(true);
  const [countryEmergency, setCountryEmergency] = useState("");

  const [statesToSelectEmergency, setStatesToSelectEmergency] = useState([]);
  const [selectedCountryIsoCodeEmergency, setSelectedCountryIsoCodeEmergency] =
    useState("");
  const [selectedStateIsoCodeEmergency, setSelectedStateIsoCodeEmergency] =
    useState("");
  const [countriesToSelectEmergency, setCountriesToSelectEmergency] = useState(
    []
  );
  const [statesEmergency, setStatesEmergency] = useState([]);
  const [citiesEmergency, setCitiesEmergency] = useState([]);
  const [selectedStateEmergency, setSelectedStateEmergency] = useState("");
  const [selectedCityEmergency, setSelectedCityEmergency] = useState("");
  const [
    registrationPhoneNumberEmergency,
    setRegistrationPhoneNumberEmergency,
  ] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [ismodalOpen, setIsModalOpen] = useState(false);

  const handleUpdateInfo = async () => {
    const _formData = {
      fullname: fullName,
      first_name: fName,
      middle_name: mName,
      last_name: lName,
      sex: toggleSex,
      dob: birthDate,
      place_of_birth: birthPlace,
      unit_number: unit,
      street_name: street,
      city: selectedCity,
      state: selectedState,
      country: country,
      postal_code: postalCode,
      backup_email: backupEmail,
      is_profile_info_done: 1,
    };
    setIsModalOpen(true);

    // const efname = fullNameEmergency.split(" ")[0];
    // const elname = fullNameEmergency
    //   .substring(efname.length, fullNameEmergency.length)
    //   .trim();

    const _formDataEmergencyContact = {
      city: selectedCityEmergency,
      country: countryEmergency,
      email: emailEmergency,
      fullname: fullNameEmergency,
      // first_name: efname,
      // last_name: elname,
      phone_number: registrationPhoneNumberEmergency,
      postal_code: postalCodeEmergency,
      state: selectedStateEmergency,
      street_name: streetEmergency,
    };

    if (userData) {
      const { id } = userData || { id: 0 };
      await dispatch(personalInfo({ id, data: { ..._formData } })).then(
        (data) => {
          if (data?.payload?.user) {
            dispatch(
              emergencyContact({ id, data: { ..._formDataEmergencyContact } })
            ).then((ndata) => {
              if (ndata?.payload?.data) {
                setIsModalOpen(false);
                navigate("/register/process-payment");
              }
            });
          }
        }
      );
    }
  };

  const validateBackupEmail = (e) => {
    const newEmail = e.target.value;
    setBackUpEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidBackupEmail(emailRegex.test(newEmail));
  };
  const validateEmergencyEmail = (e) => {
    const newEmail = e.target.value;
    setEmailEmergency(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmergencyEmail(emailRegex.test(newEmail));
  };

  const { shouldRedirect, redirectTo } = useInspectRoute(
    "/register/personal-information"
  );

  useEffect(() => {
    setCountriesToSelect(Country.getAllCountries());
    setStatesToSelect(State.getAllStates());
    setCountriesToSelectEmergency(Country.getAllCountries());
    setStatesToSelectEmergency(State.getAllStates());
  }, []);

  useEffect(() => {
    if (selectedCountryIsoCode !== "") {
      setStates(State.getStatesOfCountry(selectedCountryIsoCode));
      setCities([]);
      setSelectedState("");
      setSelectedCity("");
    } else {
      setStates([]);
    }
    if (selectedCountryIsoCodeEmergency !== "") {
      setStatesEmergency(
        State.getStatesOfCountry(selectedCountryIsoCodeEmergency)
      );
      setCitiesEmergency([]);
      setSelectedStateEmergency("");
      setSelectedCityEmergency("");
    } else {
      setStatesEmergency([]);
    }
  }, [selectedCountryIsoCode, selectedCountryIsoCodeEmergency]);

  useEffect(() => {
    if (selectedState !== "") {
      setCities(
        City.getCitiesOfState(selectedCountryIsoCode, selectedStateIsoCode)
      );
      setSelectedCity("");
    } else {
      setCities([]);
    }
    if (selectedStateEmergency !== "") {
      setCitiesEmergency(
        City.getCitiesOfState(
          selectedCountryIsoCodeEmergency,
          selectedStateIsoCodeEmergency
        )
      );
      setSelectedCityEmergency("");
    } else {
      setCitiesEmergency([]);
    }
  }, [
    selectedState,
    selectedCountryIsoCode,
    selectedStateEmergency,
    selectedCountryIsoCodeEmergency,
  ]);

  const test = {
    fullname: fullName,
    first_name: fName,
    middle_name: mName,
    last_name: lName,
    sex: toggleSex,
    dob: birthDate,
    place_of_birth: birthPlace,
    unit_number: unit,
    street_name: street,
    city: selectedCity,
    state: selectedState,
    country: country,
    postal_code: postalCode,
    backup_email: backupEmail,
    is_profile_info_done: 1,
  };

  console.log(test, "data");

  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    setCountry(countryName);
    const countryIso = countriesToSelect.find((c) => c.name === countryName);
    setSelectedCountryIsoCode(countryIso ? countryIso.isoCode : "");
  };
  const handleStateChange = (e) => {
    const stateName = e.target.value;
    setSelectedState(stateName);
    const stateIsoIso = statesToSelect.find((c) => c.name === stateName);
    setSelectedStateIsoCode(stateIsoIso ? stateIsoIso.isoCode : "");
  };
  const handleCountryChangeEmergency = (e) => {
    const countryName = e.target.value;
    setCountryEmergency(countryName);
    const countryIso = countriesToSelectEmergency.find(
      (c) => c.name === countryName
    );
    setSelectedCountryIsoCodeEmergency(countryIso ? countryIso.isoCode : "");
  };

  const handleStateChangeEmergency = (e) => {
    const stateName = e.target.value;
    setSelectedStateEmergency(stateName);
    const stateIsoIso = statesToSelectEmergency.find(
      (c) => c.name === stateName
    );
    setSelectedStateIsoCodeEmergency(stateIsoIso ? stateIsoIso.isoCode : "");
  };
  const handlePhoneNumberChange = (phoneNumber) => {
    setRegistrationPhoneNumberEmergency(phoneNumber);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const response = await fetch(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        );
        const data = await response.json();
        let countryName = data.countryName;

        countryName = countryName.replace(/\s*\(the\)$/, "");
        setCountry(countryName);
        setCountryEmergency(countryName);

        const countryIso = countriesToSelect.find(
          (c) => c.name === countryName
        );
        setSelectedCountryIsoCode(countryIso ? countryIso.isoCode : "");
      });
    }
  }, [countriesToSelect]);

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <>
      <div className="flex flex-col mx-auto leading-[121%] max-w-[760px] text-stone-300 max-sm:px-2.5">
        <div className="flex gap-4 justify-center  py-1 font-bold text-blue-900 leading-[120%] max-md:flex-wrap">
          <div className="flex-1 text-2xl tracking-tight max-md:max-w-full">
            Personal Information
          </div>
          <div className="text-3xl tracking-tight text-right">
            <span className="text-base leading-5 tracking-tight ">
              Serial Code
            </span>
            <span className="text-lg leading-6 tracking-tight">
              {" "}
              {serial_number}
            </span>
          </div>
        </div>
        <div className="flex justify-center w-full gap-4 mt-4 text-base whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
          <input
            placeholder="Firstname"
            className="text-black flex-1 justify-center items-start p-2.5 rounded-xl border border-solid border-neutral-400 max-md:pr-5"
            value={fName}
            onChange={(e) => setFName(e.target.value)}
          />

          <input
            placeholder="MiddleName"
            className="text-black flex-1 justify-center items-start p-2.5 rounded-xl border border-solid border-neutral-400 max-md:pr-5"
            value={mName}
            onChange={(e) => setMName(e.target.value)}
          />

          <input
            placeholder="Lastname"
            className="text-black flex-1 justify-center items-start p-2.5 rounded-xl border border-solid border-neutral-400 max-md:pr-5"
            value={lName}
            onChange={(e) => setLname(e.target.value)}
          />
        </div>
        <div className="flex justify-center w-full gap-6 pl-8 mt-4 text-base max-sm:gap-4 max-md:flex-wrap max-md:max-w-full">
          <div className="flex items-center justify-center gap-10 px-2 my-auto text-lg max-sm:gap-4 whitespace-nowrap ">
            <div
              className="flex items-center gap-2 py-1 cursor-pointer"
              onClick={() => setToggleSex("male")}
            >
              <div
                className={` shrink-0 w-4 h-4  rounded-full ${
                  toggleSex === "male"
                    ? "bg-teal-400 text-black"
                    : "bg-zinc-300"
                }`}
              />
              <div
                className={` ${
                  toggleSex === "male" ? "font-semibold text-black" : ""
                }`}
              >
                Male
              </div>
            </div>
            <div
              className="flex items-center gap-2 py-1 cursor-pointer"
              onClick={() => setToggleSex("female")}
            >
              <div
                className={` shrink-0 w-4 h-4  rounded-full ${
                  toggleSex === "female"
                    ? "bg-teal-400 text-black"
                    : "bg-zinc-300"
                }`}
              />
              <div
                className={` ${
                  toggleSex === "female" ? "font-semibold text-black" : ""
                }`}
              >
                Female
              </div>
            </div>
          </div>
          <input
            type="date"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            className={`${
              birthDate !== "" ? " text-black" : " text-zinc-300"
            } flex-1 justify-center items-start p-2.5 rounded-xl border border-solid border-neutral-400 max-md:pr-5 `}
          />

          <input
            value={birthPlace}
            onChange={(e) => {
              setBirthPlace(e.target.value);
            }}
            placeholder="Birthplace"
            className="text-black flex-1 justify-center items-start p-2.5 whitespace-nowrap rounded-xl border border-solid border-neutral-400 max-md:pr-5"
          />
        </div>
        <div className="w-full mt-4 text-base leading-6 text-neutral-600 max-md:max-w-full">
          Address
        </div>
        <div className="flex justify-center w-full gap-4 mt-2 text-base text-black max-md:flex-wrap max-md:max-w-full">
          <input
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            placeholder="Unit#"
            className="text-black flex-1 justify-center items-start p-2.5 rounded-xl border border-solid border-neutral-400 max-md:pr-5"
          />

          <input
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            placeholder="Street"
            className="flex-1 placeholder:text-neutral-300 justify-center items-start p-2.5 whitespace-nowrap rounded-xl border border-solid border-neutral-400 max-md:pr-5"
          />

          <select
            id="city"
            className={`${
              selectedState === "" ? "text-neutral-400" : "text-black"
            } rounded-xl w-full border border-solid border-neutral-300 max-md:max-w-full max-md:p-2.5 max-md:mt-4`}
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            disabled={!selectedState}
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-center w-full gap-4 mt-4 text-base text-black max-md:flex-wrap max-md:max-w-full">
          <select
            id="state"
            className={`${
              selectedState === "" ? "text-neutral-400" : "text-black"
            } rounded-xl w-full border border-solid border-neutral-300 max-md:max-w-full max-md:p-2.5`}
            value={selectedState}
            onChange={handleStateChange}
            disabled={!selectedCountryIsoCode}
          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state.isoCode} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
          <select
            className={`w-full  py-3 border-2 rounded-xl px-3 ${
              country === "" ? "text-neutral-400" : "text-black"
            }`}
            id="country"
            value={country}
            onChange={handleCountryChange}
          >
            <option disabled value="">
              Country
            </option>
            {countriesToSelect.map((country) => (
              <option key={country.isoCode} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
          <input
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            placeholder="Postal Code"
            type="number"
            className="flex-1 placeholder:text-neutral-300 justify-center items-start p-2.5 rounded-xl border border-solid border-neutral-400 max-md:pr-5"
          />
        </div>
        <div className="w-full mt-4 text-base leading-6 text-neutral-600 max-md:max-w-full">
          Backup Email Address
        </div>
        <input
          value={backupEmail}
          onChange={validateBackupEmail}
          placeholder="Email Address"
          className=" text-black justify-center items-start p-2.5 mt-2 w-full text-base rounded-xl border border-solid border-neutral-400
      max-md:pr-5 max-md:max-w-full"
        />
        {!isValidBackupEmail && (
          <div className="mt-1 text-sm text-red-600">
            Please enter a valid email address.
          </div>
        )}

        <div className="flex flex-col w-full p-4 mt-4 text-sm leading-5 tracking-normal text-black bg-yellow-50 rounded-xl text-opacity-80 max-md:max-w-full">
          <div className="max-md:max-w-full">
            <span className="text-base font-bold leading-6">
              Next Of Kin or Emergency Person:
            </span>
            <br />
            <br />
            <span className="leading-5 tracking-normal text-black">
              As part of your membership sign-up, we ask you to provide the
              contact details of a family member or friend.
            </span>
            <br />
            <br />
            <span className="font-semibold leading-5 tracking-normal text-black">
              Why is this important?
            </span>
            <span className="leading-5 tracking-normal text-black">
              This contact will only be used in one specific situation: if you
              have unclaimed funds in your account. It's a precautionary measure
              to ensure that any unclaimed benefits can be appropriately managed
              and directed in case you're not reachable.
            </span>
            <br />
            <br />
            <span className="font-semibold leading-5 tracking-normal text-black">
              Respecting Your Privacy
            </span>
            <br />
            <span className="leading-5 tracking-normal text-black">
              Please be assured, this information is strictly for the purpose of
              handling unclaimed funds and will be kept confidential.
            </span>
            <br />
            <br />
            <span className="leading-5 tracking-normal text-black">
              Thank you for taking this step to secure your benefits and
              interests as a member of our community.
            </span>
          </div>
          <div className="mt-4 max-md:max-w-full">
            <span className="font-semibold">
              Commitment to Data Privacy and Security
            </span>
            <br />
            <br />
            <span className="leading-5 tracking-normal text-black">
              At Full ID Check, we prioritize your privacy. Once your
              application is approved, your personal information is removed from
              our online servers and securely stored offline. This practice
              ensures the utmost security and confidentiality of your data.
            </span>
            <br />
            <span className="leading-5 tracking-normal text-black">
              For any questions about our data management, please contact us.
            </span>
          </div>
        </div>
        <div className="w-full mt-4 text-base leading-6 text-black text-opacity-80 max-md:max-w-full">
          Fullname (Firstname, Middle, Lastname)
        </div>
        <input
          value={fullNameEmergency}
          onChange={(e) => setFullNameEmergency(e.target.value)}
          placeholder="Fullname"
          className="text-black justify-center items-start p-2.5 mt-2 w-full text-base whitespace-nowrap rounded-xl 
    border border-solid border-neutral-400 max-md:pr-5 max-md:max-w-full"
        />

        <div className="w-full mt-4 text-base leading-6 text-neutral-600 max-md:max-w-full">
          Address
        </div>
        <div className="flex justify-center w-full gap-4 mt-2 text-base text-black max-md:flex-wrap max-md:max-w-full">
          <input
            value={unitEmergency}
            onChange={(e) => setUnitEmergency(e.target.value)}
            placeholder="Unit#"
            className="text-black flex-1 justify-center items-start p-2.5 rounded-xl border border-solid border-neutral-400 max-md:pr-5"
          />

          <input
            value={streetEmergency}
            onChange={(e) => setStreetEmergency(e.target.value)}
            placeholder="Street"
            className="flex-1 placeholder:text-neutral-300 justify-center items-start p-2.5 whitespace-nowrap rounded-xl border border-solid border-neutral-400 max-md:pr-5"
          />

          <select
            id="city"
            className={`${
              selectedStateEmergency === "" ? "text-neutral-400" : "text-black"
            } rounded-xl w-full border border-solid border-neutral-300 max-md:max-w-full max-md:p-2.5 max-md:mt-4`}
            value={selectedCityEmergency}
            onChange={(e) => setSelectedCityEmergency(e.target.value)}
            disabled={!selectedStateEmergency}
          >
            <option value="">Select City</option>
            {citiesEmergency.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-center w-full gap-4 mt-4 text-base text-black max-md:flex-wrap max-md:max-w-full">
          <select
            id="state"
            className={`${
              selectedStateEmergency === "" ? "text-neutral-400" : "text-black"
            } rounded-xl w-full border border-solid border-neutral-300 max-md:max-w-full max-md:p-2.5`}
            value={selectedStateEmergency}
            onChange={handleStateChangeEmergency}
            disabled={!selectedCountryIsoCodeEmergency}
          >
            <option value="">Select State</option>
            {statesEmergency.map((state) => (
              <option key={state.isoCode} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
          <select
            className={`w-full  py-3 border-2 rounded-xl px-3 ${
              countryEmergency === "" ? "text-neutral-400" : "text-black"
            }`}
            id="country"
            value={countryEmergency}
            onChange={handleCountryChangeEmergency}
          >
            <option disabled value="">
              Country
            </option>
            {countriesToSelectEmergency.map((country) => (
              <option key={country.isoCode} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
          <input
            value={postalCodeEmergency}
            onChange={(e) => setPostalCodeEmergency(e.target.value)}
            placeholder="Postal Code"
            type="number"
            className="flex-1 placeholder:text-neutral-300 justify-center items-start p-2.5 rounded-xl border border-solid border-neutral-400 max-md:pr-5"
          />
        </div>

        <div className="w-full mt-4 text-base leading-6 text-black text-opacity-80 max-md:max-w-full">
          Phone Number
        </div>

        <div className="">
          <PhoneNumberWithFlag onPhoneNumberChange={handlePhoneNumberChange} />
        </div>

        <div className="w-full mt-4 text-base leading-6 text-black text-opacity-80 max-md:max-w-full">
          Email Address
        </div>
        <input
          value={emailEmergency}
          onChange={validateEmergencyEmail}
          placeholder="Email Address"
          className="text-black justify-center items-start p-2.5 mt-2 w-full text-base rounded-xl border border-solid border-neutral-400 max-md:pr-5
      max-md:max-w-full"
        />
        {!isValidEmergencyEmail && (
          <div className="mt-1 text-sm text-red-600">
            Please enter a valid email address.
          </div>
        )}
        {auth.loading && (
          <p className="mt-2 text-left text-blue-900">Processing...</p>
        )}
        {auth.error && (
          <p className="mt-2 text-left text-red-900">{auth.error}</p>
        )}

        <button
          onClick={handleUpdateInfo}
          disabled={auth.loading}
          className="cursor-pointer text-center justify-center items-center self-end px-9 py-3 mt-10 mb-12 max-w-full text-base font-semibold text-white whitespace-nowrap bg-blue-900 rounded-[30px] w-[186px] max-md:px-5"
        >
          Next
        </button>
      </div>
      <Loader loadingText={"Processing..."} isOpen={ismodalOpen} />
    </>
  );
}

export default PersonalInfo;
