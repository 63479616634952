import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Bubble from "./Bubble";

function ProfileSidePanel({ data }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (value) => {
    setPhoneNumber(value);
  };

  return (
    <>
      <div className="flex flex-col px-5 py-4 text-base leading-6 bg-white rounded-xl text-neutral-400">
        <div className="flex flex-col px-2.5 py-4 mt-6 bg-white rounded-xl">
          <div className="flex flex-col w-full px-4 pt-4 mt-6 text-xl leading-8 bg-white pb-9 rounded-xl text-neutral-800">
            <div>
              <span className="font-bold text-blue-900">Availability</span>
              <br />
              {data.availability}
            </div>
            <div className="w-[98%] h-[2px] mt-4 bg-neutral-300 m-auto"></div>
            <div className="mt-4">
              <span className="font-bold text-blue-900">Work Arrangement</span>
              <br />
              {data.work_arrangement}
            </div>

            <div className="w-[98%] h-[2px] mt-4 bg-neutral-300 m-auto"></div>

            <div className="mt-4 font-bold text-blue-900 leading-[157%]">
              Languages
            </div>
            <div className="flex flex-wrap mt-2 text-base text-neutral-600">
              {
                data.languages?.map((item, index) => {
                  return <Bubble title={item.language} showControls={false} />
                })
              }
            </div>

            <div className="w-[98%] h-[2px] mt-4 bg-neutral-300 m-auto"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileSidePanel;
