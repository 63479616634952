import { CiCircleMinus, CiCircleCheck } from "react-icons/ci";

const Bubble = ({ title, handleRemove, showControls = true, color = null }) => {
  return (
    <div className={`justify-center rounded-2xl  mx-1 my-2 py-1 ${color ? color : 'bg-neutral-100'} ${showControls ? 'pl-3' : 'px-4'}`}>
      {title}
      {showControls &&
        <button className="text-blue-900 mr-1 px-1 py-1 justify-center align-middle rounded-2xl hover:text-red-400 hover:bg-neutral-200"
          onClick={() => handleRemove(title)}>
          <CiCircleMinus className="shrink-0 my-auto w-4 aspect-square align-middle" />
        </button>
      }
    </div>
  )
}

export default Bubble;