import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { login, resendLink } from "../../feature/authSlice";

const useLogin = () => {
  const mainImg =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&";
  const logoImg =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/0fa4a55e76d2b3d29c123fbc8cb288186f69c33a12fe61eff618d75a8254a6c4?apiKey=3ae96302e69649f5be368f89230b0596&";
  const navigate = useNavigate();
  const [loginPass, setLoginPass] = useState("");
  const [fullName, setFullName] = useState("");
  const [code, setCode] = useState("");
  const [toggleEye, setToggleEye] = useState(false);
  const [ismodalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const handleToggleEye = () => {
    setToggleEye(!toggleEye);
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleSignUp = () => {
    navigate("/register");
  };

  const handleLogin = async (e) => {
    if (e) e.preventDefault();
    setIsModalOpen(true);
    if (fullName.trim() === "" || loginPass.trim() === "") return;

    await dispatch(
      login({ email: fullName, password: loginPass, code: code })
    ).then(({ payload }) => {
      setIsModalOpen(false);

      if (payload?.user) {
        const userData = payload.user;
        if (+userData.is_profile_info_done === 0) {
          navigate("/register/personal-information");
        } else if (+userData.is_paid === 0) {
          navigate("/register/process-payment");
        } else if (+userData.is_document_uploaded === 0) {
          navigate("/register/upload-documents");
        } else if (+userData.is_face_recognized === 0) {
          navigate("/register/face-recognition");
        } else if (+userData.is_interviewing === 0) {
          navigate("/register/schedule-interview");
        } else {
          if (payload?.two_factor === false) {
            navigate("/enable-2fa");
          } else {
            const otpVerified = localStorage.getItem('OTPVerified');
            if(!otpVerified){
              navigate("/verify-2fa");
            }else {
              navigate("/dashboard/messages");
            }
          }
        }
      }
    });
  };

  const resendVerificationEmail = async () => {
    await dispatch(resendLink());
  };

  return {
    fullName,
    setFullName,
    loginPass,
    setLoginPass,
    code,
    setCode,
    toggleEye,
    handleToggleEye,
    handleForgotPassword,
    handleSignUp,
    handleLogin,
    resendVerificationEmail,
    mainImg,
    logoImg,
    ismodalOpen,
  };
};

export default useLogin;
