import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "../../../../authSlice";
import imageCheck, { imageCheckSquare } from "../../../../../utils/imageCheck";
import dateHelper from "../../../../../utils/dateHelper";
import { getHonorsAndAwards, addHonorsAndAwards, updateHonorsAndAwards, removeHonorsAndAwards } from "./honorsAndAwardsSlice";
import { honorsAndAwardsAction } from "./honorsAndAwardsSlice";
import isValidImageUrl from "../../../../../utils/isValidImageUrl";
import useHonorsAndAwardsValidation from "./useHonorsAndAwardsValidation";


const useHonorsAndAwards = () => {
	const dispatch = useDispatch();
	const { success, error, loading, values } = useSelector(state => state.honorsAndAwards);
	const userData = useSelector(state => state.auth.user);
	const [displayedHonorsAndAwards, setDisplayedHonorsAndAwards] = useState(userData?.honorsAndAwards);
	const { validationErrorMessage, showNotif, isSafeToSubmit, validateTitle, validateDescription, validateIssuer, validateDate } = useHonorsAndAwardsValidation();

	const postHonorsAndAwardsDefaultValues = {
		title: "",
		issued_by: "",
		issued_date: "",
		description: "",
		thumbnail: "",
	};
	const [postHonorsAndAwards, setPostHonorsAndAwards] = useState(postHonorsAndAwardsDefaultValues);
	const [updateId, setUpdateId] = useState(0);

	const thumbnailDefaultValue = {
		src: "",
		file: null,
	};
	const [thumbnail, setThumbnail] = useState(thumbnailDefaultValue);
	const [myError, setMyError] = useState('');

	const [showAddButton, setShowAddButton] = useState(true);
	const [showEditButton, setShowEditButton] = useState(false);


	useEffect(() => {
		console.log("HELLO 1");

		clearInputFields();
		displayAddButton();
		getData();
	}, []);

	const stringHonorsAndAwards = JSON.stringify(values);
	useEffect(() => {
		setDisplayedHonorsAndAwards(values);
	}, [stringHonorsAndAwards]);

	useEffect(() => {
		if (error !== null) {
			setDisplayedHonorsAndAwards(values);
		}
	}, [error, stringHonorsAndAwards]);

	const getData = async () => {
		await dispatch(getHonorsAndAwards({}));
	}

	const handleImageUpload = (event) => {

		const result = imageCheckSquare(event, 2, setMyError);

		if (result) {
			const { image: thisImage, file } = result;
			setThumbnail({
				src: thisImage.src,
				file: file,
			});
			setPostHonorsAndAwards({ ...postHonorsAndAwards, thumbnail: file });
		}
	};

	const clearInputFields = async () => {
		setPostHonorsAndAwards(postHonorsAndAwardsDefaultValues);
		setThumbnail(thumbnailDefaultValue);
	}

	const handleAddRecord = async () => {
		try {
			const promises = await Promise.all([
				validateTitle(postHonorsAndAwards.title),
				validateDescription(postHonorsAndAwards.description),
				validateIssuer(postHonorsAndAwards.issued_by),
				validateDate(postHonorsAndAwards.issued_date)
			]);

			if (isSafeToSubmit) {
				const result = await dispatch(addHonorsAndAwards({ data: postHonorsAndAwards }));
				const newRecord = JSON.parse(result?.payload).data;
				getData();
				clearInputFields();
			}

		} catch (err) {
			await dispatch(honorsAndAwardsAction.setError(`Error: ${err}`));
		}

	}

	const handleRemoveButton = async (intId) => {
		try {

			const newDisplayedHonorsAndAwards = displayedHonorsAndAwards.filter((item) => item.id !== intId);
			setDisplayedHonorsAndAwards(newDisplayedHonorsAndAwards);

			await dispatch(removeHonorsAndAwards({ honorAndAwardId: intId }));
			getData();

		} catch (err) {
			await dispatch(honorsAndAwardsAction.setError(`Error: ${err}`))
		}
	}

	const handleEditButton = async (intId) => {
		displayUpdateButton();
		clearInputFields();

		setUpdateId(intId);
		await rePopulateForEditing(displayedHonorsAndAwards, intId);
	}

	const displayAddButton = () => {
		setShowAddButton(true);
		setShowEditButton(false);
	}

	const displayUpdateButton = () => {
		setShowAddButton(false);
		setShowEditButton(true);
	}

	const rePopulateForEditing = async (items, updateId) => {
		const recordForUpdate = await items.filter((item) => item.id === updateId)[0];
		await setPostHonorsAndAwards(recordForUpdate);
		await setThumbnail({
			src: recordForUpdate?.thumbnail_path,
			file: null,
		});
	}

	const handleUpdateButton = async () => {

		try {
			const promises = await Promise.all([
				validateTitle(postHonorsAndAwards.title),
				validateDescription(postHonorsAndAwards.description),
				validateIssuer(postHonorsAndAwards.issued_by),
				validateDate(postHonorsAndAwards.issued_date)
			]);

			if (isSafeToSubmit) {
				let data = {
					title: postHonorsAndAwards.title,
					issued_by: postHonorsAndAwards.issued_by,
					issued_date: dateHelper.isDatetimeChecker(postHonorsAndAwards.issued_date) ? dateHelper.datetimeToInputDate(postHonorsAndAwards.issued_date) : postHonorsAndAwards.issued_date,
					description: postHonorsAndAwards.description,
				};

				if (postHonorsAndAwards.thumbnail && !isValidImageUrl(postHonorsAndAwards.thumbnail)) {
					data = { ...data, thumbnail: postHonorsAndAwards.thumbnail };
				}

				await dispatch(updateHonorsAndAwards({ honorAndAwardId: updateId, data: data }));
				getData();
				clearInputFields();
				displayAddButton();
			}

		} catch (err) {
			await dispatch(honorsAndAwardsAction.setError(`Error: ${err}`));
		}
	}

	return {
		success, error, loading,
		showAddButton, showEditButton, displayAddButton, displayUpdateButton,
		displayedHonorsAndAwards, setDisplayedHonorsAndAwards,
		postHonorsAndAwards, setPostHonorsAndAwards,
		thumbnail, setThumbnail,
		handleImageUpload,
		handleAddRecord, handleRemoveButton, handleEditButton, handleUpdateButton,
		validationErrorMessage, showNotif, isSafeToSubmit, validateTitle, validateDescription, validateIssuer, validateDate
	}

}

export default useHonorsAndAwards