import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { auth_api } from "../api/api";
import { saveToLocalStorage } from "../data/local/localStorage";

export const login = createAsyncThunk('/auth/login', async ({ email, password, code }, { rejectWithValue }) => {
	try {
		const response = await auth_api.login(email, password, code);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const resendLink = createAsyncThunk('/auth/resendLink', async () => {
	const response = await auth_api.resendLink();
	return response;
});

export const logout = createAsyncThunk('/auth/logout', async () => {
	const response = await auth_api.logout();
	return response;
});

export const register = createAsyncThunk('/auth/register', async (data, { rejectWithValue }) => {
	try {
		const response = await auth_api.register(data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const personalInfo = createAsyncThunk('/auth/personalInfo', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.personalInfo(id, data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const emergencyContact = createAsyncThunk('/auth/emergencyContact', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.emergencyContact(id, data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const stripeKey = createAsyncThunk('/auth/stripeKey', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.stripeKey();
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const processPayment = createAsyncThunk('/auth/processPayment', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.processPayment(id, data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const getDocuments = createAsyncThunk('/auth/getDocuments', async ({ }, { rejectWithValue }) => {
	try {
		const response = await auth_api.getDocuments();
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const uploadDocument = createAsyncThunk('/auth/uploadDocument', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.uploadDocument(id, data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const membershipValues = createAsyncThunk('/auth/processPayment', async ({ id }, { rejectWithValue }) => {
	try {
		const response = await auth_api.membershipValues(id);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const validateCoupon = createAsyncThunk('/auth/validateCoupon', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.validateCoupon(id, data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const deleteDocument = createAsyncThunk('/auth/deleteDocument', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.deleteDocument(id, data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const compileDocuments = createAsyncThunk('/auth/compileDocuments', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.compileDocuments(id, data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const qrCode = createAsyncThunk('/auth/qrCode', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.qrCode(id, data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const faceRecognitionStatus = createAsyncThunk('/auth/faceRecognitionStatus', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.faceRecognitionStatus(id, data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const scanAndVerify = createAsyncThunk('/auth/scanAndVerify', async ({ data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.scanAndVerify(data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const scheduleInterview = createAsyncThunk('/auth/scheduleInterview', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await auth_api.scheduleInterview(id, data);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const countryManagers = createAsyncThunk('/auth/countryManagers', async () => {
	const response = await auth_api.countryManagers();
	return response;
});

export const saveUserData = createAsyncThunk('/auth/saveToLocalStorage', async ({ userData }, { rejectWithValue }) => {
	try {
		const response = await saveToLocalStorage('user', userData);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});

export const getProfileInfo = createAsyncThunk('/auth/getProfileInfo', async ({ id }, { rejectWithValue }) => {
	try {
		const response = await auth_api.getProfileInfo(id);
		return response;
	} catch (err) {
		return rejectWithValue(err);
	}
});


export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: (!!localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || null) : null) || null,
		token: localStorage.getItem('token') || null,
		two_factor: false,
		success: false,
		loading: false,
		error: null,
		logout: {
			loading: false,
			success: null,
			error: null
		},
		resend: {
			loading: false,
			success: null,
			error: null
		},
		coupon: {
			loading: false,
			error: null
		},
		file: {
			upload: {
				loading: false,
				error: null
			},
			delete: {
				loading: false,
				error: null
			},
			compile: {
				loading: false,
				error: null,
			}
		},
		qr: {
			loading: false,
			error: null,
		},
		faceRecognitionStatus: {
			loading: false,
			error: null,
		},
		scanAndVerify: {
			loading: false,
			error: null,
			success: null,
		},
	},
	reducers: {
		setError: (state, action) => {
			state.error = action.payload || null;
		},
		setTwoFactored: (state, action) => {
			state.two_factor = action.payload || false;
			localStorage.setItem('two_factor', state.two_factor);
		},
		setFaceRecognitionStatusError: (state, action) => {
			state.faceRecognitionStatus.error = action.payload || null;
		},
		setFaceRecognitionStatusLoading: (state, action) => {
			state.faceRecognitionStatus.loading = action.payload || false;
		},
		setScanAndVerifyError: (state, action) => {
			state.scanAndVerify.error = action.payload || null;
		},
		setScanAndVerifySuccess: (state, action) => {
			state.scanAndVerify.success = action.payload || null;
		}
	},
	extraReducers: (builder) => {
		// Login
		builder.addCase(login.pending, (state) => {
			state.loading = true;
			state.error = null;
		}).addCase(login.fulfilled, (state, action) => {
			state.loading = false;
			state.token = action.payload.token;
			state.two_factor = action?.payload?.two_factor || false;

			if (action?.payload?.resend_link?.length > 0) {
				state.error = action?.payload?.message;
				state.resend_link = action?.payload?.resend_link;
			} else {
				if (action?.payload?.message === 'Invalid 2FA code.') {
					state.error = action?.payload?.message;
				}
				state.user = action.payload.user;
			}

			if (state.token) localStorage.setItem('token', state.token);
			if (state.user) localStorage.setItem('user', JSON.stringify(state.user));
			if (state.two_factor) localStorage.setItem('two_factor', state.two_factor);

		}).addCase(login.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload.message;
		})

			// Resend Link
			.addCase(resendLink.pending, (state) => {
				state.resend.loading = true;
				state.resend.error = null;
				state.resend.success = null;
			}).addCase(resendLink.rejected, (state, action) => {
				state.resend.loading = false;
				state.resend.success = null;
				state.resend.error = action.payload.message;
			}).addCase(resendLink.fulfilled, (state, action) => {
				state.user = action?.payload?.user;
				state.resend.loading = false;
				state.resend.success = action?.payload?.message !== 'Server Error' ? action?.payload?.message : null;

				if (state.user) localStorage.setItem('user', JSON.stringify(state.user));
			})

			// Logout
			.addCase(logout.pending, (state) => {
				state.logout.loading = true;
				state.logout.error = null;
			}).addCase(logout.rejected, (state, action) => {
				state.logout.loading = false;
				state.logout.error = action?.payload?.message;
			}).addCase(logout.fulfilled, (state) => {
				state.user = null;
				state.token = null;
				state.logout.loading = false;

				localStorage.removeItem('token');
				localStorage.removeItem('user');
				localStorage.removeItem('two_factor');
				localStorage.removeItem('loggedin');
				localStorage.removeItem('OTPVerified');
			})

			// Register
			.addCase(register.pending, (state) => {
				state.loading = true;
				state.error = false;
			})
			.addCase(register.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.message;
			}).addCase(register.fulfilled, (state, action) => {
				state.loading = false;

				if (action.payload.user) {
					state.user = action.payload.user;
					state.token = action.payload.token;

					if (state.token) localStorage.setItem('token', state.token);
					if (state.user) localStorage.setItem('user', JSON.stringify(state.user));
				} else {
					state.error = action.payload.message;
				}
			})

			// Personal Info
			.addCase(personalInfo.pending, (state) => {
				state.loading = true;
				state.error = false;
			}).addCase(personalInfo.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload?.message;
			}).addCase(personalInfo.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action?.payload?.user;

				if (state.user) localStorage.setItem('user', JSON.stringify(state.user));
			})

			// Emergency Contact
			.addCase(emergencyContact.pending, (state) => {
				state.loading = true;
				state.error = false;
			}).addCase(emergencyContact.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload?.message;
			}).addCase(emergencyContact.fulfilled, (state) => {
				state.loading = false;
			})

			// Get Stripe Key
			.addCase(stripeKey.pending, (state) => {
				state.loading = true;
				state.error = false;
			}).addCase(stripeKey.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload?.message;
			}).addCase(stripeKey.fulfilled, (state) => {
				state.loading = false;
			})

			// Process Payment
			.addCase(processPayment.pending, (state) => {
				state.loading = true;
				state.error = false;
			}).addCase(processPayment.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload?.message;
			}).addCase(processPayment.fulfilled, (state) => {
				state.loading = false;
			})

			// Validate Coupon
			.addCase(validateCoupon.pending, (state) => {
				state.coupon.loading = true;
				state.coupon.error = false;
			}).addCase(validateCoupon.rejected, (state, action) => {
				state.coupon.loading = false;
				state.coupon.error = action?.payload?.message;
			}).addCase(validateCoupon.fulfilled, (state, action) => {
				state.coupon.loading = false;
				if (action?.payload?.message !== 'Coupon code valid') state.coupon.error = action?.payload?.message;
			})

			// Upload Document
			.addCase(uploadDocument.pending, (state) => {
				state.file.upload.loading = true;
				state.file.upload.error = false;
			}).addCase(uploadDocument.rejected, (state, action) => {
				state.file.upload.loading = false;
				state.file.upload.error = action?.payload?.message;
			}).addCase(uploadDocument.fulfilled, (state, action) => {
				state.file.upload.loading = false;
				if (action?.payload?.message !== 'User File Uploaded') state.file.upload.error = action?.payload?.message;
			})

			// Delete Document
			.addCase(deleteDocument.pending, (state) => {
				state.file.delete.loading = true;
				state.file.delete.error = false;
			}).addCase(deleteDocument.rejected, (state, action) => {
				state.file.delete.loading = false;
				state.file.delete.error = action?.payload?.message;
			}).addCase(deleteDocument.fulfilled, (state, action) => {
				state.file.delete.loading = false;
				if (action?.payload?.message !== 'User File Removed') state.file.delete.error = action?.payload?.message;
			})

			// Compile Documents
			.addCase(compileDocuments.pending, (state) => {
				state.loading = true;
				state.error = false;
			}).addCase(compileDocuments.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload?.message;
			}).addCase(compileDocuments.fulfilled, (state, action) => {
				state.loading = false;
				if (action?.payload?.message !== 'Uploaded Documents validation success!') state.error = action?.payload?.message;
			})

			// QR Code
			.addCase(qrCode.pending, (state) => {
				state.qr.loading = true;
				state.qr.error = false;
			}).addCase(qrCode.rejected, (state, action) => {
				state.qr.loading = false;
				state.qr.error = action?.payload?.message;
			}).addCase(qrCode.fulfilled, (state, action) => {
				state.qr.loading = false;
				if (action?.payload?.message !== '') state.qr.error = action?.payload?.message;
			})

			// Face Recognition Status
			.addCase(faceRecognitionStatus.pending, (state) => {
				state.faceRecognitionStatus.loading = true;
				state.faceRecognitionStatus.error = false;
			}).addCase(faceRecognitionStatus.rejected, (state, action) => {
				state.faceRecognitionStatus.loading = false;
				state.faceRecognitionStatus.error = action?.payload?.message;
			}).addCase(faceRecognitionStatus.fulfilled, (state, action) => {
				state.faceRecognitionStatus.loading = false;
				if (action?.payload?.message !== 'Face Recognition Done!') state.faceRecognitionStatus.error = action?.payload?.message;
			})

			// Scan and Verify
			.addCase(scanAndVerify.pending, (state) => {
				state.scanAndVerify.loading = true;
				state.scanAndVerify.error = false;
				state.scanAndVerify.success = false;
			}).addCase(scanAndVerify.rejected, (state, action) => {
				state.scanAndVerify.loading = false;
				state.scanAndVerify.success = false;
				state.scanAndVerify.error = action?.payload?.message;
			}).addCase(scanAndVerify.fulfilled, (state, action) => {
				state.scanAndVerify.loading = false;
				if (action?.payload?.message !== 'Face Verification Success!') {
					state.scanAndVerify.error = action?.payload?.message;
				} else {
					state.scanAndVerify.success = action?.payload?.message;
				}
			})

			// schedule Interview
			.addCase(scheduleInterview.pending, (state) => {
				state.loading = true;
				state.error = false;
			}).addCase(scheduleInterview.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload?.message;
			}).addCase(scheduleInterview.fulfilled, (state, action) => {
				state.loading = false;
				if (action?.payload?.message !== 'Interview scheduled successfully') state.error = action?.payload?.message;
			})

			// Country Managers
			.addCase(countryManagers.pending, (state) => {
				state.loading = true;
				state.error = false;
			}).addCase(countryManagers.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload?.message;
			}).addCase(countryManagers.fulfilled, (state, action) => {
				state.loading = false;
			})

			// getProfileInfo
			.addCase(getProfileInfo.pending, (state) => {
				state.success = false;
				state.loading = true;
				state.error = false;
			}).addCase(getProfileInfo.rejected, (state, action) => {
				state.success = false;
				state.loading = false;
				state.error = action?.payload?.message;
			}).addCase(getProfileInfo.fulfilled, (state, action) => {
				state.success = true;
				state.loading = false;
				state.user = action?.payload;
				if (state.user) localStorage.setItem('user', JSON.stringify(state.user));
			})
			// Get Documents
			.addCase(getDocuments.pending, (state) => {
				state.loading = true;
				state.error = false;
			}).addCase(getDocuments.rejected, (state, action) => {
				state.loading = false;
				state.error = action?.payload?.message;
			}).addCase(getDocuments.fulfilled, (state, action) => {
				state.loading = false;
			})
	}
});

export const {
	setError,
	setTwoFactored,
	setFaceRecognitionStatusError,
	setFaceRecognitionStatusLoading,
	setScanAndVerifySuccess,
} = authSlice.actions;
export default authSlice.reducer;