import useMessagesValidation from "../../../feature/dashboard/messages/useMessagesValidation";
import InputValidationMessage from "../../../components/InputValidationMessage";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ResponsiveValues from "../../../components/ResponsiveValues";
import ProfilePic from "../../../assets/Profile/ProfilePic.jpg";

const ReplyMessagePanel = ({ loading, data, postMessage, setPostMessage, onClickSend, }) => {

  const {
    showNotif,
    validationErrorMessage,
    validateMessage,
  } = useMessagesValidation();

  return (
    <>
      <div className={ResponsiveValues.forMobileScreen}>
        <div className="flex gap-2 mt-4 max-md:flex-wrap">
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="flex flex-col w-full h-52 text-xs font-light leading-4 tracking-tight border border-solid rounded-2xl border-zinc-200 text-neutral-600 max-md:max-w-full">
              <div className="flex gap-2.5 justify-between items-start px-4 pt-4 w-full max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
                <div className="flex gap-2.5">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/59108dcad660c8d70d86bdf3a6018e1f86e413696d00ca82ff3efab5aac202b5?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 aspect-[0.94] fill-neutral-600 w-[17px]"
                  />
                  <div>to: {data.sender.fullname}</div>
                </div>
                <button
                  onClick={() => { setPostMessage({ ...postMessage, message: '' }) }}
                >
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/91ac736b9a107374c7ff8dcfcd45f86e24cdcb622659701bce3553c37245b900?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 aspect-[0.81] w-[13px]"
                  />
                </button>
              </div>
              <textarea className={`flex-grow resize-none p-4 focus:outline-none border-b border-solid rounded-2xl border-zinc-200`} name="" id=""
                value={postMessage.message}
                onChange={(e) => {
                  if (loading !== true) setPostMessage({ ...postMessage, message: e.target.value });
                }}
              />
            </div>
            <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.message.errorMessage} />
            <button className={`cursor-pointer justify-center self-end px-6 py-3.5 mt-4 text-lg font-semibold text-white bg-blue-900 rounded-3xl w-full max-md:px-5 whitespace-nowrap
              ${(loading === false) ? '' : 'disabled'}`}
              onClick={() => {
                validateMessage(postMessage.message);
                if (validationErrorMessage.message.isValid) onClickSend();
              }}
            >
              Send
            </button>
            {showNotif && (
              <>
                {loading && <LoadingIndicator loading={loading} styling="text-base justify-center my-2" />}
              </>
            )}
          </div>
        </div>
      </div>

      <div className={ResponsiveValues.forDesktopScreen}>
        <div className="flex gap-2 mt-4 max-md:flex-wrap">
          <img
            alt="img"
            loading="lazy"
            src={data.userData.profile_photo_url || ProfilePic}
            className="self-start w-10 rounded-full shrink-0 aspect-square"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="flex flex-col w-full h-52 text-xs font-light leading-4 tracking-tight border border-solid rounded-2xl border-zinc-200 text-neutral-600 max-md:max-w-full">
              <div className="flex gap-2.5 justify-between items-start px-4 py-4 w-full max-md:flex-wrap max-md:pr-5 max-md:mb-10 max-md:max-w-full">
                <div className="flex gap-2.5">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/59108dcad660c8d70d86bdf3a6018e1f86e413696d00ca82ff3efab5aac202b5?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 aspect-[0.94] fill-neutral-600 w-[17px]"
                  />
                  <div>to: {data.sender.fullname}</div>
                </div>
                <button
                  onClick={() => { setPostMessage({ ...postMessage, message: '' }) }}
                >
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/91ac736b9a107374c7ff8dcfcd45f86e24cdcb622659701bce3553c37245b900?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 aspect-[0.81] w-[13px]"
                  />
                </button>
              </div>
              <textarea className={`flex-grow resize-none p-4 focus:outline-none border-b border-solid rounded-2xl border-zinc-200`} name="" id=""
                value={postMessage.message}
                onChange={(e) => {
                  if (loading !== true) setPostMessage({ ...postMessage, message: e.target.value });
                }}
              />
            </div>
            <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.message.errorMessage} />
            <button className={`cursor-pointer justify-center self-end px-6 py-3.5 mt-2 text-lg font-semibold text-white whitespace-nowrap bg-blue-900 rounded-3xl max-md:px-5 
              ${(loading === false) ? '' : 'disabled'}`}
              onClick={() => {
                validateMessage(postMessage.message);
                if (validationErrorMessage.message.isValid) onClickSend();
              }}
            >
              Send
            </button>
            {showNotif && (
              <>
                {loading && <LoadingIndicator loading={loading} styling="text-base justify-end my-2" />}
              </>
            )}
          </div>
        </div>
      </div>


    </>
  )
}

export default ReplyMessagePanel;