import React from "react";
import { Navigate } from "react-router-dom";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import check from "../../assets/check.png";
function ApplicationSent() {
  const { shouldRedirect, redirectTo } = useInspectRoute(
    "/register/application-sent"
  );

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <>
      <div className="flex flex-col items-center font-bold bg-white mb-[35vh] mt-[5vh] max-sm:px-5">
        <div className="flex  flex-col justify-center w-full text-sm leading-5 bg-white max-w-[1200px] text-neutral-500 max-md:max-w-full" />
        <div className="flex justify-center items-center px-16 mt-52 max-w-full text-3xl tracking-tight leading-9 text-blue-900 w-[709px] max-md:px-5 max-md:mt-10">
          <div className="flex gap-4 ">
            <img
              alt="img"
              loading="lazy"
              src={check}
              className="w-10 shrink-0 aspect-square "
            />
            <div className="my-auto">Application Sent</div>
          </div>
        </div>
        <div className="mt-6 text-base leading-6 text-center text-neutral-700 max-md:max-w-full">
          Please check your inbox and confirm your email to continue with your
          registration.
        </div>
        <div className="mt-6 text-base leading-6 text-center text-neutral-700 max-md:max-w-full">
          Note: If you do not see the email in your inbox, please check your
          spam or junk folder.
        </div>
        <div className="shrink-0 mt-6 max-w-full h-px border border-solid bg-neutral-100 border-neutral-100 w-[411px]" />
      </div>
    </>
  );
}

export default ApplicationSent;
