import { useDispatch, useSelector } from "react-redux";
import { getFeature, postFeature, featureAction as action } from "./featureSlice";
import { useEffect, useState } from "react";
import imageCheck from "../../../../../utils/imageCheck";
import stringArrayHelper from "../../../../../utils/stringArrayHelper";
import useFeatureValidation from "./useFeatureValidation";

// // payload
// title: string,
// content: string,
// link: string,
// banner_file: image
// keywords: string

const useFeature = () => {
	const dispatch = useDispatch();
	const { values, success, error, loading, validationErrorMessage, showNotif, isSafeToSubmit, setValidationErrorMessage, validateLink, validateTitle, validateContent, validateKeywords } = useFeatureValidation();
	const valuesStr = JSON.stringify(values);

	const [title, setTitle] = useState('');
	const [content, setContent] = useState('');
	const [link, setLink] = useState('');
	const [keywords, setKeywords] = useState('');
	const [banner, setBanner] = useState('');
	const [bannerFile, setBannerFile] = useState('');

	const [keywordEntry, setKeywordEntry] = useState("");
	const [myError, setMyError] = useState([]);


	useEffect(() => {
		getValues();
	}, []);

	useEffect(() => {
		populateValues();
	}, [values]);

	useEffect(() => {
		setValidationErrorMessage(prevState => ({ ...prevState, banner: { isValid: false, errorMessage: myError } }));
	}, [myError]);


	const getValues = async () => {
		try {
			await dispatch(getFeature({}));
		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`));
		}
	}

	const populateValues = () => {
		if (values !== null && values.length > 0) {
			setTitle(values[0]?.title);
			setContent(values[0]?.content);
			setLink(values[0]?.link);
			setKeywords(values[0]?.keywords);
			setBanner(values[0]?.banner);
		}
	}

	const handleTitleChange = (str) => {
		setTitle(str);
		validateTitle(str);
	}

	const handleContentChange = (str) => {
		setContent(str);
		validateContent(str);
	}

	const handleLinkChange = (str) => {
		setLink(str);
		validateLink(str);
	}

	const handleKeywordChange = async (keyword) => {

		if (keyword.length <= 3) {
			setValidationErrorMessage(prevState => ({ ...prevState, keywords: { isValid: false, errorMessage: "Keyword must be atleast 3 letters long." } }));
		} else if (!checkKeywordsCount()) {
			setValidationErrorMessage(prevState => ({ ...prevState, keywords: { isValid: false, errorMessage: "Maximum of 3 keywords only." } }));
		} else {
			stringArrayHelper.addStringArrayHelper(keyword, keywords, setKeywords);
			setKeywordEntry("");
		}
	}

	const handlesAddFeature = async () => {
		try {
			const promises = await Promise.all([
				validateTitle(title.trim()),
				validateContent(content.trim()),
				validateLink(link.trim()),
				validateKeywords(keywords),
			]);

			if (isSafeToSubmit) {
				const data = {
					title: title.trim(),
					content: content.trim(),
					link: link.trim(),
					banner_file: bannerFile,
					keywords: keywords,
				};

				await dispatch(postFeature({ data: data }));
			}

		} catch (err) {
			await dispatch(action.setError(`Error: ${err}`))
		}
	}

	const handleImageUpload = (event) => {

		const result = imageCheck(event, 2, 815, 380, setMyError);

		if (result) {
			const { image: thisImage, file } = result;

			setBanner(thisImage.src);
			setBannerFile(file);
		}
	};

	const handleKeywordTyping = (str) => {
		setKeywordEntry(str);
	}

	const handleRemoveKeyword = (keyword) => {
		stringArrayHelper.removeStringArrayHelper(keyword, keywords, setKeywords);
	}

	const getArrayFromString = () => {
		return stringArrayHelper.getArrayHelper(keywords);
	}

	const checkKeywordsCount = () => {
		const arr = stringArrayHelper.getArrayHelper(keywords)
		if (arr.length < 3) {
			return true;
		}

		return false;
	}

	return {
		success, loading, error, myError,
		validationErrorMessage, showNotif, isSafeToSubmit,
		title, setTitle, handleTitleChange,
		content, setContent, handleContentChange,
		link, setLink, handleLinkChange,
		banner, setBanner,
		keywords, setKeywords, handleKeywordChange,
		keywordEntry, setKeywordEntry,
		handlesAddFeature,
		handleImageUpload,
		handleKeywordTyping,
		handleRemoveKeyword,
		getArrayFromString,
	}

}

export default useFeature