import React, { useState, useEffect } from "react";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import useHonorsAndAwards from "../../../feature/dashboard/professionalProfile/profileEdit/honorsAndAwards/useHonorsAndAwards";
import dateHelper from "../../../utils/dateHelper";
import HonorAwardsItems from "../../../components/Dashboard/profileEdit/HonorAwardsItem";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";

function HonorAwards() {

  const {
    success, error, loading,
    showAddButton, showEditButton,
    displayedHonorsAndAwards,
    postHonorsAndAwards, setPostHonorsAndAwards,
    thumbnail,
    handleImageUpload,
    handleAddRecord, handleRemoveButton, handleEditButton, handleUpdateButton,
    validationErrorMessage, showNotif, isSafeToSubmit, validateTitle, validateDescription, validateIssuer, validateDate
  } = useHonorsAndAwards();

  return (
    <>
      <div className="flex flex-col border-b border-solid border-neutral-400 max-md:mt-8 max-md:max-w-full">
        <div className="flex justify-between h-4 gap-4 px-5 text-base tracking-tight max-md:flex-wrap">
          <div className="leading-[110%] text-neutral-600">
            Add Honor / Award
          </div>
          {
            showNotif && (
              <>
                {!loading && success && <SuccessIndicator message="Account updated." />}
                {!loading && !success && error && <FailureIndicator message={error || "Account not updated."} />}
              </>
            )
          }
        </div>
        <div className="mt-4 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="relative flex flex-col w-[16%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col justify-center p-4 text-sm leading-4 text-center rounded-2xl border border-solid border-neutral-400 text-stone-300 max-md:mt-4">
                <img
                  alt="img"
                  loading="lazy"
                  src={thumbnail?.src || "https://cdn.builder.io/api/v1/image/assets/TEMP/a3088e5600d7f1611b46ef764ad5146a35374a7e29ff5c2206ee4755e075e8cc?"}
                  className="self-center aspect-[0.93]"
                />
                {!thumbnail?.src && <div>Issuer Logo</div>}
              </div>
              <input
                type="file"
                accept="image/*"
                value=""
                onChange={handleImageUpload}
                className="opacity-0  absolute top-0 left-0 w-full h-full cursor-pointer"
              />
            </div>

            <div className="flex flex-col ml-5 w-[84%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-center leading-[121%] text-stone-300 max-md:mt-4 max-md:max-w-full">
                <input
                  placeholder="Award Title"
                  value={postHonorsAndAwards?.title || ''}
                  autoComplete="organization"
                  onChange={(e) => {
                    setPostHonorsAndAwards({ ...postHonorsAndAwards, title: e.target.value });
                    validateTitle(e.target.value);
                  }}
                  className="justify-center p-4 text-base text-neutral-600 rounded-2xl border border-solid border-neutral-400 max-md:max-w-full" />
                <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.title?.errorMessage} />

                <div className="flex items-bottom gap-4 mt-4 max-md:flex-wrap max-md:max-w-full">
                  <input
                    placeholder="Name of Issuer"
                    value={postHonorsAndAwards?.issued_by || ''}
                    autoComplete="organization"
                    onChange={(e) => {
                      setPostHonorsAndAwards({ ...postHonorsAndAwards, issued_by: e.target.value });
                      validateIssuer(e.target.value);
                    }}
                    className="flex-1 justify-center p-4 text-base text-neutral-600 rounded-2xl border border-solid border-neutral-400" />

                  <div className="flex flex-col col-span-3">
                    <label className="text-[13px]"> Date Issued</label>
                    <input
                      type="date"
                      placeholder="Date Issued"
                      autoComplete="on"
                      value={
                        dateHelper.isDatetimeChecker(postHonorsAndAwards?.issued_date) ? dateHelper.datetimeToInputDate(postHonorsAndAwards?.issued_date) : postHonorsAndAwards?.issued_date
                      }
                      onChange={(e) => {
                        setPostHonorsAndAwards({ ...postHonorsAndAwards, issued_date: e.target.value });
                        validateDate(e.target.value);
                      }}
                      className="flex justify-center items-start text-base text-neutral-600 p-4 rounded-2xl border border-solid border-neutral-400 max-md:pr-5"
                    />
                    <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.date?.errorMessage} />

                  </div>
                </div>
                <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.issuer?.errorMessage} />

                <input
                  placeholder="Description"
                  value={postHonorsAndAwards?.description || ''}
                  autoComplete="organization"
                  onChange={(e) => {
                    setPostHonorsAndAwards({ ...postHonorsAndAwards, description: e.target.value });
                    validateDescription(e.target.value);
                  }}
                  className="justify-center items-start px-4 pt-4 pb-14 mt-4 text-base text-neutral-600 whitespace-nowrap rounded-2xl border border-solid border-neutral-400 max-md:pr-5 max-md:max-w-full" />
                <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.desription?.errorMessage} />
              </div>
            </div>
          </div>
        </div>
        {showAddButton &&
          <div className="flex flex-col justify-center items-end px-16 py-6 mt-4 text-base font-semibold text-white border-b border-solid border-neutral-400 max-md:pl-5 max-md:max-w-full">
            <button onClick={() => handleAddRecord()}
              className={`${isSafeToSubmit === true ? '' : 'disabled'} justify-center whitespace-nowrap px-6 py-3 bg-devRegDarkBlue rounded-2xl max-md:px-5 max-md:ml-2`}>
              Add Honor / Award
            </button>
            {
              showNotif && (
                <>
                  {loading && (<LoadingIndicator loading={loading} styling="px-10 max-md:px-5 max-md:ml-2" />)}
                </>
              )
            }
          </div>
        }

        {showEditButton &&
          <div className="flex flex-col justify-center items-end px-16 py-6 mt-4 text-base font-semibold text-white border-b border-solid border-neutral-400 max-md:pl-5 max-md:max-w-full">
            <button onClick={() => handleUpdateButton()}
              className={`${isSafeToSubmit === true ? '' : 'disabled'} justify-center whitespace-nowrap px-6 py-3 bg-devRegDarkBlue rounded-2xl max-md:px-5 max-md:ml-2`}>
              Update Honor / Award
            </button>
            {
              showNotif && (
                <>
                  {loading && (<LoadingIndicator loading={loading} styling="px-10 max-md:px-5 max-md:ml-2" />)}
                </>
              )
            }
          </div>
        }

        {displayedHonorsAndAwards?.length > 0 &&
          <div className="flex flex-col p-4 mt-4 text-base leading-6 bg-white rounded-xl text-neutral-600 max-md:max-w-full">
            <div className="text-3xl font-semibold text-neutral-800 max-md:max-w-full">
              Honors and Awards
            </div>

            {displayedHonorsAndAwards?.map((item) => <HonorAwardsItems data={item} onEdit={handleEditButton} onDelete={handleRemoveButton} />)}

          </div>
        }
      </div>
    </>
  );
}

export default HonorAwards;
