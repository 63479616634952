import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function EmailVerified() {
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const status = params.get("verified");
  const [message,setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (1 === parseInt(status)) {
      const uData = JSON.parse(localStorage.getItem('user'));
      uData.is_verified = true;

      localStorage.setItem('user',JSON.stringify(uData));
      
      setMessage("Email Confirmed");
    }else{
      setMessage("Email Confirmation failed");
    }
  },[]);

  return (
    <>
      <div className="flex flex-col items-center font-bold bg-white mb-[35vh] mt-[5vh] max-sm:px-5">
        <div className="flex  flex-col justify-center w-full text-sm leading-5 bg-white max-w-[1200px] text-neutral-500 max-md:max-w-full" />
        <div className="flex justify-center items-center px-16 mt-52 max-w-full text-3xl tracking-tight leading-9 text-blue-900 w-[709px] max-md:px-5 max-md:mt-10">
          <div className="flex gap-4 ">
            <img
              alt="img"
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f1c05a6d8cfe54c788370c372a6dab340b5242f2e55661b3be584da81ff0491?apiKey=3ae96302e69649f5be368f89230b0596&"
              className="w-10 shrink-0 aspect-square "
            />
            <div className="my-auto">Email Confirmed</div>
          </div>
        </div>
        { message === "Email Confirmed" ? (
          <div>
            <div className="mt-6 text-base leading-6 text-center text-neutral-700 max-md:max-w-full">
              Please login with your account to continue setting up personal information
            </div>
            <div className="flex flex-row items-center justify-center my-2">
              <button
                onClick={() => navigate('/login')}
                className="cursor-pointer text-center justify-center items-center self-center px-9 py-3 mt-6 max-w-full text-base font-semibold text-white whitespace-nowrap bg-blue-900 rounded-3xl w-[281px] max-md:px-5"
              >
                Go to Login
              </button>
            </div>
          </div>
        ) : (
          <div className="mt-6 text-base leading-6 text-center text-neutral-700 max-md:max-w-full">
            Something went wrong. Please contact admin support to resolve this issue. Thank you.
          </div>
        )}
        <div className="shrink-0 mt-6 max-w-full h-px border border-solid bg-neutral-100 border-neutral-100 w-[411px]" />
      </div>
    </>
  );
}

export default EmailVerified;
