import React, { useRef, useEffect } from "react";
import useOverview from "../../../feature/dashboard/professionalProfile/profileEdit/overview/useOverview";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";
import useCountryCityState from "../../../feature/dashboard/professionalProfile/profileEdit/overview/useCountryCityState";

function Overview() {

  const defaultProfilePhoto = "https://cdn.builder.io/api/v1/image/assets/TEMP/dbdb59e529344fd004b928608480af2aa91b9d0ae6b51eddb7d4695a84fa07b0?";

  const {
    loading, error, errorProfile, updateSuccess,
    image,
    imageProfile,
    currentProfileCover,
    currentProfilePhoto,
    akai, setAkai,
    about, setAbout,
    yearsOfExperience, setYearsOfExperience,
    noProjectsCompleted, setNoProjectsCompleted,
    city, setCity,
    state, setState,
    country, setCountry,
    postalCode, setPostalCode,
    profession, setProfession,
    salaryRate, setSalaryRate,
    isAvailableToWork, setIsAvailableToWork,
    availability, setAvailability,
    workSetupPreferences, setWorkSetupPreferences,
    handleUpdate,
    handleImageUpload,
    handleImageUploadProfile,
    validationErrorMessage, showNotif, isSafeToSubmit,
    validateAkai, validateAbout, validateYearsOfExperience, validateNoProjectsCompleted, validateCity,
    validateState, validateCountry, validatePostalCode, validateProfession, validateSalaryRate,
    validateIsAvailableToWork, validateAvailability, validateWorkSetupPreferences,
    countries, cities, states, populateCities, populateStates,
  } = useOverview();


  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <div className="flex flex-col grow text-base max-md:mt-8 md:mb-8 max-md:max-w-full">


        <div className="flex justify-end h-4">
          {showNotif && (
            <>
              {!loading && updateSuccess && <SuccessIndicator message="Update Success" />}
              {!loading && !updateSuccess && error && <FailureIndicator message={error} />}
            </>
          )}
        </div>

        {/* Profile picture  */}
        <div className="order-1 lg:order-2">
          <div className="flex gap-4 self-start mt-4 text-base leading-4  relative">
            <img
              loading="lazy"
              src={imageProfile ? imageProfile : (currentProfilePhoto ? currentProfilePhoto : defaultProfilePhoto)}
              className="shrink-0 max-w-full aspect-square w-[120px] rounded-full"
            />
            <div className="flex flex-col my-auto">
              <div className="font-semibold leading-[121%] text-black text-opacity-80">
                Profile picture
              </div>
              <div className="mt-2  text-sm tracking-tight text-zinc-400">
                Size: 320px x 320px
              </div>
              <div className="text-sm tracking-tight text-zinc-400">
                PNG, JPG up to 2MB
              </div>
              <div className="mt-2 font-bold tracking-tight text-sky-600">
                Upload
              </div>
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUploadProfile}
              className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
            />
          </div>
          {(errorProfile) && <div className="text-red-500 flex flex-col my-auto text-sm12">{errorProfile}</div>}
        </div>

        {/* profile banner  */}
        <div className={`${!image && !currentProfileCover ? 'block' : 'hidden'} container order-2 lg:order-1 bg-gray-200 max-w-[1000px] max-h-[200px] border-neutral-300 border-2 py-7 rounded-2xl mt-2 relative hover:opacity-80`}>
          <div className={`py-4 text-neutral-400`}>
            <div className="text-center flex flex-col justify-center items-center text-lg">
              <div className="text-base font-semibold leading-5 text-black text-opacity-80">
                Profile Banner
              </div>
              <div className="text-sm15">Add banner</div>
              <div className="text-xs leading-4">
                {`(Profile Cover / Ad Space (1000px x 200px) and MB up to 2MB`}
              </div>
              <div className="mt-2  text-sm15 font-bold tracking-tight text-devRegBlue">
                Upload
              </div>
            </div>
          </div>
          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={handleImageUpload}
            className={`opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer`}
          />
        </div>

        <img
          src={image ? image : (currentProfileCover ? currentProfileCover : defaultProfilePhoto)}
          onClick={handleClick} alt="Uploaded" className={`${(image || currentProfileCover) ? 'block' : 'hidden'} max-w-[1000px] max-h-[200px] py-7 cursor-pointer`} />
        {(error) && <div className="text-red-500 mb-4 text-sm12">{error}</div>}

        {/* profile details  */}
        <div className="flex order-3 flex-col grow text-base max-md:mt-8 max-md:max-w-full">
          <div className="mt-4 tracking-tight  placeholder:text-neutral-600 max-md:max-w-full">
            Aka/Alias Name (Hidden)
          </div>
          <input
            placeholder="Aka/Alias"
            className="p-3 mt-2 rounded-2xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:pr-5 max-md:max-w-full"
            autoComplete="name"
            value={akai}
            onChange={(e) => {
              setAkai(e.target.value);
              validateAkai(e.target.value);
            }}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.akai.errorMessage} />

          <div className="mt-4 tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
            About Me
          </div>
          <textarea
            placeholder="About Me"
            className=" p-3 mt-2 rounded-2xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:pr-5 max-md:max-w-full"
            autoComplete="on"
            value={about}
            onChange={(e) => {
              setAbout(e.target.value);
              validateAbout(e.target.value);
            }}
          />
          <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.about.errorMessage} />

          <div className="flex flex-wrap gap-4 content-start mt-4">
            <div className="flex flex-col flex-1 justify-center">
              <div className="tracking-tight leading-[110%] text-neutral-600">
                Years of Experience
              </div>
              <input
                placeholder="Years of Experience"
                className=" px-4 py-3 mt-2 rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                value={yearsOfExperience}
                onChange={(event) => {
                  setYearsOfExperience(event.target.value);
                  validateYearsOfExperience(event.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.yearsOfExperience.errorMessage} />
            </div>
            <div className="flex flex-col flex-1 justify-center">
              <div className="tracking-tight leading-[110%] text-neutral-600">
                No of Project Completed
              </div>
              <input
                placeholder="No of Project Completed"
                className=" px-4 py-3 mt-2 rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                value={noProjectsCompleted}
                onChange={(event) => {
                  setNoProjectsCompleted(event.target.value);
                  validateNoProjectsCompleted(event.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.noProjectsCompleted.errorMessage} />
            </div>
          </div>
          <div className="flex gap-2 mt-4 flex-wrap md:flex-nowrap ">
            <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
              <div className="tracking-tight leading-[110%] text-neutral-600">
                City
              </div>
              <select
                className={`px-4 py-3 mt-2 rounded-xl border 
                  border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 w-full ${state === "" ? "text-neutral-400" : "text-black"
                  }`}
                id="city"
                value={city}
                onChange={(event) => {
                  setCity(event.target.value);
                  validateCity(event.target.value);
                }}
              >
                <option disabled value="" key="0">
                  City
                </option>
                {cities?.map((city) => (
                  <option key={city.isoCode} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.city.errorMessage} />
            </div>
            <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
              <div className="tracking-tight leading-[110%] text-neutral-600">
                State
              </div>
              <select
                className={`px-4 py-3 mt-2 
                  rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 w-full ${state === "" ? "text-neutral-400" : "text-black"
                  }`}
                id="state"
                value={state}
                onChange={(event) => {
                  setState(event.target.value);
                  validateState(event.target.value);
                  populateCities(country, event.target.value);
                }}
              >
                <option disabled value="" key="0">
                  State
                </option>
                {states?.map((state) => (
                  <option key={state.isoCode} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.state.errorMessage} />
            </div>
            <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
              <div className="tracking-tight leading-[110%] text-neutral-600">
                Country
              </div>
              <select
                className={`px-4 py-3 mt-2 rounded-xl border 
                  border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 w-full ${country === "" ? "text-neutral-400" : "text-black"
                  }`}
                id="country"
                value={country}
                onChange={(event) => {
                  setCountry(event.target.value);
                  validateCountry(event.target.value);
                  populateStates(event.target.value);
                }}
              >
                <option disabled value="" key="0">
                  Country
                </option>
                {countries?.map((country) => (
                  <option key={country.isoCode} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.country.errorMessage} />
            </div>
            <div className="flex flex-col w-full md:w-1/2 lg:w-1/4">
              <div className="tracking-tight leading-[110%] text-neutral-600">
                Postal Code
              </div>
              <input
                placeholder="Postal Code"
                className=" px-4 py-3 mt-2 rounded-xl border 
                  border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 w-full"
                maxLength="16"
                autoComplete="postal-code"
                value={postalCode}
                onChange={(event) => {
                  setPostalCode(event.target.value);
                  validatePostalCode(event.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.postalCode.errorMessage} />
            </div>
          </div>

          <div className="flex gap-4 mt-4 max-md:flex-wrap">
            <div className="flex flex-col whitespace-nowrap">
              <div className="tracking-tight leading-[110%] text-neutral-600">
                Profession
              </div>
              <input
                placeholder="Profession"
                className=" items-start p-4 mt-2 rounded-2xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 "
                autoComplete="organization-title"
                value={profession}
                onChange={(event) => {
                  setProfession(event.target.value);
                  validateProfession(event.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.profession.errorMessage} />
            </div>
            <div className="flex flex-col flex-1">
              <div className="tracking-tight leading-[110%] text-neutral-600">
                Hourly rate
              </div>
              <input
                placeholder="Hourly rate"
                className=" items-start p-4 mt-2 rounded-2xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 "
                autoComplete="transaction-amount"
                value={salaryRate}
                onChange={(event) => {
                  setSalaryRate(event.target.value);
                  validateSalaryRate(event.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.salaryRate.errorMessage} />
            </div>
          </div>
          <div className="flex gap-4 pr-7 mt-6  max-md:flex-wrap max-md:pr-5">
            <div className="flex flex-col">
              <div className="tracking-tight leading-[110%] text-neutral-600">
                Available to Work
              </div>
              <select
                className={`max-h-[45px] mt-3  flex flex-1  px-2 py-2 whitespace-nowrap rounded-xl border border-solid border-neutral-300 ${isAvailableToWork === ""
                  ? "text-neutral-300"
                  : "text-black"
                  }`}
                value={isAvailableToWork === null ? "" : isAvailableToWork}
                onChange={(event) => {
                  setIsAvailableToWork(event.target.value);
                  validateIsAvailableToWork(event.target.value);
                }}
              >
                <option disabled value="" readOnly>
                  Available to Work
                </option>
                <option value="1" readOnly>Yes</option>
                <option value="0" readOnly>No</option>
              </select>
            </div>
            <div className="flex flex-col flex-1">
              <div className="tracking-tight leading-[110%] text-neutral-600">
                Availability
              </div>
              <select
                className={`max-h-[45px] mt-3  flex flex-1  px-2 py-2 whitespace-nowrap rounded-xl border border-solid border-neutral-300 ${availability === "" ? "text-neutral-300" : "text-black"
                  }`}
                value={availability === null ? "" : availability}
                onChange={(event) => {
                  setAvailability(event.target.value);
                  validateAvailability(event.target.value);
                }}
              >
                <option disabled value="">
                  Availability
                </option>

                <option value="Part Time" readOnly>Fulltime</option>
                <option value="Part-time" readOnly>Part-time</option>
                <option value="Project-based" readOnly>Project-based</option>
                <option value="Freelancer" readOnly>Freelancer</option>
                <option value="Contractor" readOnly>Contractor</option>
              </select>
            </div>
            <div className="flex flex-col flex-1">
              <div className="tracking-tight leading-[110%] text-neutral-600">
                Work Arrangement
              </div>
              <select
                className={`max-h-[45px] mt-3  flex flex-1  px-2 py-2 whitespace-nowrap rounded-xl border border-solid border-neutral-300 ${workSetupPreferences === ""
                  ? "text-neutral-300"
                  : "text-black"
                  }`}
                value={workSetupPreferences === null ? "" : workSetupPreferences}
                onChange={(event) => {
                  setWorkSetupPreferences(event.target.value);
                  validateWorkSetupPreferences(event.target.value);
                }}
              >
                <option disabled value="">
                  Work Arrangement
                </option>

                <option value="Work From Home" readOnly>Work From Home</option>
                <option value="Field Work" readOnly>Field Work</option>
                <option value="Hybrid" readOnly>Hybrid</option>
                <option value="Office Work" readOnly>Office Work</option>
              </select>
            </div>
          </div>
          <button onClick={handleUpdate}
            className={`${(error || errorProfile || loading || isSafeToSubmit === false) && 'disabled'} justify-center self-end px-6 py-3 mt-10 text-base font-semibold text-white whitespace-nowrap bg-blue-900 rounded-2xl max-md:px-5`}>
            Update
          </button>
          {loading && (<LoadingIndicator loading={loading} styling="text-base" />)}
        </div>
      </div>
    </>
  );
}

export default Overview;
