import { useDispatch, useSelector } from "react-redux";
import { certificateActions, downloadCertificate } from "./certificatesSlice";

const useCertificates = () => {
	const dispatch = useDispatch();
	const userData = useSelector(state => state.auth.user);
	const { error, loading } = useSelector(state => state.certificate);

	const handlesDownloadButton = async () => {
		try {
			await dispatch(downloadCertificate({ id: userData.id }));
		} catch (err) {
			await dispatch(certificateActions.setError(`Error: ${err}`))
		}
	}

	return {
		handlesDownloadButton,
		error,
		loading,
		userData,
	}

}

export default useCertificates