import React, { useEffect, useState } from "react";
import useRegistrationForm from "../../api/customHook/useRegistrationForm ";
import PhoneNumberWithFlag from "../../components/PhoneNumberWithFlag";
import { CiCircleCheck } from "react-icons/ci";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import Loader from "../../components/Loader";
function Registration() {
  const navigate = useNavigate();
  const {
    professionsForSelectedJobType,
    handleProfessionChange,
    handleJobTypeChange,
    selectedJobType,
    uniqueJobTypes,
    description,
    selectedProfession1,
    setCheckboxChecked,
    checkboxChecked,
    selectedOption,
    selectedProfession,
    fullName,
    password,
    confirmPassword,
    isLengthValid,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialChar,
    passwordsMatch,
    email,
    isValidEmail,
    setFullName,
    handleApply,
    handleSelectChange,
    handleSelectChangeProfession,
    handleChange,
    validateEmail,
    handlePhoneNumberChange,
    ismodalOpen,
  } = useRegistrationForm();
  const [otherProfesion, setOtherProfesion] = React.useState("");

  const auth = useSelector((state) => state.auth);
  const { shouldRedirect, redirectTo } = useInspectRoute("/register");
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    setIsFading(true);
    setTimeout(() => {
      setIsFading(false);
    }, 7000);
  }, [auth.error]);

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <>
      <div className="flex flex-col mx-auto mt-4 max-w-[600px] max-sm:px-10">
        <div className="mt-1.5 w-full text-2xl font-bold tracking-tight leading-7 text-blue-900 max-md:max-w-full">
          Registration
        </div>

        <div className="my-2 text-[#4A4A4A]">
          Create your account by filling in the details below.
        </div>
        <div className="bg-[#F6F6F6] p-2 rounded-lg text-[#4B4B4B]">
          <div>
            <span className="italic ">Note:</span> If you are a software or
            hardware development professional wishing to register and get
            verified, please visit
            <a
              href="http://devreg.org"
              target="_blank"
              className="text-[#0082BA] font-semibold"
              rel="noreferrer"
            >
              {" "}
              devreg.org
            </a>
          </div>
        </div>
        <input
          className="justify-center items-start focus:outline-blue-900 p-2.5 mt-6 w-full text-base leading-5 rounded-xl border border-solid border-neutral-400 max-md:pr-5 max-md:max-w-full text-black"
          placeholder="Full name "
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />

        <div className="flex justify-center gap-4 mt-4 text-base max-md:flex-col leading-5max-md:flex-wrap">
          <select
            id="jobType"
            className={`flex focus:outline-blue-900 flex-1 justify-between px-2 py-2.5 whitespace-nowrap rounded-xl border border-solid border-neutral-400 ${selectedJobType === "" ? "text-neutral-600" : "text-black"
              }`}
            value={selectedJobType}
            onChange={handleJobTypeChange}
          >
            <option disabled selected value="">
              Type of Job
            </option>
            {uniqueJobTypes.map((jobType, index) => (
              <option key={index} value={jobType}>
                {jobType}
              </option>
            ))}
          </select>

          <select
            className={`border border-solid border-neutral-400 rounded-xl focus:outline-blue-900 px-2 py-2.5 w-full ${selectedProfession1 === "" ? "text-neutral-600" : "text-black"
              }`}
            id="profession"
            value={selectedProfession1}
            onChange={handleProfessionChange}
          >
            <option disabled selected value="">
              Profession
            </option>
            {professionsForSelectedJobType.map((profession, index) => (
              <option key={index} value={profession}>
                {profession}
              </option>
            ))}
          </select>
        </div>

        {description && (
          <div className="bg-[#F6F6F6] p-2 rounded-lg  mt-2 text-[#4B4B4B]">
            <div>
              <div>
                <p className="text-center ">{description}</p>
              </div>
            </div>
          </div>
        )}

        {selectedProfession === "Other" && (
          <input
            className="justify-center items-start p-2.5 mt-4 w-full
          text-base leading-5 whitespace-nowrap rounded-xl border
          border-solid border-neutral-400 text-black max-md:pr-5
            max-md:max-w-full"
            placeholder="Other Profession"
            value={otherProfesion}
            onChange={(e) => setOtherProfesion(e.target.value)}
          />
        )}
        <input
          className="justify-center  focus:outline-blue-900 items-start p-2.5 mt-4 w-full
          text-base leading-5 whitespace-nowrap rounded-xl border
          border-solid border-neutral-400 text-black max-md:pr-5
            max-md:max-w-full"
          placeholder="Email"
          value={email}
          onChange={validateEmail}
        />
        {!isValidEmail && (
          <div className="mt-1 text-sm text-red-600">
            Please enter a valid email address.
          </div>
        )}

        <PhoneNumberWithFlag onPhoneNumberChange={handlePhoneNumberChange} />
        <input
          className={`justify-center items-start p-2.5 mt-4 w-full text-base leading-5 rounded-xl border border-solid 
          max-md:pr-5 max-md:max-w-full text-black focus:outline-blue-900`}
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={handleChange}
        />

        <div className="w-full mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
          Your password should:
        </div>
        <div
          className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${isLengthValid ? " text-green-700" : "  text-zinc-600"
            } `}
        >
          {isLengthValid ? (
            <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
          ) : (
            <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
          )}
          <div className="flex-auto">Be at least 8 characters in length</div>
        </div>
        <div
          className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasUpperCase && hasLowerCase ? " text-green-700" : "  text-zinc-600"
            } `}
        >
          {hasUpperCase && hasLowerCase ? (
            <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
          ) : (
            <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
          )}
          <div className="flex-auto ">
            Contain both upper and lowercase alphabetic characters (e.g. A-Z,
            a-z)
          </div>
        </div>
        <div
          className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasNumber ? " text-green-700" : "  text-zinc-600"
            } `}
        >
          {hasNumber ? (
            <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
          ) : (
            <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
          )}
          <div className="flex-auto max-md:max-w-full">
            Have at least one numerical character (e.g. 0-9)
          </div>
        </div>
        <div
          className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${hasSpecialChar ? " text-green-700" : "  text-zinc-600"
            } `}
        >
          {hasSpecialChar ? (
            <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
          ) : (
            <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px]  ml-[2px] mt-[3px]" />
          )}
          <div className="flex-auto ">
            Have at least one special character (e.g. ~!@#$%^&*()_-+=)
          </div>
        </div>
        <input
          className={`
  ${passwordsMatch ? "border-neutral-400" : "border-red-600"
            } justify-center items-start p-2.5 mt-4 w-full focus:outline-blue-900 text-base leading-5 rounded-xl border border-solid text-black max-md:pr-5 max-md:max-w-full`}
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={handleChange}
          disabled={
            !isLengthValid ||
            !hasUpperCase ||
            !hasLowerCase ||
            !hasNumber ||
            !hasSpecialChar
          }
        />

        <div className="flex self-center gap-2 pr-5 mt-4">
          <div className="flex justify-center">
            <input
              type="checkbox"
              checked={checkboxChecked}
              className="shrink-0 mt-[2px]  rounded-md bg-zinc-300"
              onChange={() => {
                setCheckboxChecked(!checkboxChecked);
              }}
            />
          </div>
          <div className="text-sm leading-4 tracking-tight text-neutral-600">
            I have read and accept the{" "}
            <span
              className="font-semibold cursor-pointer"
              onClick={() => navigate("/terms-and-conditions")}
            >
              {" "}
              terms and conditions
            </span>
          </div>
        </div>
        <button
          onClick={handleApply}
          disabled={!checkboxChecked || (checkboxChecked && auth.loading)}
          className="cursor-pointer text-center justify-center items-center self-center px-9 py-3 mt-6 max-w-full text-base font-semibold text-white whitespace-nowrap bg-blue-900 rounded-3xl w-[281px] max-md:px-5"
        >
          Apply
        </button>
        {auth.loading && (
          <p className="mt-2 text-center text-teal-400">Processing...</p>
        )}
        {auth.error && isFading && (
          <p className="mt-2 text-center text-red-400">{auth.error}</p>
        )}

        <div className="self-center mt-4 mb-12 text-base text-center text-stone-600">
          Already have an account?&nbsp;
          <span
            className="font-bold text-teal-400 cursor-pointer"
            onClick={() => {
              navigate("/login");
              window.scrollTo(0, 0);
            }}
          >
            Log-in
          </span>
        </div>
      </div>
      <Loader loadingText={"Registering..."} isOpen={ismodalOpen} />
    </>
  );
}

export default Registration;
