import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function SecuritySettings() {
  const navigate = useNavigate();
  const [email,setEmail] = useState("");
  const [phoneNumber,setPhoneNumber] = useState("");

  useEffect(() => {
    const uData = JSON.parse(localStorage.getItem('user'));
    
    setEmail(uData.email);
    setPhoneNumber(uData.phone_number);

  },[email,phoneNumber]);

  return (
    <div className=" min-h-[89vh]">
      <div className="">
        <div className="flex justify-between w-full gap-4 px-5 text-base tracking-tight max-md:flex-wrap">
          <div className="leading-[110%] text-neutral-600">
            Security Settings
          </div>
          {/* <div className="flex gap-2 text-green-700 leading-[120%]  ">
            <div>Account Updated</div>
            <img
              alt="img"
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/221edcdbd14084a7e10fe57ea12fad63e1b9ad48a561223e6b0c9d7e11b96b5b?"
              className="w-4 my-auto shrink-0 aspect-square"
            />
          </div> */}
        </div>
        <div className="flex flex-col self-stretch px-5 max-md:mt-10 max-sm:mt-2 max-md:max-w-full">
          <div className="mt-6 text-base leading-4 tracking-tight text-neutral-600 max-md:max-w-full">
            Password
          </div>
          <div className="flex gap-0 pr-20 mt-2 max-sm:pr-0 max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
            <div className="max-sm:w-full max-sm:pr-2 max-sm:rounded-none justify-center items-start px-4 py-2.5 text-lg leading-5 whitespace-nowrap rounded-xl min-[384px]:rounded-tr-none  min-[384px]:rounded-br-none border border-solid border-neutral-300 text-neutral-600 max-md:px-5">
              ••••••
            </div>
            <div
              onClick={() => {
                navigate("/dashboard/account-settings/change-password");
              }}
              className="cursor-pointer max-sm:mt-2 max-sm:text-center  max-sm:w-full max-sm:rounded-none justify-center px-6 py-3 text-base font-semibold text-white bg-blue-900  min-[384px]:rounded-tl-none  min-[384px]:rounded-bl-none  rounded-xl max-md:px-5"
            >
              Change Password
            </div>
          </div>
          <div className="mt-6 text-base leading-4 tracking-tight text-neutral-600 max-md:max-w-full">
            Phone
          </div>
          <div className="flex gap-0 pr-20 mt-2 max-sm:pr-0 max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
            <div className="max-sm:w-full max-sm:pr-2 max-sm:rounded-none justify-center items-start px-4 py-3 text-base leading-5 rounded-xl min-[460px]:rounded-tr-none  min-[460px]:rounded-br-none border border-solid border-neutral-300 text-neutral-600 max-md:px-5">
              {phoneNumber}
            </div>
            <div
              className=" max-sm:w-full max-sm:mt-2 max-sm:rounded-none  max-sm:text-center justify-center items-start px-6 py-3 text-base font-semibold text-white bg-blue-900 min-[460px]:rounded-tl-none  min-[460px]:rounded-bl-none  rounded-xl  max-md:px-5 cursor-pointer"
              onClick={() => {
                navigate("/dashboard/account-settings/change-number");
              }}
            >
              Change Number
            </div>
          </div>
          <div className="mt-6 text-base leading-4 tracking-tight text-neutral-600 max-md:max-w-full">
            Email
          </div>
          <div className="flex gap-0 pr-20 mt-2 max-sm:pr-0 max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
            <div className="max-sm:w-full max-sm:pr-2 max-sm:rounded-nonejustify-center items-start px-4 py-3 text-base leading-5 whitespace-nowrap  min-[532px]:rounded-tr-none  min-[532px]:rounded-br-none  rounded-xl border border-solid border-neutral-300 text-neutral-600 max-md:px-5">
              {email}
            </div>
            <div
              className="max-sm:text-center  max-sm:w-full max-sm:rounded-none max-sm:mt-2 justify-center items-start px-6 py-3 cursor-pointer text-base font-semibold text-white bg-blue-900 min-[532px]:rounded-tl-none  min-[532px]:rounded-bl-none  rounded-xl  max-md:px-5"
              onClick={() =>
                navigate("/dashboard/account-settings/change-email")
              }
            >
              Change Email
            </div>
          </div>
          <div className="flex gap-0 pr-20 mt-10 max-sm:pr-0 max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
            <button
              className="w-full sm:w-auto justify-center px-6 py-3 text-base font-semibold text-blue-900 border-blue-900 rounded-3xl cursor-pointer border-[1px]"
              onClick={() =>
                navigate("/dashboard/account-settings/recovery-codes")
              }
            >
              Generate New Recovery Codes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecuritySettings;
