import React from "react";
import usePortfolio from "../../../feature/dashboard/professionalProfile/profileEdit/portfolio/usePortfolio";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";
import { CiCircleMinus } from "react-icons/ci";
import PortfolioItem from "../../../components/Dashboard/profileEdit/PortfolioItem";

function Portfolio() {

  const {
    success, error, loading,
    displayed,
    showAddButton, showEditButton,
    handleAddButton, handleRemoveButton, handleEditButton, handleUpdateButton,
    postPortfolio, postImages, setPostPortfolio,
    addImage, removeImageFromArray, handleFeaturedUpaded,
    validationErrorMessage, showNotif, isSafeToSubmit, validateTitle, validateMessage, validateDescription,
  } = usePortfolio();

  return (
    <>
      {/* ADD PORTFOLIO  */}
      <div className="flex flex-col px-5 pb-6 border-b border-solid border-neutral-400 max-md:max-w-full">
        <div className="flex flex-row justify-between h-4 text-base tracking-tight max-md:flex-wrap">
          <div className="leading-[110%] text-neutral-600">
            Add Item to Portfolio
          </div>
          <div className="flex  gap-2 pr-px pl-20 font-medium text-green-700 leading-[120%] max-md:flex-wrap max-md:pl-5">
            {
              showNotif && (
                <>
                  {!loading && success && <SuccessIndicator message="Account updated." />}
                  {!loading && !success && error && <FailureIndicator message={error || "Account not updated."} />}
                </>
              )
            }
          </div>
        </div>
        <div className="mt-4 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
          Title
        </div>
        <input className="justify-center p-4 mt-4 text-base leading-5 whitespace-nowrap rounded-2xl border border-solid border-neutral-400 text-neutral-600 max-md:max-w-full"
          placeholder="Title"
          value={postPortfolio.title}
          onChange={(e) => {
            setPostPortfolio({ ...postPortfolio, title: e.target.value });
            validateTitle(e.target.value);
          }}
        />
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.title?.errorMessage} />

        <div className="mt-4 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
          Description
        </div>
        <textarea className="justify-center items-start px-4 pt-4 pb-14 mt-4 text-base leading-5 whitespace-nowrap rounded-2xl border border-solid border-neutral-400 text-neutral-600 max-md:pr-5 max-md:max-w-full"
          placeholder="Description"
          value={postPortfolio.description}
          onChange={(e) => {
            setPostPortfolio({ ...postPortfolio, description: e.target.value });
            validateDescription(e.target.value);
          }}
        />
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.description?.errorMessage} />

        <div className="mt-4 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
          Add Images
        </div>
        <div className="relative justify-center items-center p-6 mt-4 text-lg leading-6 text-center rounded-2xl border border-solid border-neutral-400 text-stone-300 max-md:px-5 max-md:max-w-full">
          <div className="text-base">Drag or Upload images here</div>
          <br />
          <span className="text-base">{`(PNG, JPG up to 2MB)`}</span>
          <input
            className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
            placeholder="Drag or Upload images here"
            type="file"
            accept="image/*"
            multiple="multiple"
            value=""
            onChange={addImage}
          />
        </div>

        {postImages?.length > 0 &&
          <div className="grid grid-cols-4 gap-4 content-center p-6 mt-4 rounded-2xl border border-solid border-neutral-400 max-md:px-5 max-md:max-w-full">
            {postImages.map((item, index) => {
              return <div key={index} className="relative inline-block">
                <button className="absolute right-16 top-2 text-blue-900 rounded-2xl hover:text-red-400 hover:bg-neutral-200"
                  onClick={() => { removeImageFromArray(item, index) }}>
                  <CiCircleMinus className="shrink-0 my-auto w-4 aspect-square align-middle text-red-500" />
                </button>
                <img
                  alt="img"
                  loading="lazy"
                  src={item.src}
                  className="w-20 aspect-square"
                />
              </div>
            })
            }
          </div>
        }

        {showAddButton &&
          <>
            <button className={`${(loading === false && isSafeToSubmit === true) ? '' : 'disabled'} justify-center self-end px-6 py-3 mt-10 text-base font-semibold text-white bg-blue-900 rounded-2xl max-md:px-5`}
              onClick={() => handleAddButton()}
            >
              Publish Portfolio
            </button>
            {loading && <LoadingIndicator loading={loading} styling='justify-center self-end px-6 py-3' />}
          </>
        }

        {showEditButton &&
          <>
            <button className={`${(loading === false && isSafeToSubmit === true) ? '' : 'disabled'} justify-center self-end px-6 py-3 mt-10 text-base font-semibold text-white bg-blue-900 rounded-2xl max-md:px-5`}
              onClick={() => handleUpdateButton()} f
            >
              Update Portfolio
            </button>
            {loading && <LoadingIndicator loading={loading} styling='justify-center self-end px-6 py-3' />}
          </>
        }
      </div>

      {/* PREVIEW PORTFOLIO  */}
      {displayed?.length > 0 &&
        <div className="flex flex-col p-4 max-md:max-w-full">
          <div className="text-3xl font-semibold leading-10 text-neutral-800 max-md:max-w-full">
            My Projects
          </div>
          <div className="grid grid-cols-2 gap-4 w-full">

            {
              displayed.map((item, index) => {
                const imageArray = [];
                item?.portfolio_images?.forEach((x, y) => {
                  imageArray.push(x.upload_path);
                });

                return <div key={index} className="col-span-1">
                  <PortfolioItem
                    data={item}
                    imageData={imageArray}
                    featureEdit={() => { handleFeaturedUpaded(item, !item.is_featured) }}
                    onEdit={handleEditButton}
                    onDelete={handleRemoveButton}
                    showControls={true}
                  />
                </div>
              })
            }
          </div>
        </div>
      }
    </>
  );
}

export default Portfolio;
