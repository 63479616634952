const InputValidationMessage = ({ message = null, showNotif }) => {

  return (
    <>
      {
        // (showNotif === true && message !== null)
        // && (
        <div className="text-red-600 text-sm mt-1 min-h-[20px]">
          {message}
        </div>
        // )
      }
    </>
  )
}

export default InputValidationMessage;