import React, { useEffect, useState } from "react";
import useLanguage from "../../../feature/dashboard/professionalProfile/profileEdit/language/useLanguage";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import Bubble from "../../../components/Bubble";
import LoadingIndicator from "../../../components/LoadingIndicator";
import useLanguageValidation from "../../../feature/dashboard/professionalProfile/profileEdit/language/useLanguageValidation";
import InputValidationMessage from "../../../components/InputValidationMessage";

function Language() {
  const {
    success, error, loading,
    typed, setTyped,
    displayedLanguages,
    handleOnload,
    handleTyping,
    handleAddbutton,
    handleRemoveButton,
  } = useLanguage();

  const { validationErrorMessage, showNotif, isSafeToSubmit, validateLanguage, } = useLanguageValidation();

  useEffect(() => {
    handleOnload();
  }, []);

  return (
    <>
      <div className="flex flex-col pb-20 max-md:mt-8 max-md:max-w-full">

        <div className="flex justify-between flex-row">
          <div>
            Add Languages
          </div>
          <div className="flex justify-end items-center">
            {
              showNotif && (
                <>
                  {loading && (<LoadingIndicator loading={loading} styling="text-base" />)}
                  {!loading && success && <SuccessIndicator message="Account updated." />}
                  {!loading && !success && error && <FailureIndicator message={error || "Account not updated."} />}
                </>
              )
            }
          </div>
        </div>


        <div className="flex gap-2 p-4 mt-4 text-sm leading-4 whitespace-nowrap rounded-2xl border border-solid border-neutral-400 text-neutral-600 max-md:flex-wrap max-md:pr-5">
          <input className="justify-center px-5 py-2 rounded-2xl bg-neutral-100"
            placeholder="Add Language"
            type="text"
            autoComplete="language"
            value={typed}
            onChange={(event) => {
              handleTyping(event.target.value);
              validateLanguage(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && isSafeToSubmit === true) {
                handleAddbutton(event.target.value);
                setTyped("");
              }
            }}
          />
          {displayedLanguages &&
            displayedLanguages.map((e, i) => {
              return <Bubble key={i} title={e.language} handleRemove={() => handleRemoveButton(e.id)}></Bubble>
            })
          }
        </div>
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage.language.errorMessage} />
      </div>
    </>
  );
}

export default Language;
