import { authAxios, authAxiosPut, basicAxios } from '../../../../config/common';

// Helper function to convert data to URL-encoded string
const toUrlEncodedString = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

// Helper function to create FormData from an object
const toFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  return formData;
};

// Helper function to handle Axios requests
const handleAxiosRequest = async (axiosInstance, method, url, data = null) => {
  try {
    const response = await axiosInstance[method](url, data);
    return JSON.parse(response.data);
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Change Password
const changePassword = async (userID, data) => {
  const urlencodedData = toUrlEncodedString(data);
  return handleAxiosRequest(authAxiosPut(), 'put', `api/user/${userID}/change-password`, urlencodedData);
};

// Check Password
const checkPassword = async (userID, data) => {
  const formData = toFormData(data);
  return handleAxiosRequest(authAxios(), 'post', `api/user/${userID}/check-password`, formData);
};

// Send OTP via Email
const sendOTPViaEmail = async (userID) => {
  return handleAxiosRequest(authAxios(), 'post', `api/user/${userID}/otp-via-email-send`);
};

// Verify OTP via Email
const verifyOTPViaEmail = async (userID, data) => {
  const formData = toFormData(data);
  return handleAxiosRequest(authAxios(), 'post', `api/user/${userID}/otp-via-email-verify`, formData);
};

// Send OTP via New Email
const sendOTPViaNewEmail = async (userID, data) => {
  const formData = toFormData(data);
  return handleAxiosRequest(authAxios(), 'post', `api/user/${userID}/otp-via-new-email-send`, formData);
};

// Verify OTP via New Email
const verifyOTPViaNewEmail = async (userID, data) => {
  const urlencodedData = toUrlEncodedString(data);
  return handleAxiosRequest(authAxiosPut(), 'put', `api/user/${userID}/otp-via-new-email-verify`, urlencodedData);
};

// Send OTP via Phone
const sendOTPViaPhone = async (email, phone_number) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('phone_number', phone_number);
  return handleAxiosRequest(basicAxios, 'post', 'api/otp-via-phone-send', formData);
};

// Verify OTP via Phone
const verifyOTPViaPhone = async (otp, email) => {
  const formData = new FormData();
  formData.append('otp', otp);
  formData.append('email', email);
  return handleAxiosRequest(basicAxios, 'post', 'api/otp-via-phone-verify', formData);
};

// Verify OTP via New Phone
const verifyOTPViaNewPhone = async (userID, data) => {
  const formData = toFormData(data);
  return handleAxiosRequest(authAxios(), 'post', `api/user/${userID}/otp-via-phone-verify`, formData);
};

// Send OTP via Email No Login
const sendOTPViaEmailNoLogin = async (data) => {
  const formData = toFormData(data);
  return handleAxiosRequest(basicAxios, 'post', 'api/otp-via-email-send', formData);
};

// Verify OTP via Email No Login
const verifyOTPViaEmailNoLogin = async (data) => {
  const formData = toFormData(data);
  return handleAxiosRequest(basicAxios, 'post', 'api/otp-via-email-verify', formData);
};

// Verify OTP Password Reset
const verifyOTPPasswordReset = async (data) => {
  const formData = toFormData(data);
  return handleAxiosRequest(basicAxios, 'post', 'api/reset-password-verify', formData);
};

// Get Recovery Codes
const getRecoveryCodes = async () => {
  return handleAxiosRequest(authAxios(), 'get', 'api/2fa/recovery-codes');
};

// Generate New Recovery Codes
const generateNewRecoveryCodes = async () => {
  return handleAxiosRequest(authAxios(), 'post', 'api/2fa/recovery-codes');
};

// Confirm Password Reset
const confirmPasswordReset = async (data) => {
  const urlencodedData = toUrlEncodedString(data);
  return handleAxiosRequest(basicAxios, 'put', 'api/reset-password-confirm', urlencodedData, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};

// Send Feedback
const sendFeedback = async (userID, data) => {
  const formData = toFormData(data);
  return handleAxiosRequest(authAxios(), 'post', `api/user/${userID}/feedbacks`, formData);
};

const securitySettingsAPI = {
  changePassword,
  checkPassword,
  sendOTPViaEmail,
  verifyOTPViaEmail,
  sendOTPViaNewEmail,
  verifyOTPViaNewEmail,
  sendOTPViaPhone,
  verifyOTPViaPhone,
  verifyOTPViaNewPhone,
  sendOTPViaEmailNoLogin,
  verifyOTPViaEmailNoLogin,
  verifyOTPPasswordReset,
  confirmPasswordReset,
  getRecoveryCodes,
  generateNewRecoveryCodes,
  sendFeedback,
};

export default securitySettingsAPI;
