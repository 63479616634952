// src/components/ShareButton.js
import React, { useState } from 'react';
import ShareModal from './ShareModal';

const ShareButton = ({ url, text, body }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button
        onClick={openModal}
        className="flex gap-2"
      >
        <img
          alt="img"
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3de3601293611a3605a42187b3f42511a88ebd046c8c0517da5037c2d30c465b?"
          className="shrink-0 my-auto w-4 aspect-square"
        />
        Share
      </button>
      <ShareModal isOpen={isModalOpen} onClose={closeModal} url={url} text={text} />
    </div>
  );
};

export default ShareButton;
