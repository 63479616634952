import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";

function Forum() {
  const [url,setUrl] = useState("");

  useEffect(() => {
    const token = localStorage.getItem('token');
    setUrl(`https://devreg.co.nz/phpBB3/token_login.php?user_token=${token}`);
  },[]);

  return (
    <Iframe
      url={url}
      width="100%"
      height="100%"
      display="initial"
      position="relative"
      className="min-h-screen min-w-fit"
    />
  );
}

export default Forum;
