import React, { useEffect, useState } from "react";
import BacktoLogin from "./BacktoLogin";
import PassUpdateSuccess from "./PassUpdateSuccess";
import { CiCircleCheck } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmPasswordReset,
  setConfirmPasswordResetSuccess,
} from "../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";
import Loader from "../../components/Loader";

function UpdatePassword({ email, session_key }) {
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [ismodalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const confirmPasswordResetStates = useSelector(
    (state) => state.settings.confirmPasswordReset
  ) || { success: null, error: null, loading: false };

  useEffect(() => {
    if (
      confirmPasswordResetStates.success ===
      "Password has been reset successfully. All related access tokens are cleared."
    ) {
      setUpdateSuccess(true);
      dispatch(setConfirmPasswordResetSuccess(null));
    }

    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [confirmPasswordResetStates.success, isFading, dispatch]);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setIsModalOpen(true);

    if (!passwordsMatch || confirmPassword.length <= 0) {
      setIsModalOpen(false); // Close the modal if validations fail
      return;
    }

    const data = {
      email,
      session_key,
      new_password: password,
      new_password_confirmation: confirmPassword,
    };

    try {
      await dispatch(confirmPasswordReset({ data }));
    } catch (error) {
      console.error("Password reset failed:", error);
    } finally {
      setIsModalOpen(false); // Ensure modal is closed after dispatch
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      setPassword(value);
      setIsLengthValid(value.length >= 8);
      setHasUpperCase(/[A-Z]/.test(value));
      setHasLowerCase(/[a-z]/.test(value));
      setHasNumber(/\d/.test(value));

      // eslint-disable-next-line
      setHasSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"|,.<>?]+/.test(value));
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    }
  };

  return (
    <>
      {!updateSuccess ? (
        <div className="absolute flex flex-col items-center justify-center min-w-full min-h-screen py-12 bg-sky-600">
          <BacktoLogin />
          <div className="flex flex-col p-12 mt-6 max-w-full text-sm bg-white rounded-3xl shadow-sm text-zinc-600 w-[420px] max-md:px-5">
            <img
              alt="Reset Password"
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9246731a30a25c33df9fcb23d2d86a1c5470b5117cb16633726554b18ba3f1bd?apiKey=3ae96302e69649f5be368f89230b0596&"
              className="self-center max-w-full aspect-[3.85] w-[282px]"
            />
            <div className="mt-8 text-xl font-semibold text-center text-neutral-800">
              Reset Password
            </div>
            <form onSubmit={handleUpdatePassword}>
              <div className="mt-2 text-base text-zinc-700 text-opacity-80">
                Enter your new password
              </div>
              <input
                placeholder="New Password"
                value={password}
                type="password"
                name="password"
                onChange={handleChange}
                autoComplete="on"
                className="w-full flex flex-col justify-center px-5 py-2.5 mt-4 text-base leading-5 whitespace-nowrap rounded-xl border border-solid border-neutral-400 text-neutral-600"
              />
              <input
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={handleChange}
                autoComplete="off"
                disabled={
                  !isLengthValid ||
                  !hasUpperCase ||
                  !hasLowerCase ||
                  !hasNumber ||
                  !hasSpecialChar
                }
                type="password"
                name="confirmPassword"
                className="w-full flex flex-col justify-center px-5 py-2.5 mt-6 text-base leading-5 whitespace-nowrap rounded-xl border border-solid border-neutral-400 text-neutral-600"
              />
              {!passwordsMatch && (
                <div className="mt-1 text-sm text-red-600">
                  Password doesn't match
                </div>
              )}

              <div className="w-full mt-4 text-base leading-8 text-zinc-600">
                Your password should:
              </div>
              <div
                className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                  isLengthValid ? "text-green-700" : "text-zinc-600"
                }`}
              >
                {isLengthValid ? (
                  <CiCircleCheck
                    size={21}
                    className="min-w-[20px] max-w-[20px]"
                  />
                ) : (
                  <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                )}
                <div className="flex-auto">
                  Be at least 8 characters in length
                </div>
              </div>
              <div
                className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                  hasUpperCase && hasLowerCase
                    ? "text-green-700"
                    : "text-zinc-600"
                }`}
              >
                {hasUpperCase && hasLowerCase ? (
                  <CiCircleCheck
                    size={21}
                    className="min-w-[20px] max-w-[20px]"
                  />
                ) : (
                  <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                )}
                <div className="flex-auto">
                  Contain both upper and lowercase alphabetic characters (e.g.
                  A-Z, a-z)
                </div>
              </div>
              <div
                className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                  hasNumber ? "text-green-700" : "text-zinc-600"
                }`}
              >
                {hasNumber ? (
                  <CiCircleCheck
                    size={21}
                    className="min-w-[20px] max-w-[20px]"
                  />
                ) : (
                  <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                )}
                <div className="flex-auto">
                  Have at least one numerical character (e.g. 0-9)
                </div>
              </div>
              <div
                className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                  hasSpecialChar ? "text-green-700" : "text-zinc-600"
                }`}
              >
                {hasSpecialChar ? (
                  <CiCircleCheck
                    size={21}
                    className="min-w-[20px] max-w-[20px]"
                  />
                ) : (
                  <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                )}
                <div className="flex-auto">
                  Have at least one special character (e.g. ~!@#$%^&*()_-+=)
                </div>
              </div>
              <button
                type="submit"
                disabled={confirmPasswordResetStates.loading}
                className="items-center justify-center w-full py-3 mt-12 text-base font-semibold text-center text-white bg-blue-900 cursor-pointer px-9 whitespace-nowrap rounded-3xl max-md:px-5 max-md:mt-10"
              >
                Update
              </button>
              {confirmPasswordResetStates.loading && (
                <p className="mt-2 text-center text-blue-600">Processing...</p>
              )}
              {confirmPasswordResetStates.error && (
                <p className="mt-2 text-center text-red-600">
                  {confirmPasswordResetStates.error}
                </p>
              )}
            </form>
          </div>
        </div>
      ) : (
        <PassUpdateSuccess />
      )}
      <Loader loadingText={"Updating..."} isOpen={ismodalOpen} />
    </>
  );
}

export default UpdatePassword;
