import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processPayment, validateCoupon, personalInfo } from "../../feature/authSlice";
import { useNavigate } from "react-router-dom";

const useStripePayment = () => {
  const auth = useSelector((state) => state.auth);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [ismodalOpen, setIsModalOpen] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [membershipCountryValues, setMembershipCountryValues] = useState({
    currencyName: "USD",
    currencySymbol: "$",
    membershipFeeForDisplay: "195",
    membershipFeeForComputation: 0,
    membershipRenewalFee: "100",
    updated: false,
  });

  const [coupon, setCoupon] = useState("");
  const [couponUsedCode, setCouponUsedCode] = useState("");
  const [couponValue, setCouponValue] = useState(0);
  // const [amount, setAmount] = useState(195);

  const [errorMessage, setErrorMessage] = React.useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMembershipValues = async () => {

    setMembershipCountryValues({
      currencyName: 'USD',
      currencySymbol: '$',
      membershipFee: 195,
      membershipRenewalFee: 135,
      membershipFeeForComputation: 195,
      updated: true,
    });

    // const id = auth.user.id || userData.id;

    // await dispatch(membershipValues({ id })).then(data => {
    //   if (data?.payload) {
    //     const x = data?.payload;

    //     setMembershipCountryValues({
    //       currencyName: x.currency_name,
    //       currencySymbol: x.currency_symbol,
    //       membershipFee: x.membership_fee,
    //       membershipRenewalFee: x.membership_renewal_fee,
    //       membershipFeeForComputation: x.membership_fee,
    //       updated: true,
    //     });
    //   }
    // });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsModalOpen(true);

    if (!stripe || !elements) {
      setIsModalOpen(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setErrorMessage(error.message);
      setIsModalOpen(false);
    } else {
      const formData = {
        paymentMethod: paymentMethod.id,
        coupon: coupon,
      };

      const { id } = userData || { id: 0 };
      await dispatch(processPayment({ id, data: { ...formData } })).then(data => {
        setIsModalOpen(false);
        
        if (data?.payload?.clientSecret) {
          const { clientSecret } = data.payload.clientSecret;
          const confirmResult = stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id,
          });

          if (confirmResult.error) {
            setErrorMessage(confirmResult.error.message);
          } else if (confirmResult.paymentIntent && confirmResult.paymentIntent.status === 'succeeded') {
            const udata = JSON.parse(localStorage.getItem('user'));
            udata.is_paid = "1";
            localStorage.setItem('user', JSON.stringify(udata));

            // Update is_paid to 1
            dispatch(personalInfo({ id, data: { is_paid: 1 } }));
            navigate('/register/payment-success');
          }
        }else{
          const udata = JSON.parse(localStorage.getItem('user'));
          udata.is_paid = "1";
          localStorage.setItem('user', JSON.stringify(udata));
          navigate('/register/payment-success');
        }
      });
    }
  };

  const handleValidateCoupon = async () => {
    if (coupon.trim() !== "") {
      const userData = JSON.parse(localStorage.getItem("user"));
      const { id } = userData || { id: 0 };

      await dispatch(validateCoupon({ id, data: { code: coupon } })).then(
        (data) => {
          if (data?.payload?.message === "Coupon code valid") {
            setCouponValue(data?.payload?.data?.amount || 0);
            // setCouponUsedCode(coupon);
          }else{
            setCouponValue(0);
          }
        }
      );
    }
  };

  const stringToFloat = async (strParam) => {
    if (strParam) {
      return parseFloat(strParam.replace(/,/g, ""));
    } else {
      return 0;
    }
  };

  const stringToInt = async (strParam) => {
    if (strParam) {
      return parseInt(strParam.replace(/,/g, ""));
    } else {
      return 0;
    }
  };

  return {
    membershipCountryValues,
    // amount, setAmount,
    coupon,
    setCoupon,
    couponValue,
    setCouponValue,
    errorMessage,
    handleSubmit,
    handleValidateCoupon,
    handleMembershipValues,
    ismodalOpen,
  };
};

export default useStripePayment;
