import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TwoStepVerification from "./TwoStepVerification";
import BacktoLogin from "./BacktoLogin";
import {
  sendOTPViaEmailNoLogin,
  setSendOTPViaEmailNoLoginSuccess,
} from "../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";
import Loader from "../../components/Loader";

function ForgotPass() {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const [isFading, setIsFading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sendOTPViaEmailNoLoginStates = useSelector(
    (state) => state.settings.sendOTPViaEmailNoLogin
  ) || { success: null, error: null, loading: false };
  const [ismodalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (sendOTPViaEmailNoLoginStates.success === "OTP sent") {
      setShowVerifyCode(true);
      dispatch(setSendOTPViaEmailNoLoginSuccess(null));
    }

    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [sendOTPViaEmailNoLoginStates.success, isFading, dispatch]);

  const validateEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(newEmail));
  };

  const handleSendEmailBtn = async (e) => {
    e.preventDefault();

    if (!isValidEmail || email.length <= 0) {
      setIsModalOpen(false); // Ensure modal is closed if email is invalid
      return;
    }

    try {
      setIsModalOpen(true);
      await dispatch(sendOTPViaEmailNoLogin({ data: { email } }));
    } catch (error) {
      console.error("Error sending OTP:", error);
      // Optionally handle the error (e.g., show a notification to the user)
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      {!showVerifyCode ? (
        <div className="absolute flex flex-col items-center justify-center min-w-full min-h-screen py-12 bg-sky-600">
          <BacktoLogin />
          <div className="flex flex-col p-12 mt-6 max-w-full font-semibold bg-white rounded-3xl shadow-sm w-[400px] max-md:px-8">
            <img
              alt="img"
              loading="lazy"
              onClick={() => navigate("/")}
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f66e9bd8f770a50a70d95ce8488b59d511fe1eadb3decf9a7a38ca33dd8ecbab?apiKey=3ae96302e69649f5be368f89230b0596&"
              className="self-center cursor-pointer w-full aspect-[4.17]"
            />
            <div className="mt-10 mr-auto text-xl text-center text-neutral-800 max-sm:mr-auto max-md:text-xl">
              Forgot Password
            </div>
            <div className="mt-2 text-sm text-zinc-700 text-opacity-80 max-sm:mx-auto max-sm:text-sm max-md:text-md">
              Enter your email and we’ll send you instructions to reset your
              password
            </div>
            <input
              value={email}
              onChange={validateEmail}
              placeholder="Email"
              className="justify-center items-start p-2.5 mt-6 whitespace-nowrap rounded-xl border border-solid border-neutral-400 leading-[121%] placeholder-text-stone-300 max-md:pr-5"
            />
            {!isValidEmail && (
              <div className="mt-1 text-sm text-red-600">
                Please enter a valid email address.
              </div>
            )}
            <button
              className={`items-center justify-center py-3 mt-4 text-base text-center text-white bg-blue-900 cursor-pointer px-9 rounded-3xl max-md:px-5 ${
                !isValidEmail || sendOTPViaEmailNoLoginStates.loading
                  ? "opacity-50 pointer-events-none"
                  : ""
              }`}
              onClick={handleSendEmailBtn}
              disabled={!isValidEmail || sendOTPViaEmailNoLoginStates.loading}
            >
              Send to Email
            </button>
            <div className="mt-2 text-sm max-sm:mx-auto max-sm:text-sm max-md:text-md">
              {sendOTPViaEmailNoLoginStates.loading && (
                <p className="text-left text-blue-600">Processing...</p>
              )}
              {sendOTPViaEmailNoLoginStates.error && (
                <p className="text-left text-red-600">
                  {sendOTPViaEmailNoLoginStates.error}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <TwoStepVerification email={email} />
      )}
      <Loader loadingText={"Sending..."} isOpen={ismodalOpen} />
    </>
  );
}

export default ForgotPass;
