import FormattedDateComponent from "../../FormattedDateComponent";

const HonorAwardsItems = ({ data, onEdit, onDelete, showControls = true }) => {
  return (
    <>
      <div className="flex gap-4 mt-8 max-md:flex-wrap">
        <img
          alt="img"
          loading="lazy"
          src={data.thumbnail_path}
          className="shrink-0 aspect-square w-[47px]"
        />
        <div className="flex flex-col flex-1 self-start max-md:max-w-full">
          <div className="font-bold max-md:max-w-full">
            {data.title}
          </div>
          <div className="max-md:max-w-full">
            {data.issued_by} · <FormattedDateComponent datetime={data.issued_date} />
          </div>
        </div>
      </div>
      <div className="mt-4 leading-6 max-md:max-w-full">
        {data.description}
      </div>
      <div className="self-end mt-4 text-lg font-medium text-blue-900">
        {showControls &&
          <><button onClick={() => onEdit(data.id)}>Edit</button> | <button onClick={() => onDelete(data.id)}>Delete</button></>
        }
      </div>
    </>
  )
}

export default HonorAwardsItems