
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import ValidationHelperChain from "../../../../../utils/ValidationHelperChain";
import Debounce from "../../../../../utils/Debounce";
import { DebounceDelay } from "../../../../../utils/Debounce";
import { featureAction as action } from "./featureSlice";


const useFeatureValidation = () => {
  const dispatch = useDispatch();

  const itemDefaultValue = { isValid: false, errorMessage: [] };
  const validationErrorMessageDefault = {
    title: itemDefaultValue,
    content: itemDefaultValue,
    link: itemDefaultValue,
    banner: itemDefaultValue,
    keywords: itemDefaultValue,
  };
  const [validationErrorMessage, setValidationErrorMessage] = useState(validationErrorMessageDefault);

  const strValidationErrorMessage = JSON.stringify(validationErrorMessage);
  const [isSafeToSubmit, setIsSafeToSubmit] = useState(false);

  const { success, error, loading, values } = useSelector(state => state.feature);
  const strNotifiers = JSON.stringify(`${success} ${error} ${loading}`);

  const [showNotif, setShowNotif] = useState(true);


  useEffect(() => {
    setIsSafeToSubmit(hasValidationError() === true || validationErrorMessage === validationErrorMessageDefault ? false : true);
    if (showNotif === true) {
      setTimeout(() => {
        setShowNotif(false);
        // setErrorsToEmpty();
        dispatch(action.setError(null));
        dispatch(action.setSuccess(false));
      }, 15000);
    } else {
      setShowNotif(true);
    }
  }, [strValidationErrorMessage, strNotifiers]);

  const hasValidationError = () => {
    return Object.values(validationErrorMessage).some(
      (item) => item.errorMessage?.length > 0
    );
  };

  const setErrorsToEmpty = () => {
    setValidationErrorMessage(validationErrorMessageDefault);
  }

  const validateTitle = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 3 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, title: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, title: { isValid: true, errorMessage: null } })); }

  }, DebounceDelay);

  const validateContent = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 10 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, content: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, content: { isValid: true, errorMessage: null } })); }

  }, DebounceDelay);

  const validateLink = Debounce((str) => {
    const validation = ValidationHelperChain().URL({ str: str }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, link: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, link: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateKeywords = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 3 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, keywords: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, keywords: { isValid: true, errorMessage: null } })); }
  }, 2000);

  return {
    values,
    success, error, loading,
    showNotif, isSafeToSubmit,
    validateTitle,
    validateContent,
    validateLink,
    validateKeywords,
    validationErrorMessage, setValidationErrorMessage,
  }
}

export default useFeatureValidation;
