import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import securitySettingsAPI from "./securitySettingsApi";

// Helper function to handle async thunks
const createAsyncThunkWithAPI = (type, apiCall) => {
  return createAsyncThunk(type, async (args, { rejectWithValue }) => {
    try {
      const response = await apiCall(args);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  });
};

export const changePassword = createAsyncThunkWithAPI(
  '/security-settings/change-password',
  ({ id, data }) => securitySettingsAPI.changePassword(id, data)
);

export const checkPassword = createAsyncThunkWithAPI(
  '/security-settings/check-password',
  ({ id, data }) => securitySettingsAPI.checkPassword(id, data)
);

export const sendOTPViaEmail = createAsyncThunkWithAPI(
  '/security-settings/otp-via-email-send',
  ({ id }) => securitySettingsAPI.sendOTPViaEmail(id)
);

export const verifyOTPViaEmail = createAsyncThunkWithAPI(
  '/security-settings/otp-via-email-verify',
  ({ id, data }) => securitySettingsAPI.verifyOTPViaEmail(id, data)
);

export const resendOTPViaEmail = createAsyncThunkWithAPI(
  '/security-settings/otp-via-email-resend',
  ({ id }) => securitySettingsAPI.sendOTPViaEmail(id)
);

export const sendOTPViaNewEmail = createAsyncThunkWithAPI(
  '/security-settings/otp-via-new-email-send',
  ({ id, data }) => securitySettingsAPI.sendOTPViaNewEmail(id, data)
);

export const verifyOTPViaNewEmail = createAsyncThunkWithAPI(
  '/security-settings/otp-via-new-email-verify',
  ({ id, data }) => securitySettingsAPI.verifyOTPViaNewEmail(id, data)
);

export const resendOTPViaNewEmail = createAsyncThunkWithAPI(
  '/security-settings/otp-via-new-email-resend',
  ({ id, data }) => securitySettingsAPI.sendOTPViaNewEmail(id, data)
);

// Add phone number related actions and reducers

export const sendOTPViaPhone = createAsyncThunkWithAPI(
  '/security-settings/otp-via-phone-send',
  ({ email, phone_number }) => securitySettingsAPI.sendOTPViaPhone(email, phone_number)
);

export const verifyOTPViaPhone = createAsyncThunkWithAPI(
  '/security-settings/otp-via-phone-verify',
  ({ otp, email }) => securitySettingsAPI.verifyOTPViaPhone(otp, email)
);

export const resendOTPViaPhone = createAsyncThunkWithAPI(
  '/security-settings/otp-via-phone-resend',
  ({ email, phone_number }) => securitySettingsAPI.sendOTPViaPhone(email, phone_number)
);

export const sendOTPViaNewPhone = createAsyncThunkWithAPI(
  '/security-settings/otp-via-new-phone-send',
  ({ email, phone_number }) => securitySettingsAPI.sendOTPViaPhone(email, phone_number)
);

export const verifyOTPViaNewPhone = createAsyncThunkWithAPI(
  '/security-settings/otp-via-new-phone-verify',
  ({ id, data }) => securitySettingsAPI.verifyOTPViaNewPhone(id, data)
);

export const sendOTPViaEmailNoLogin = createAsyncThunkWithAPI(
  '/security-settings/otp-via-email-send-no-login',
  ({ data }) => securitySettingsAPI.sendOTPViaEmailNoLogin(data)
);

export const resendOTPViaEmailNoLogin = createAsyncThunkWithAPI(
  '/security-settings/otp-via-email-resend-no-login',
  ({ data }) => securitySettingsAPI.sendOTPViaEmailNoLogin(data)
);

export const verifyOTPViaEmailNoLogin = createAsyncThunkWithAPI(
  '/security-settings/otp-via-email-verify-no-login',
  ({ data }) => securitySettingsAPI.verifyOTPViaEmailNoLogin(data)
);

export const verifyOTPPasswordReset = createAsyncThunkWithAPI(
  '/security-settings/reset-password-verify',
  ({ data }) => securitySettingsAPI.verifyOTPPasswordReset(data)
);

export const confirmPasswordReset = createAsyncThunkWithAPI(
  '/security-settings/reset-password-confirm',
  ({ data }) => securitySettingsAPI.confirmPasswordReset(data)
);

export const getRecoveryCodes = createAsyncThunkWithAPI(
  '/security-settings/get-recovery-codes',
  () => securitySettingsAPI.getRecoveryCodes()
);

export const generateNewRecoveryCodes = createAsyncThunkWithAPI(
  '/security-settings/generate-new-recovery-codes',
  () => securitySettingsAPI.generateNewRecoveryCodes()
);

export const sendFeedback = createAsyncThunkWithAPI(
  '/security-settings/send-feedback',
  ({ id, data }) => securitySettingsAPI.sendFeedback(id, data)
);

// Initial state
const initialState = {
  changePassword: {
    success: null,
    loading: false,
    error: null,
  },
  checkPassword: {
    success: null,
    loading: false,
    error: null,
  },
  sendOTPViaEmail: {
    success: null,
    loading: false,
    error: null,
  },
  verifyOTPViaEmail: {
    success: null,
    loading: false,
    error: null,
  },
  resendOTPViaEmail: {
    success: null,
    loading: false,
    error: null,
  },
  sendOTPViaNewEmail: {
    success: null,
    loading: false,
    error: null,
  },
  verifyOTPViaNewEmail: {
    success: null,
    loading: false,
    error: null,
  },
  resendOTPViaNewEmail: {
    success: null,
    loading: false,
    error: null,
  },
  sendOTPViaPhone: {
    success: null,
    loading: false,
    error: null,
  },
  verifyOTPViaPhone: {
    success: null,
    loading: false,
    error: null,
  },
  resendOTPViaPhone: {
    success: null,
    loading: false,
    error: null,
  },
  sendOTPViaNewPhone: {
    success: null,
    loading: false,
    error: null,
  },
  verifyOTPViaNewPhone: {
    success: null,
    loading: false,
    error: null,
  },
  sendOTPViaEmailNoLogin: {
    success: null,
    loading: false,
    error: null,
  },
  resendOTPViaEmailNoLogin: {
    success: null,
    loading: false,
    error: null,
  },
  verifyOTPViaEmailNoLogin: {
    success: null,
    loading: false,
    error: null,
  },
  verifyOTPPasswordReset: {
    success: null,
    loading: false,
    error: null,
    sessionKey: null,
  },
  confirmPasswordReset: {
    success: null,
    loading: false,
    error: null,
  },
  sendFeedback: {
    success: null,
    loading: false,
    error: null,
  },
  getRecoveryCodes: {
    success: null,
    loading: false,
    error: null,
  },
  generateNewRecoveryCodes: {
    success: null,
    loading: false,
    error: null,
  },
};

// Reusable reducers for pending, fulfilled, and rejected states with payload conditions
const createReducersWithPayloadConditions = (builder, action, name, successMessages) => {
  builder
    .addCase(action.pending, (state) => {
      state[name].loading = true;
      state[name].error = null;
    })
    .addCase(action.rejected, (state, action) => {
      state[name].loading = false;
      state[name].error = action.payload;
    })
    .addCase(action.fulfilled, (state, action) => {
      state[name].loading = false;
      const { message, user, session_key } = action.payload || {};

      if (successMessages.includes(message)) {
        state[name].success = message;
        state[name].error = null;

        if (name === 'verifyOTPViaNewEmail' && user) {
          const uData = JSON.parse(localStorage.getItem('user'));
          uData.email = user.email;
          localStorage.setItem('user', JSON.stringify(uData));
        }

        if (name === 'verifyOTPViaNewPhone' && user) {
          const uData = JSON.parse(localStorage.getItem('user'));
          uData.phone_number = user.phone_number;
          localStorage.setItem('user', JSON.stringify(uData));
        }

        if (name === 'verifyOTPPasswordReset' && session_key) {
          state.verifyOTPPasswordReset.sessionKey = session_key;
        }
      } else {
        state[name].error = message;
      }
    });
};

const securitySettingsSlice = createSlice({
  name: 'securitySettings',
  initialState,
  reducers: {
    setChangePasswordError: (state, action) => {
      state.changePassword.error = action?.payload || null;
    },
    setChangePasswordSuccess: (state, action) => {
      state.changePassword.success = action?.payload || null;
    },
    setCheckPasswordError: (state, action) => {
      state.checkPassword.error = action?.payload || null;
    },
    setCheckPasswordSuccess: (state, action) => {
      state.checkPassword.success = action?.payload || null;
    },
    setSendOTPViaEmailError: (state, action) => {
      state.sendOTPViaEmail.error = action?.payload || null;
    },
    setSendOTPViaEmailSuccess: (state, action) => {
      state.sendOTPViaEmail.success = action?.payload || null;
    },
    setVerifyOTPViaEmailError: (state, action) => {
      state.verifyOTPViaEmail.error = action?.payload || null;
    },
    setVerifyOTPViaEmailSuccess: (state, action) => {
      state.verifyOTPViaEmail.success = action?.payload || null;
    },
    setResendOTPViaEmailError: (state, action) => {
      state.resendOTPViaEmail.error = action?.payload || null;
    },
    setResendOTPViaEmailSuccess: (state, action) => {
      state.resendOTPViaEmail.success = action?.payload || null;
    },
    setSendOTPViaNewEmailError: (state, action) => {
      state.sendOTPViaNewEmail.error = action?.payload || null;
    },
    setSendOTPViaNewEmailSuccess: (state, action) => {
      state.sendOTPViaNewEmail.success = action?.payload || null;
    },
    setVerifyOTPViaNewEmailError: (state, action) => {
      state.verifyOTPViaNewEmail.error = action?.payload || null;
    },
    setVerifyOTPViaNewEmailSuccess: (state, action) => {
      state.verifyOTPViaNewEmail.success = action?.payload || null;
    },
    setResendOTPViaNewEmailError: (state, action) => {
      state.resendOTPViaNewEmail.error = action?.payload || null;
    },
    setResendOTPViaNewEmailSuccess: (state, action) => {
      state.resendOTPViaNewEmail.success = action?.payload || null;
    },
    setSendOTPViaPhoneError: (state, action) => {
      state.sendOTPViaPhone.error = action?.payload || null;
    },
    setSendOTPViaPhoneSuccess: (state, action) => {
      state.sendOTPViaPhone.success = action?.payload || null;
    },
    setVerifyOTPViaPhoneError: (state, action) => {
      state.verifyOTPViaPhone.error = action?.payload || null;
    },
    setVerifyOTPViaPhoneSuccess: (state, action) => {
      state.verifyOTPViaPhone.success = action?.payload || null;
    },
    setResendOTPViaPhoneError: (state, action) => {
      state.resendOTPViaPhone.error = action?.payload || null;
    },
    setResendOTPViaPhoneSuccess: (state, action) => {
      state.resendOTPViaPhone.success = action?.payload || null;
    },
    setSendOTPViaNewPhoneError: (state, action) => {
      state.sendOTPViaNewPhone.error = action?.payload || null;
    },
    setSendOTPViaNewPhoneSuccess: (state, action) => {
      state.sendOTPViaNewPhone.success = action?.payload || null;
    },
    setVerifyOTPViaNewPhoneError: (state, action) => {
      state.verifyOTPViaNewPhone.error = action?.payload || null;
    },
    setVerifyOTPViaNewPhoneSuccess: (state, action) => {
      state.verifyOTPViaNewPhone.success = action?.payload || null;
    },
    setSendOTPViaEmailNoLoginSuccess: (state, action) => {
      state.sendOTPViaEmailNoLogin.success = action?.payload || null;
    },
    setSendOTPViaEmailNoLoginError: (state, action) => {
      state.sendOTPViaEmailNoLogin.error = action?.payload || null;
    },
    setResendOTPViaEmailNoLoginSuccess: (state, action) => {
      state.resendOTPViaEmailNoLogin.success = action?.payload || null;
    },
    setResendOTPViaEmailNoLoginError: (state, action) => {
      state.resendOTPViaEmailNoLogin.error = action?.payload || null;
    },
    setVerifyOTPViaEmailNoLoginSuccess: (state, action) => {
      state.verifyOTPViaEmailNoLogin.success = action?.payload || null;
    },
    setVerifyOTPViaEmailNoLoginError: (state, action) => {
      state.verifyOTPViaEmailNoLogin.error = action?.payload || null;
    },
    setVerifyOTPPasswordResetSuccess: (state, action) => {
      state.verifyOTPPasswordReset.success = action?.payload || null;
    },
    setVerifyOTPPasswordResetError: (state, action) => {
      state.verifyOTPPasswordReset.error = action?.payload || null;
    },
    setConfirmPasswordResetSuccess: (state, action) => {
      state.confirmPasswordReset.success = action?.payload || null;
    },
    setConfirmPasswordResetError: (state, action) => {
      state.confirmPasswordReset.error = action?.payload || null;
    },
    setFeedbackError: (state, action) => {
      state.sendFeedback.error = action?.payload || null;
    },
    setFeedbackSuccess: (state, action) => {
      state.sendFeedback.success = action?.payload || null;
    },
    setGetRecoveryCodesError: (state, action) => {
      state.getRecoveryCodes.error = action?.payload || null;
    },
    setGetRecoveryCodesSuccess: (state, action) => {
      state.getRecoveryCodes.success = action?.payload || null;
    },
    setGenerateNewRecoveryCodesError: (state, action) => {
      state.generateNewRecoveryCodes.error = action?.payload || null;
    },
    setGenerateNewRecoveryCodesSuccess: (state, action) => {
      state.generateNewRecoveryCodes.success = action?.payload || null;
    },
  },
  extraReducers: (builder) => {
    createReducersWithPayloadConditions(builder, changePassword, 'changePassword', ['Password changed successfully']);
    createReducersWithPayloadConditions(builder, checkPassword, 'checkPassword', ['Password is correct!']);
    createReducersWithPayloadConditions(builder, sendOTPViaEmail, 'sendOTPViaEmail', ['OTP sent']);
    createReducersWithPayloadConditions(builder, verifyOTPViaEmail, 'verifyOTPViaEmail', ['OTP is valid!']);
    createReducersWithPayloadConditions(builder, resendOTPViaEmail, 'resendOTPViaEmail', ['OTP sent']);
    createReducersWithPayloadConditions(builder, sendOTPViaNewEmail, 'sendOTPViaNewEmail', ['OTP sent']);
    createReducersWithPayloadConditions(builder, verifyOTPViaNewEmail, 'verifyOTPViaNewEmail', ['Email changed successfully!']);
    createReducersWithPayloadConditions(builder, resendOTPViaNewEmail, 'resendOTPViaNewEmail', ['OTP sent']);
    createReducersWithPayloadConditions(builder, sendOTPViaPhone, 'sendOTPViaPhone', ['OTP sent']);
    createReducersWithPayloadConditions(builder, verifyOTPViaPhone, 'verifyOTPViaPhone', ['OTP is valid!']);
    createReducersWithPayloadConditions(builder, resendOTPViaPhone, 'resendOTPViaPhone', ['OTP sent']);
    createReducersWithPayloadConditions(builder, sendOTPViaNewPhone, 'sendOTPViaNewPhone', ['OTP sent']);
    createReducersWithPayloadConditions(builder, verifyOTPViaNewPhone, 'verifyOTPViaNewPhone', ['Phone Number changed successfully!']);
    createReducersWithPayloadConditions(builder, sendOTPViaEmailNoLogin, 'sendOTPViaEmailNoLogin', ['OTP sent']);
    createReducersWithPayloadConditions(builder, resendOTPViaEmailNoLogin, 'resendOTPViaEmailNoLogin', ['OTP sent']);
    createReducersWithPayloadConditions(builder, verifyOTPViaEmailNoLogin, 'verifyOTPViaEmailNoLogin', ['OTP is valid!']);
    createReducersWithPayloadConditions(builder, verifyOTPPasswordReset, 'verifyOTPPasswordReset', ['Verification code is valid!']);
    createReducersWithPayloadConditions(builder, confirmPasswordReset, 'confirmPasswordReset', ['Password has been reset successfully. All related access tokens are cleared.']);
    createReducersWithPayloadConditions(builder, sendFeedback, 'sendFeedback', ['Message Sent']);
    createReducersWithPayloadConditions(builder, getRecoveryCodes, 'getRecoveryCodes', []);
    createReducersWithPayloadConditions(builder, generateNewRecoveryCodes, 'generateNewRecoveryCodes', []);
  },
});

export const { 
  setChangePasswordError, 
  setChangePasswordSuccess,
  setCheckPasswordError,
  setCheckPasswordSuccess,
  setSendOTPViaEmailError,
  setSendOTPViaEmailSuccess,
  setVerifyOTPViaEmailError,
  setVerifyOTPViaEmailSuccess,
  setResendOTPViaEmailError,
  setResendOTPViaEmailSuccess,
  setSendOTPViaNewEmailError,
  setSendOTPViaNewEmailSuccess,
  setVerifyOTPViaNewEmailError,
  setVerifyOTPViaNewEmailSuccess,
  setResendOTPViaNewEmailError,
  setResendOTPViaNewEmailSuccess,
  setSendOTPViaPhoneError,
  setSendOTPViaPhoneSuccess,
  setVerifyOTPViaPhoneError,
  setVerifyOTPViaPhoneSuccess,
  setResendOTPViaPhoneError,
  setResendOTPViaPhoneSuccess,
  setSendOTPViaNewPhoneError,
  setSendOTPViaNewPhoneSuccess,
  setVerifyOTPViaNewPhoneError,
  setVerifyOTPViaNewPhoneSuccess,
  setSendOTPViaEmailNoLoginError,
  setSendOTPViaEmailNoLoginSuccess,
  setVerifyOTPPasswordResetSuccess,
  setVerifyOTPPasswordResetError,
  setConfirmPasswordResetSuccess,
  setConfirmPasswordResetError,
  setResendOTPViaEmailNoLoginError,
  setResendOTPViaEmailNoLoginSuccess,
  setGetRecoveryCodesError,
  setGetRecoveryCodesSuccess,
  setGenerateNewRecoveryCodesError,
  setGenerateNewRecoveryCodesSuccess,
  setFeedbackError,
  setFeedbackSuccess,
} = securitySettingsSlice.actions;

export default securitySettingsSlice.reducer;
