import React from "react";
import BannerProfile from "../../../components/Dashboard/BannerProfile";
import { Navigate } from "react-router-dom";
import useInspectRoute from "../../../api/customHook/useInspectRoute";

import useMessages from "../../../feature/dashboard/messages/useMessages";
import useFullIdCheckMessages from "../../../feature/dashboard/messages/fullIdCheck/useFullIdCheckMessages";
import useRegisterMessages from "../../../feature/dashboard/messages/theRegister/useRegisterMessages";
import useAdminMessages from "../../../feature/dashboard/messages/admin/useAdminMessages";
import MessagesMenu, { MessageMenuSmallerScreen } from "./messagesMenu";
import MessageContent, { MessageContentSmallerScreen } from "./messageContent";
import SearchBar from "./searchBar";
import MessagesFullIdCheck from "./MessagesFullIdCheck";
import MessagesTheRegister from "./MessagesTheRegister";
import MessagesAdmin from "./MessagesAdmin";

import LoadingIndicator from "../../../components/LoadingIndicator";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";

import ResponsiveValues from "../../../components/ResponsiveValues";

function Messages() {
  const { shouldRedirect, redirectTo } = useInspectRoute("/dashboard/messages");
  const {
    userData,
    success, error, loading, showNotif,
    postMessage, setPostMessage, defaultMessageValues,
    toggleTab, setToggleTab,
    searchText, setSearchText,
    showMessage, setShowMessage,
    showReplyPanel, setShowReplyPanel,
    isHovered, setIsHovered,
    messageContent, setMessageContent,
    handleOpenMessage, handleReplyMessage,
    handleRemoveButton
  } = useMessages();

  const { handleRemoveButton: handleRemoveButtonF,
    handleReadUnRead: handleReadUnReadF,
    getConversations: getConversationF,
    conversation: conversationF,
  } = useFullIdCheckMessages();
  const { handleRemoveButton: handleRemoveButtonR,
    handleReadUnRead: handleReadUnReadR,
    getConversations: getConversationR,
    conversation: conversationR,
  } = useRegisterMessages();
  const { handleRemoveButton: handleRemoveButtonA,
    handleReadUnRead: handleReadUnReadA,
    getConversations: getConversationA,
    conversation: conversationA,
  } = useAdminMessages();

  const getRemoveFunction = () => {
    switch (toggleTab) {
      case 1:
        return { primaryFunction: handleRemoveButtonF, defaultFunction: handleRemoveButton };
      case 2:
        return { primaryFunction: handleRemoveButtonR, defaultFunction: handleRemoveButton };
      case 3:
        return { primaryFunction: handleRemoveButtonA, defaultFunction: handleRemoveButton };
      default:
        return { primaryFunction: null, secondaryFunction: null };
    }
  };

  const getReadUnReadFunction = () => {
    switch (toggleTab) {
      case 1:
        return handleReadUnReadF;
      case 2:
        return handleReadUnReadR;
      case 3:
        return handleReadUnReadA;
      default:
        return handleReadUnReadF;
    }
  };

  const getConversationFunction = () => {
    switch (toggleTab) {
      case 1:
        return { getConversation: getConversationF, conversation: conversationF };
      case 2:
        return { getConversation: getConversationR, conversation: conversationR };
      case 3:
        return { getConversation: getConversationA, conversation: conversationA };
      default:
        return { getConversation: getConversationF, conversation: conversationF };
    }
  };

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <>
      <div className={`${ResponsiveValues.forDesktopScreen} min-h-screen flex flex-col flex-grow`}>
        <div className="m-auto">
          <BannerProfile data={userData} />

          {!showMessage && (
            <>
              <div className="flex flex-row items-center justify-between title text-[30px] mt-4 font-bold max-sm:text-[23px]">
                <div>Message inbox</div>
                <div>
                  {showNotif && (
                    <>
                      {loading && <LoadingIndicator loading={loading} styling="text-base" />}
                      {!loading && success && <SuccessIndicator message="Update Success" />}
                      {!loading && !success && error && <FailureIndicator message={error} />}
                    </>
                  )}
                </div>
              </div>

              <MessagesMenu
                toggleTab={toggleTab}
                setToggleTab={setToggleTab}
              />
              <div className="h-[2px] mt-[-2px] w-full bg-[#bab8b8] rounded-full "></div>

              {/* Begin - List of messages */}
              <div className="mt-2">
                {toggleTab === 1 && <MessagesFullIdCheck
                  isHovered={isHovered}
                  loading={loading}
                  setIsHovered={setIsHovered}
                  setShowMessage={setShowMessage}
                  handleOpenMessage={handleOpenMessage}
                  onClickCheckedBox={() => { }}
                />}
                {toggleTab === 2 && <MessagesTheRegister
                  isHovered={isHovered}
                  loading={loading}
                  setIsHovered={setIsHovered}
                  setShowMessage={setShowMessage}
                  handleOpenMessage={handleOpenMessage}
                  onClickCheckedBox={() => { }}
                />}
                {toggleTab === 3 && <MessagesAdmin
                  isHovered={isHovered}
                  loading={loading}
                  setIsHovered={setIsHovered}
                  setShowMessage={setShowMessage}
                  handleOpenMessage={handleOpenMessage}
                  onClickCheckedBox={() => { }}
                />}
              </div>
              {/* End - List of messages */}
            </>
          )}

          {/* Start - Message Contents and Reply */}
          {showMessage &&
            <MessageContent
              data={{ ...messageContent, userData: userData, getConversation: getConversationFunction().getConversation, conversation: getConversationFunction().conversation }}
              getConversation={getConversationFunction().getConversation}
              conversation={getConversationFunction().conversation}
              showReplyPanel={showReplyPanel}
              onClickBackButton={() => {
                setShowMessage(false);
                setMessageContent(defaultMessageValues);
              }}
              onClickShowReplyPanel={() => setShowReplyPanel(true)}
              onClickTrashBin={getRemoveFunction()}
              handleReadUnRead={getReadUnReadFunction()}
              postMessage={postMessage}
              setPostMessage={setPostMessage}
              handleReplyMessage={() => { handleReplyMessage(messageContent, postMessage) }}
            />
          }
          {/* End - Message Contents and Reply */}
        </div>
      </div>

      {/* Begin - Smaller Screen */}
      <div className={`${ResponsiveValues.forMobileScreen} min-h-screen flex flex-col flex-grow`}>
        {!showMessage && (
          <>
            <div className="h-[1px] mt-1 w-full bg-[#e2e2e2] rounded-full "></div>
            <SearchBar
              value={searchText}
              onChange={setSearchText}
            />
            <div className="h-[1px] w-full bg-[#e2e2e2] rounded-full "></div>
            <div className="flex flex-cols justify-between items-center">
              <MessageMenuSmallerScreen
                toggleTab={toggleTab}
                setToggleTab={setToggleTab}
              />
              {showNotif && (
                <>
                  {loading && <LoadingIndicator loading={loading} styling='text-base' />}
                  {!loading && success && <SuccessIndicator message='Update Success' max={25} />}
                  {!loading && !success && error && <FailureIndicator message={error} max={25} />}
                </>
              )}
            </div>

            <div className="h-[1px] w-full bg-[#e2e2e2] rounded-full "></div>
            <div className="h-[1px] w-full bg-[#e2e2e2] rounded-full "></div>
            <div className="mt-2">
              {toggleTab === 1 && <MessagesFullIdCheck
                isHovered={isHovered}
                loading={loading}
                setIsHovered={setIsHovered}
                setShowMessage={setShowMessage}
                handleOpenMessage={handleOpenMessage}
                onClickCheckedBox={() => { }}
              />}
              {toggleTab === 2 && <MessagesTheRegister
                isHovered={isHovered}
                loading={loading}
                setIsHovered={setIsHovered}
                setShowMessage={setShowMessage}
                handleOpenMessage={handleOpenMessage}
                onClickCheckedBox={() => { }}
              />}
              {toggleTab === 3 && <MessagesAdmin
                isHovered={isHovered}
                loading={loading}
                setIsHovered={setIsHovered}
                setShowMessage={setShowMessage}
                handleOpenMessage={handleOpenMessage}
                onClickCheckedBox={() => { }}
              />}
            </div>
          </>
        )}

        {showMessage && (
          <MessageContentSmallerScreen
            data={{ ...messageContent, userData: userData, getConversation: getConversationFunction().getConversation, conversation: getConversationFunction().conversation }}
            showReplyPanel={showReplyPanel}
            onClickBackButton={() => {
              setShowMessage(false);
              setMessageContent(defaultMessageValues);
            }}
            onClickShowReplyPanel={() => setShowReplyPanel(true)}
            onClickTrashBin={getRemoveFunction()}
            handleReadUnRead={getReadUnReadFunction()}
            postMessage={postMessage}
            setPostMessage={setPostMessage}
            handleReplyMessage={() => { handleReplyMessage(messageContent, postMessage) }}
          />
        )}
      </div>
      {/* End - Search on smaller screens */}
    </>
  );
}

export default Messages;
