import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function PhoneNumberWithFlag({ onPhoneNumberChange }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("us");
  const [valid, setValid] = useState(true);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchCountryCode(latitude, longitude).then((code) => {
            setCountryCode(code);
          });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    }
  }, []);

  const fetchCountryCode = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`
      );
      const data = await response.json();
      return data.countryCode.toLowerCase();
    } catch (error) {
      console.error("Error fetching country code:", error);
      return "us";
    }
  };

  const handleChange = (value) => {
    setPhoneNumber(value);
    setValid(validatePhoneNumber(value));
    // Pass the phone number to the parent component
    onPhoneNumberChange(value);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  return (
    <div className="mt-3">
      <PhoneInput
        country={countryCode}
        value={phoneNumber}
        onChange={handleChange}
        inputStyle={{
          width: "100%",
          padding: "0.75rem",
          fontSize: "1rem",
          lineHeight: "1.5",
          color: "black",
          backgroundColor: "#fff",
          border: "1px solid #BCBCBC",
          borderRadius: ".6rem",
          paddingLeft: "50px",
          transition:
            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
        }}
        buttonStyle={{
          backgroundColor: "#fff",
          border: "1px solid #BCBCBC",
          borderTopRightRadius: "0",
          borderBottomRightRadius: "0",
        }}
      />
    </div>
  );
}

export default PhoneNumberWithFlag;
