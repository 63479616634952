import FileIcons from "../../feature/dashboard/uploadDocuments/accessKey/fileIcons"
import LoadingIndicator from "../../components/LoadingIndicator";
import FailureIndicator from "../../components/FailureIndicator";

const UploadAdditionalDocument = ({ success, error, loading, uploadedAll, addedIdToInput, addedBillingToInput, handleContinue, }) => {
  return (
    <div className="flex flex-col mx-auto mt-14 max-w-[750px] max-sm:px-10">
      <div className="px-10">
        <div className="font-bold text-[#1c3775] text-2xl whitespace-nowrap">
          Document Requirements
        </div>
        <div className="mt-3.5 text-base leading-6 text-neutral-800 w-[544px] max-md:max-w-full">
          Please upload the following:
          <br />
          <br />
          ● National ID
          <br />
          ● Postal ID with Photo
        </div>

        <div className="relative container border-neutral-400 border-2 rounded-2xl mt-4">
          <div className="py-16 text-neutral-300">
            <div className="text-center">
              Drag or Upload your documents here
            </div>
            <input
              className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
              type="file"
              accept="application/pdf, image/*, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              multiple="multiple"
              value=""
              onChange={(e) => { addedIdToInput(e) }}
            />
            <div className="text-center">{`jpg, png, pdf, word`}</div>
          </div>
        </div>

        <br />
        ● Latest Billing Statement

        <div className="relative container border-neutral-400 border-2 rounded-2xl mt-4">
          <div className="py-16 text-neutral-300">
            <div className="text-center">
              Drag or Upload your documents here
            </div>
            <input
              className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
              type="file"
              accept="application/pdf, image/*, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              multiple="multiple"
              value=""
              onChange={(e) => { addedBillingToInput(e) }}
            />
            <div className="text-center">{`jpg, png, pdf, word`}</div>
          </div>
        </div>

        {
          uploadedAll?.length > 0 &&
          <div className="font-semibold text-lg mt-10">
            Uploaded Documents
          </div>
        }
        <div className="docs-container flex gap-6 justify-start items-center my-3">
          {
            uploadedAll?.map((item, index) => {
              if (item.docType === 'image') {
                return <FileIcons.ImageFile
                  key={index}
                  filename={item.file?.name || 'Image File'}
                />
              } else if (item.docType === 'pdf') {
                return <FileIcons.PdfFile
                  key={index}
                  filename={item.file?.name || 'PDF File'}
                />
              } else {
                return <FileIcons.DocFile
                  key={index}
                  filename={item.file?.name || 'Doc File'}
                />
              }
            })
          }
        </div>

        {Array.isArray(error) && error.length > 0 &&
          error?.map((item, index) => {
            return <div className="text-red-500 flex flex-col my-auto text-sm12">
              {item}
            </div>
          })
        }

        <div className="w-full flex justify-end">
          <button
            onClick={() => { handleContinue() }}
            className="  items-center mt-10  bg-[#1c3775] text-white px-20 py-2 rounded-full font-semibold text-lg"
          >
            Continue
          </button>
        </div>
        {loading && <LoadingIndicator styling="w-full flex justify-end px-16" />}
      </div>
    </div>
  )
}

export default UploadAdditionalDocument