import { CiCircleCheck } from "react-icons/ci";

const SuccessIndicator = ({ message, styling = "", max = null }) => {
  return (
    <div className={`flex ml-auto gap-2 pr-px items-center text-base font-medium tracking-tight leading-5 text-green-700 max-md:flex-wrap max-md:pl-5 ${styling}`}>
      <CiCircleCheck className="shrink-0 my-auto w-4 aspect-square" />
      <div>{(max !== null && message.length >= max) ? message.slice(0, max) + '...' : message}</div>
    </div>
  )
}

export default SuccessIndicator;