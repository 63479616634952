import { useState, useEffect } from "react";

const ImageCarousel = ({ images }) => {

  const [displayImageIndex, setDisplayImageIndex] = useState(0);

  useEffect(() => {

  }, []);

  const nextButton = () => {
    if (displayImageIndex < images?.length - 1) {
      setDisplayImageIndex(displayImageIndex + 1);
    }
  }

  const prevButton = () => {
    if (displayImageIndex !== 0) {
      setDisplayImageIndex(displayImageIndex - 1);
    }
  }


  return (
    <div id="default-carousel" class="relative w-full" data-carousel="slide">
      <div class="relative h-56 overflow-hidden rounded-lg md:h-96">
        {
          images.map((item, index) => {
            return (
              <div
                key={item.id || index}
                className={`duration-700 ease-in-out ${index !== displayImageIndex ? 'hidden' : ''}`}
              >
                <img
                  src={item.image_path}
                  className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                  alt={index}
                />
              </div>
            );
          })
        }

      </div>
      <button onClick={prevButton}
        type="button" class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full group-hover:bg-white/50 dark:group-hover:bg-gray-800/10 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
          <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
          </svg>
          <span class="sr-only">Previous</span>
        </span>
      </button>
      <button onClick={nextButton}
        type="button" class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full group-hover:bg-white/50 dark:group-hover:bg-gray-800/10 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
          <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
          </svg>
          <span class="sr-only">Next</span>
        </span>
      </button>
    </div>
  );
}

export default ImageCarousel