import React, { useEffect, useRef, useState } from "react";
import BacktoLogin from "./BacktoLogin";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../feature/authSlice";
import { setVerify2FAError, verify2FA } from "../../feature/dashboard/professionalProfile/google2fa/google2faSlice";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";

const Verify2FA = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const otpRefs = useRef([]);
  const [isFading, setIsFading] = useState(false);
  const [recoveryCode, setRecoveryCode] = useState("");
  const [isRecoveryCode, setIsRecoveryCode] = useState(false);

  const verify2FAStates = useSelector(
    (state) => state.google2fa.verify2FA
  ) || { success: null, error: null, loading: false };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setError(null));
  },[dispatch]);

  useEffect(() => {
    if (verify2FAStates.error) {
      if(isRecoveryCode)
        dispatch(setVerify2FAError('Invalid Recovery Code!'));
      
      setIsFading(true);
      const timer = setTimeout(() => {
        setIsFading(false);
        dispatch(setVerify2FAError(null));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [verify2FAStates.error]);

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];

    if (/^[0-9]$/.test(value)) {
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < 5 && value) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (newOtp[index] === "") {
        if (index > 0) otpRefs.current[index - 1].focus();
      } else {
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  const handleOtpPaste = (event) => {
    const pasteData = event.clipboardData
      .getData("text")
      .slice(0, 6)
      .split("")
      .filter((char) => /^[0-9]$/.test(char));
    const newOtp = [...otp];

    pasteData.forEach((char, index) => {
      newOtp[index] = char;
      if (otpRefs.current[index]) otpRefs.current[index].value = char;
    });

    setOtp(newOtp);
    const nextIndex = pasteData.length < 6 ? pasteData.length : 5;
    otpRefs.current[nextIndex].focus();
  };

  const renderOtpInputFields = () => (
    <div className="flex flex-row max-w-full gap-4 pr-3 mt-4">
      {[...Array(6)].map((_, i) => (
        <input
          key={i}
          type="text"
          maxLength="1"
          className="flex-grow border border-solid border-neutral-400 h-[58px] min-w-5 text-center rounded-md"
          value={otp[i]}
          onChange={(e) => handleOtpChange(e, i)}
          onKeyDown={(e) => handleOtpKeyDown(e, i)}
          onPaste={handleOtpPaste}
          ref={(el) => (otpRefs.current[i] = el)}
        />
      ))}
    </div>
  );

  const handleVerifyClick = () => {
    const _code = isRecoveryCode ? recoveryCode : otp.join("");

    if (_code) {
      const uData = JSON.parse(localStorage.getItem('user'));
      dispatch(verify2FA({ otp: _code, email: uData?.email || '' })).then(({ payload }) => {        
        if (payload && payload.message === "OTP is valid!") {
          localStorage.setItem('OTPVerified',1);
        
          navigate("/dashboard/messages");
        }
      });
      return;
    }

    dispatch(setError("Verification Code Required!"));
  };

  return (
    <div className="absolute flex flex-col items-center justify-center min-w-full min-h-screen py-12 bg-sky-600">
      <BacktoLogin />
      <div className="flex flex-col p-3 mx-3 md:mx-0 md:p-12 my-3 max-w-[420px] w-full bg-white rounded-3xl shadow-sm">
        <img
          alt="img"
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0515c2622ed9199edf94bde5a446c72d0f096dd64c6ce9f50af8b84b945c06d4?apiKey=3ae96302e69649f5be368f89230b0596&"
          className="self-center w-full aspect-[4.17]"
        />

        <div className="mt-10 text-xl font-semibold text-neutral-800 max-sm:mr-auto text-start">
          {isRecoveryCode ? "Recovery Code" : "Two Step Verification"}
        </div>

        <div className="mt-4 text-base text-zinc-700 text-opacity-80">
          {isRecoveryCode ? "Type in your recovery code below." : "Open your two-factor authenticator app."}
        </div>

        {isRecoveryCode ? (
          <input
            type="text"
            className="w-full p-3 mt-4 border border-solid rounded-md border-neutral-400"
            value={recoveryCode}
            onChange={(e) => setRecoveryCode(e.target.value)}
          />
        ) : (
          renderOtpInputFields()
        )}

        <button
          onClick={handleVerifyClick}
          disabled={verify2FAStates.loading}
          className="items-center justify-center py-3 mt-4 text-base font-semibold text-center text-white bg-blue-900 cursor-pointer px-9 whitespace-nowrap rounded-3xl max-md:px-5"
        >
          Verify
        </button>
        {verify2FAStates.error && isFading && <div className="my-3 text-center text-red-600">{verify2FAStates.error}</div>}

        <div className="mt-4 text-base text-center text-zinc-900">
          {isRecoveryCode ? (
            "If you cannot find your codes, please contact Admin."
          ) : (
            <button onClick={() => setIsRecoveryCode(true)} className="underline cursor-pointer text-sky-600">
              Use Recovery Code instead
            </button>
          )}
        </div>
      </div>
      <Loader loadingText={"Verifying..."} isOpen={verify2FAStates.loading} />
    </div>
  );
};

export default Verify2FA;
