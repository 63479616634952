import FormattedDateComponent from "../../FormattedDateComponent";

const EducationCertItem = ({ data, onEdit, onDelete, showControls = true }) => {
  return (
    <>
      <div className="flex gap-4 mt-8 max-md:flex-wrap">
        <img
          alt="img"
          loading="lazy"
          src={data.thumbnail_path}
          className="shrink-0 self-start aspect-square w-[77px]"
        />
        <div className="flex flex-col flex-1 max-md:max-w-full">
          <div className="font-bold max-md:max-w-full">
            {data.name}
          </div>
          <div className="mt-1 max-md:max-w-full">
            {data.degree_or_course}
          </div>
          <div className="mt-1 max-md:max-w-full"><FormattedDateComponent datetime={data.date_started} /> - <FormattedDateComponent datetime={data.date_graduated} /></div>
          {showControls &&
            <div className="self-end mt-1 text-lg font-medium text-blue-900">
              <div><button onClick={() => onEdit(data.id)}>Edit</button> | <button onClick={() => onDelete(data.id)}>Delete</button></div>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default EducationCertItem