import { useState } from "react";
import LoadingIndicator from "../../components/LoadingIndicator";

const VerifyAccessKey = ({ error, loading, submitAccessKey, accessKey, setAccessKey }) => {

  return (
    <div className="flex flex-col items-center pt-6 bg-white text-neutral-600">
      <div className="mt-28 text-base font-medium tracking-tight leading-5 text-center text-blue-900 max-md:mt-10">
        ACCESS KEY
      </div>
      <div className="mt-4 text-base tracking-tight leading-5 text-center w-[335px]">
        Please enter your access key to upload additional document
        requirement
      </div>
      <input
        className="justify-center items-start px-4 py-3.5 mt-4 max-w-full text-base leading-5 rounded-xl border border-solid border-neutral-400 placeholder-text-stone-300 w-[335px] max-md:px-5"
        placeholder="Access Key"
        type="text"
        value={accessKey}
        onChange={(e) => { setAccessKey(e.target.value.trim()) }}
      />

      {Array.isArray(error) && error.length > 0 &&
        error?.map((item, index) => {
          return <div className="flex w-[335px] mt-2">
            <div className="text-red-500 text-sm">
              {item}
            </div>
          </div>
        })
      }

      <div className="flex gap-4 justify-center px-8 mt-4 max-w-full text-base font-semibold text-white whitespace-nowrap w-[335px] max-md:px-5">
        <div className="cursor-pointer justify-center px-6 py-4 bg-sky-600 rounded-2xl max-md:px-5">
          Cancel
        </div>
        <div
          onClick={() => { submitAccessKey() }}
          className="cursor-pointer justify-center px-6 py-4 bg-blue-900 rounded-2xl max-md:px-5"
        >
          Apply
        </div>
      </div>
      {loading && <LoadingIndicator loading={loading} styling="flex gap-4 justify-center mt-2 max-w-full whitespace-nowrap w-[335px]" />}
    </div>
  )
}

export default VerifyAccessKey