import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendFeedback, setFeedbackSuccess } from "../../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";

function Help() {
  const [messageType, setMessageType] = useState("Compliment");
  const [message, setMessage] = useState("");
  const [isFading, setIsFading] = useState(false);

  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  const feedbackStates = useSelector((state) => state.settings.sendFeedback) || { success: null, error: null, loading: false };

  useEffect(() => {
    if (feedbackStates?.success?.includes("Message Sent")) {
      setIsFading(true);
      const successTimer = setTimeout(() => {
        setIsFading(false);
        
        dispatch(setFeedbackSuccess(null));

        // Reset form
        setMessageType("Compliment");
        setMessage("");
      }, 5000);
      return () => clearTimeout(successTimer);
    }
  }, [feedbackStates.success,dispatch]);

  const handleCancel = async () => {
    setMessageType("Compliment");
    setMessage("");

    await dispatch(setFeedbackSuccess(null));
  };

  const handleSend = async () => {
    if (message.trim() === '') return;

    const data = {
      serial_number: userData.serial_number,
      feedback_type: messageType,
      message: message,
    };

    await dispatch(sendFeedback({ id: userData.id, data: { ...data } }));
  };

  return (
    <div className="min-h-[89vh]">
      <div className="w-full">
        <div className="self-start tracking-tight max-md:max-w-full">
          Contact Admin
        </div>
        <div className="max-w-[500px] m-auto">
          <div className="flex flex-col items-center px-8 text-base leading-4 text-neutral-600 max-md:px-5 max-md:mt-8 max-md:max-w-full">
            <div className="self-start mt-6 tracking-tight">
              Type of Message
            </div>
            <div className="flex gap-5 justify-between px-2 mt-4 max-w-full whitespace-nowrap leading-[121%] w-[407px] max-md:pr-5">
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  id="compliment"
                  name="messageType"
                  value="Compliment"
                  checked={messageType === "Compliment"}
                  onChange={(e) => setMessageType(e.target.value)}
                  className="w-4 h-4 bg-teal-400 rounded-full shrink-0"
                />
                <label htmlFor="compliment">Compliment</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  id="feedback"
                  name="messageType"
                  value="Feedback"
                  checked={messageType === "Feedback"}
                  onChange={(e) => setMessageType(e.target.value)}
                  className="w-4 h-4 rounded-full shrink-0 bg-zinc-300"
                />
                <label htmlFor="feedback">Feedback</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  id="complaint"
                  name="messageType"
                  value="Complaint"
                  checked={messageType === "Complaint"}
                  onChange={(e) => setMessageType(e.target.value)}
                  className="w-4 h-4 rounded-full shrink-0 bg-zinc-300"
                />
                <label htmlFor="complaint">Complaint</label>
              </div>
            </div>
            <div className="self-start mt-6 text-base tracking-tight ">
              Message
            </div>
            <textarea
              className="px-4 py-2 shrink-0 mt-2 max-w-full rounded-2xl border border-solid border-neutral-300 h-[180px] w-[407px]"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="flex gap-4 justify-center mt-4 max-w-full font-semibold text-white w-[407px]">
              <button
                className="justify-center px-6 py-4 cursor-pointer whitespace-nowrap bg-sky-600 rounded-2xl max-md:px-5"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className={`items-center justify-center flex-1 px-6 py-4 text-center bg-blue-900 cursor-pointer rounded-2xl max-md:px-5 ${
                  message.trim() === '' || feedbackStates.loading
                    ? "opacity-50 pointer-events-none"
                    : ""
                }`}
                disabled={message.trim() === '' || feedbackStates.loading}
                onClick={handleSend}
              >
                Send to Admin
              </button>
            </div>
            <div className="justify-center mt-4 max-w-full w-[407px]">
              {feedbackStates.loading && (
                <div className="text-blue-600">Processing...</div>
              )}
              {feedbackStates.error && (
                <div className="text-red-600">{feedbackStates.error}</div>
              )}
              {feedbackStates.success && isFading && (
                <div className="text-green-600">{feedbackStates.success}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
