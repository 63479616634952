import React, { useState, useEffect } from "react";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";
import useSocialLinks from "../../../feature/dashboard/professionalProfile/profileEdit/socialLinks/useSocialLinks";
import { SocialsList } from "../../../feature/dashboard/professionalProfile/profileEdit/socialLinks/useSocialLinks";

import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa6";


const SocialLinks = () => {

  const {
    success, error, loading,
    getSocialsList, setSocialsList,
    handleAddButton,
    validationErrorMessage, showNotif, isSafeToSubmit,
    validateInstagram,
    validateFacebook,
    validateYoutube,
    validateLinkedIn,
    validateDiscord,
    validateTiktok,
  } = useSocialLinks();


  return (
    <>
      <div className="flex flex-col max-md:mt-8 max-md:max-w-full">
        <div className="flex justify-between h-4 px-5 text-base tracking-tight max-md:flex-wrap">
          <div className="leading-[110%] text-neutral-600">
            Social Links
          </div>
          <div className="flex  gap-2 pr-px pl-20 font-medium text-green-700 leading-[120%] max-md:flex-wrap max-md:pl-5">
            {
              showNotif && (
                <>
                  {!loading && success && <SuccessIndicator message="Account updated." />}
                  {!loading && !success && error && <FailureIndicator message={error || "Account not updated."} />}
                </>
              )
            }
          </div>
        </div>
        <div className="mt-4 max-md:max-w-full">

          <div className="flex flex-col ml-5 w-[84%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow justify-center leading-[121%] text-stone-300 max-md:mt-4 max-md:max-w-full">
              <div className="flex flex-row item-center space-x-2 mx-2 mt-4"><FaInstagram /> <InputTitle title='Instagram' /></div>
              <input
                placeholder="Type your Instagram link here"
                autoComplete="url"
                className="justify-center my-2 p-4 text-base text-neutral-600 rounded-2xl border border-solid border-neutral-400 max-md:max-w-full"
                value={getSocialsList(SocialsList?.instagram)?.link}
                onChange={(e) => {
                  setSocialsList(SocialsList?.instagram, e.target.value);
                  validateInstagram(e.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.instagram?.errorMessage} />

              <div className="flex flex-row mx-2 mt-4"><FaFacebookF /> <InputTitle title='Facebook' /></div>
              <input
                placeholder="Type your Facebook link here"
                autoComplete="url"
                className="justify-center my-2 p-4 text-base text-neutral-600 rounded-2xl border border-solid border-neutral-400 max-md:max-w-full"
                value={getSocialsList(SocialsList?.facebook)?.link}
                onChange={(e) => {
                  setSocialsList(SocialsList?.facebook, e.target.value);
                  validateFacebook(e.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.facebook?.errorMessage} />

              <div className="flex flex-row mx-2 mt-4"><FaLinkedinIn /> <InputTitle title='LinkedIn' /></div>
              <input
                placeholder="Type your LinkedIn link here"
                autoComplete="url"
                className="justify-center my-2 p-4 text-base text-neutral-600 rounded-2xl border border-solid border-neutral-400 max-md:max-w-full"
                value={getSocialsList(SocialsList?.linkedIn)?.link}
                onChange={(e) => {
                  setSocialsList(SocialsList?.linkedIn, e.target.value);
                  validateLinkedIn(e.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.linkedIn?.errorMessage} />

              <div className="flex flex-row mx-2 mt-4"><FaYoutube /> <InputTitle title='Youtube' /></div>
              <input
                placeholder="Type your Youtube link here"
                autoComplete="url"
                className="justify-center my-2 p-4 text-base text-neutral-600 rounded-2xl border border-solid border-neutral-400 max-md:max-w-full"
                value={getSocialsList(SocialsList?.youtube)?.link}
                onChange={(e) => {
                  setSocialsList(SocialsList?.youtube, e.target.value);
                  validateYoutube(e.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.youtube?.errorMessage} />

              <div className="flex flex-row mx-2 mt-4"><FaDiscord /> <InputTitle title='Discord' /></div>
              <input
                placeholder="Type your Discord link here"
                autoComplete="url"
                className="justify-center my-2 p-4 text-base text-neutral-600 rounded-2xl border border-solid border-neutral-400 max-md:max-w-full"
                value={getSocialsList(SocialsList?.discord)?.link}
                onChange={(e) => {
                  setSocialsList(SocialsList?.discord, e.target.value);
                  validateDiscord(e.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.discord?.errorMessage} />

              <div className="flex flex-row mx-2 mt-4"><FaTiktok /> <InputTitle title='Tiktok' /></div>
              <input
                placeholder="Type your TikTok link here"
                autoComplete="url"
                className="justify-center my-2 p-4 text-base text-neutral-600 rounded-2xl border border-solid border-neutral-400 max-md:max-w-full"
                value={getSocialsList(SocialsList?.tiktok)?.link}
                onChange={(e) => {
                  setSocialsList(SocialsList?.tiktok, e.target.value);
                  validateTiktok(e.target.value);
                }}
              />
              <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.tiktok?.errorMessage} />

            </div>
          </div>
        </div>

        <div className={` flex flex-col justify-center items-end px-16 py-6 mt-4 text-base font-semibold text-white max-md:pl-5 max-md:max-w-full`}>
          <button onClick={() => handleAddButton()}
            className={`${(loading === false && isSafeToSubmit === true) ? '' : 'disabled'} justify-center whitespace-nowrap px-6 py-3 bg-devRegDarkBlue rounded-2xl max-md:px-5 max-md:ml-2`}>
            Submit
          </button>
          {loading &&
            <LoadingIndicator loading={loading} styling="max-md:px-5 max-md:ml-2" />
          }
        </div>
      </div>
    </>
  );
}

const InputTitle = ({ title }) => {
  return (
    <div className="mx-2 text-base item-center tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
      <div>{title || ''}</div>
    </div>
  );
}

export default SocialLinks;
