import React, { useEffect, useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { FaLock } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa";
import useStripePayment from "../../api/customHook/useStripePayment";
import { useSelector } from "react-redux";
import { FormattedNumber } from "react-intl";
import Loader from "../../components/Loader";

const StripePayment = () => {
  const [showCardElement, setShowCardElement] = useState(false);

  const auth = useSelector((state) => state.auth);
  const [isFading, setIsFading] = useState(false);

  const {
    handleSubmit,
    errorMessage,
    coupon,
    couponValue,
    setCoupon,
    handleValidateCoupon,
    handleMembershipValues,
    membershipCountryValues,
    ismodalOpen,
  } = useStripePayment();

  const handleAddPaymentMethod = () => {
    setShowCardElement(true);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        fontWeight: 400,
        fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
        fontSize: "16px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
      invalid: {
        iconColor: "#E25950",
        color: "#E25950",
      },
    },
  };

  useEffect(() => {
    if (membershipCountryValues.updated === false) {
      handleMembershipValues();
    }
  });

  useEffect(() => {
    if(auth.coupon.error){
      setIsFading(true);

      var tm = setTimeout(() => {
        setIsFading(false);
        setCoupon("");
      }, 3000);

      return () => {
        clearTimeout(tm);
      };
    }
  }, [auth.coupon.error]);

  return (
    <>
      <div className="flex flex-col mx-auto mt-8 mb-12 max-w-[900px] max-sm:px-10">
        <div className="container mx-auto flex flex-wrap px-10 lg:flex-nowrap bg-[#f5f5f5] rounded-2xl justify-center ">
          <div className="px-3 pr-10 section-1 pt-11 min-w-[22rem] w-[43rem]">
            <div className="font-bold text-[#1c3775] text-2xl whitespace-nowrap">
              Payment Method
            </div>

            <button
              className="font-semibold text-neutral-600"
              onClick={handleAddPaymentMethod}
            >
              + Add Payment Method
            </button>
            {showCardElement && (
              <>
                {/* <div className="border-black border-2 w-[100px] my-5 text-center py-1 font-bold text-2xl text-[#635bff]">
                  Stripe
                </div> */}

                <CardElement
                  options={CARD_ELEMENT_OPTIONS}
                  className="StripeElement"
                />
                <div id="card-errors" role="alert">
                  {errorMessage && <span>{errorMessage}</span>}
                </div>
              </>
            )}
          </div>
          <div className="min-w-[22rem] px-0 py-6 section-2">
            <div className="px-10 py-5 bg-white rounded-3xl">
              <div className="flex justify-between">
                <div className="font-bold text-[#1c3775] text-2xl">
                  Order Summary
                </div>
                <div className="flex items-center justify-center gap-2 text-neutral-400">
                  <span>
                    <FaCoins />
                  </span>
                  {membershipCountryValues.currencyName}
                </div>
              </div>

              <div className="flex justify-between mt-8">
                <div>
                  <div className="font-semibold text-neutral-500">
                    Membership Fee
                  </div>
                  <div className="text-neutral-400">{`${membershipCountryValues.currencySymbol} ${membershipCountryValues.membershipRenewalFee} next year (01/15/2025)`}</div>
                </div>
                <div className="font-bold">
                  {membershipCountryValues.membershipFee}
                </div>
              </div>

              <div className="mt-8 font-semibold text-neutral-500">
                Coupon Code
              </div>
              <div className="flex flex-col items-center w-full mt-2 gap-y-1 lg:flex-row lg:justify-start lg:items-center">
                <input
                  type="text"
                  className="flex-1 px-2 border-2 rounded-lg border-neutral-400 placeholder-text-neutral-200 min-h-[2.5rem] w-full lg:w-auto"
                  placeholder="Coupon Code"
                  value={coupon}
                  onChange={(e) => {
                    setCoupon(e.target.value);
                  }}
                />
                <button
                  onClick={handleValidateCoupon}
                  disabled={auth.loading || !membershipCountryValues.updated}
                  className={`lg:ml-2 w-full rounded-full lg:rounded-lg lg:w-auto font-bold text-white bg-[#1c3775] px-7 py-2 ${
                    !membershipCountryValues.updated ? "disabled" : ""
                  }`}
                >
                  Apply
                </button>
              </div>
              {auth.coupon.loading && (
                <p className="mt-1 text-blue-900">Validating coupon...</p>
              )}
              {auth.coupon.error && isFading && (
                <p className="mt-1 text-red-900">{auth.coupon.error}</p>
              )}

              <div className="flex justify-between mt-4">
                <div className="font-semibold text-neutral-500 ">{`Coupon Discount (FREESTAFF)`}</div>
                <div className="text-neutral-500">
                  -{membershipCountryValues.currencySymbol}
                  {couponValue}
                </div>
              </div>
              <div className="flex justify-between mt-4 text-xl font-extrabold">
                <div className="">Total Due</div>
                <div>
                  <FormattedNumber
                    value={(
                      membershipCountryValues.membershipFeeForComputation -
                      couponValue
                    ).toFixed(2)}
                    style="currency"
                    currency={membershipCountryValues.currencyName}
                  />
                </div>
              </div>
              <button
                onClick={handleSubmit}
                disabled={auth.loading || !membershipCountryValues.updated}
                className={`w-full mt-3 bg-[#1c3775] text-white px-7 py-2 rounded-full font-semibold text-lg 
                  ${!membershipCountryValues.updated ? "disabled" : ""}`}
              >
                Pay now
              </button>
              {auth.loading && (
                <p className="mt-1 text-blue-900">Processing...</p>
              )}
              {auth.error && <p className="mt-1 text-red-900">{auth.error}</p>}

              <div className="flex justify-between mt-4">
                <FaLock className="text-neutral-400 text-[40px]" />
                <div className="flex-1 ml-4 text-neutral-400 font-[500]">
                  <span className="font-extrabold text-neutral-500">
                    Secure Checkout.{"  "}
                  </span>
                  For your convenience, we will save your encrypted payment
                  information for future payments.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loadingText={"Processing..."} isOpen={ismodalOpen} />
    </>
  );
};

export default StripePayment;
