import { FaFile } from "react-icons/fa6";
import { FaImage } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6"; //
import { FaX } from "react-icons/fa6";

const ImageFile = ({ filename, requestStatus = null }) => {
  return (
    <>
      <div className="relative max-w-[60px]">
        <div className="text-[60px] text-green-500">
          <FaFile />
        </div>
        <div className="text-[20px] text-white  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <FaImage />
        </div>
        <div className="flex justify-center items-center text-[12px] h-full">
          {filename.substring(0, 5)}
        </div>
        <div className="absolute flex justify-center items-center w-full">
          {requestStatus && requestStatus !== null && <FaCheck size={30} className="text-green-500" />}
          {!requestStatus && requestStatus !== null && <FaX size={30} className="text-red-500" />}
        </div>
      </div>

    </>
  )
}

const DocFile = ({ filename, requestStatus = null }) => {
  return (
    <>
      <div className="relative max-w-[60px]">
        <div className="text-[60px] text-blue-500">
          <FaFile />
        </div>
        <div className="text-[13px] font-semibold text-white  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          DOC
        </div>
        <div className="flex justify-center items-center text-[12px] h-full">
          {filename.substring(0, 5)}
        </div>
        <div className="absolute flex justify-center items-center w-full">
          {requestStatus && requestStatus !== null && <FaCheck size={30} className="text-green-500" />}
          {!requestStatus && requestStatus !== null && <FaX size={30} className="text-red-500" />}
        </div>
      </div>
    </>
  )
}

const PdfFile = ({ filename, requestStatus = null }) => {
  return (
    <>
      <div className="relative max-w-[60px]">
        <div className="text-[60px] text-red-500">
          <FaFile />
        </div>
        <div className="text-[13px] font-semibold text-white  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          PDF
        </div>
        <div className="flex justify-center items-center text-[12px] h-full">
          {filename.substring(0, 5)}
        </div>
        <div className="absolute flex justify-center items-center w-full">
          {requestStatus && requestStatus !== null && <FaCheck size={30} className="text-green-500" />}
          {!requestStatus && requestStatus !== null && <FaX size={30} className="text-red-500" />}
        </div>
      </div>
    </>
  )
}

const FileIcons = {
  ImageFile,
  DocFile,
  PdfFile,
}

export default FileIcons