import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Debounce from '../../../../../utils/Debounce';
import { DebounceDelay } from '../../../../../utils/Debounce';
import ValidationHelperChain from '../../../../../utils/ValidationHelperChain';
import { portfolioAction as action } from './portfolioSlice';

const usePortfolioValidation = () => {
  const dispatch = useDispatch();

  const itemDefaultValue = { isValid: false, errorMessage: [] };
  const validationErrorMessageDefault = {
    title: itemDefaultValue,
    description: itemDefaultValue,
    message: itemDefaultValue,
    thumbnail: itemDefaultValue,
    is_featured: itemDefaultValue,
  };
  const [validationErrorMessage, setValidationErrorMessage] = useState(validationErrorMessageDefault);

  const strValidationErrorMessage = JSON.stringify(validationErrorMessage);
  const [isSafeToSubmit, setIsSafeToSubmit] = useState(false);

  const { success, error, loading, } = useSelector(state => state.portfolios);
  const strNotifiers = JSON.stringify(`${success} ${error} ${loading}`);

  const [showNotif, setShowNotif] = useState(true);
  useEffect(() => {
    setIsSafeToSubmit(hasValidationError() === true || validationErrorMessage === validationErrorMessageDefault ? false : true);
    if (showNotif === true) {
      setTimeout(() => {
        setShowNotif(false);
        // setErrorsToEmpty();
        dispatch(action.setError(null));
        dispatch(action.setSuccess(false));
      }, 15000);
    } else {
      setShowNotif(true);
    }
  }, [strValidationErrorMessage, strNotifiers]);

  const hasValidationError = () => {
    return Object.values(validationErrorMessage).some(
      (item) => item.errorMessage?.length > 0
    );
  };

  const setErrorsToEmpty = () => {
    setValidationErrorMessage(validationErrorMessageDefault);
  }

  const validateTitle = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 3 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, title: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, title: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateDescription = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 10 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, description: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, description: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateMessage = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 10 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, message: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, message: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateThumbnail = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 10 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, thumbnail: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, thumbnail: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);

  const validateIs_featured = Debounce((str) => {
    const validation = ValidationHelperChain().stringCountMin({ str: str, intCount: 10 }).notEmpty({ str: str, });
    const hasError = validation.hasError();
    const errorMessage = validation.getErrorMessages();

    if (hasError === true) { setIsSafeToSubmit(false); setValidationErrorMessage(prevState => ({ ...prevState, is_featured: { isValid: false, errorMessage: errorMessage[0] } })); }
    if (hasError === false) { setIsSafeToSubmit(true); setValidationErrorMessage(prevState => ({ ...prevState, is_featured: { isValid: true, errorMessage: null } })); }
  }, DebounceDelay);


  return {
    validationErrorMessage, showNotif, isSafeToSubmit,
    validateTitle, validateMessage, validateDescription,
  };
}

export default usePortfolioValidation;


// const { validationErrorMessage, showNotif, isSafeToSubmit, validateTitle, validateMessage, validateDescription, } = usePortfolioValidation();
{ /* 
  <input
    value={postMessage.message}
    onChange={(e) => {
      setPostMessage({ ...postMessage, message: e.target.value });
      validateMessage(e.target.value);
    }}
  />

  <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.message?.errorMessage} />

  <button className={`${isSafeToSubmit === true ? '' : 'disabled'}`} onClick={onClickSend}>
    Send
  </button>

  <button className={`${(loading === false) ? '' : 'disabled' }`}
    onClick={() => {
      validateMessage(postMessage.message);
      if (validationErrorMessage.message.isValid) onClickSend();
    }}
  >
*/}