import React, { useEffect, useState } from "react";
import { RiDashboardLine } from "react-icons/ri";
import { BiMessageSquare } from "react-icons/bi";
import { IoPersonOutline } from "react-icons/io5";
import { TbMessage } from "react-icons/tb";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import earningsIcon from "../../assets/Profile/earningsIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../feature/authSlice";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import Loader from "../Loader";

function SidebarDashboard() {
  const navigate = useNavigate();

  // const { logout } = useAuth();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleLogout = async () => {
    // logout();
    await dispatch(logout());

    navigate("/");
    window.scrollTo(0, 0);
  };
  const [isActive, setIsActive] = useState("");
  const [showSecondNav, setShowSecondNav] = useState(false);
  const listTab = [
    {
      title: "Overview",
      link: "?tab=overview",
      isActive: "overview",
    },
    {
      title: "Advertisement/Features",
      link: "?tab=advertisement-features",
      isActive: "advertisement-features",
    },
    {
      title: "Language",
      link: "?tab=language",

      isActive: "language",
    },
    {
      title: "Work History",
      link: "?tab=work-history",
      isActive: "work-history",
    },
    {
      title: "Portfolio",
      link: "?tab=portfolio",
      isActive: "portfolio",
    },
    {
      title: "Skills and Expertise",
      link: "?tab=skills-and-expertise",
      isActive: "skills-and-expertise",
    },
    {
      title: "Education / Certificate",
      link: "?tab=education-certificate",
      isActive: "education-certificate",
    },
    {
      title: "Honor and Awards",
      link: "?tab=honor-and-awards",
      isActive: "honor-and-awards",
    },
    {
      title: "Social Links",
      link: "?tab=social-links",
      isActive: "overview",
    },
    {
      title: "Preview Profile",
      link: "/dashboard/profile",
      isActive: "preview-profile",
    },
  ];

  const [activeTab, setActiveTab] = React.useState("");
  const [overview, setOverView] = React.useState("overview");
  const [markTheNav, setMarkTheNav] = React.useState(0);
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get("tab");
    if (location.pathname === "/dashboard/earnings-and-referrals") {
      setMarkTheNav(0);
      setActiveTab("earnings");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/messages") {
      if (tabParam === "admin") {
        setMarkTheNav(3);
        setActiveTab("messages");
      }
      if (tabParam === "theregister") {
        setMarkTheNav(2);
        setActiveTab("messages");
      }
      if (tabParam === "fullcheckid") {
        setMarkTheNav(1);
        setActiveTab("messages");
      }
      setActiveTab("messages");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/account-settings/help") {
      setMarkTheNav(6);
      setActiveTab("messages");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/news") {
      setMarkTheNav(0);
      setActiveTab("news");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/forum") {
      setMarkTheNav(0);
      setActiveTab("forum");
      setShowSecondNav(false);
    } else if (location.pathname === "/dashboard/edit-profile") {
      setMarkTheNav(4);
      setShowSecondNav(true);
      setActiveTab("editProfile");
    } else if (location.pathname === "/dashboard/certificate") {
      setMarkTheNav(5);
      setShowSecondNav(false);

      setActiveTab("editProfile");
    } else if (location.pathname === "/dashboard/account-settings") {
      setMarkTheNav(6);
      setShowSecondNav(false);

      setActiveTab("accountSettings");
    } else if (location.pathname === "/dashboard/help") {
      setMarkTheNav(7);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-number"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-password"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else if (
      location.pathname === "/dashboard/account-settings/change-email"
    ) {
      setMarkTheNav(6);
      setShowSecondNav(false);
      setActiveTab("accountSettings");
    } else {
      setShowSecondNav(false);
      setMarkTheNav(0);
      setActiveTab("");
    }

    // Edit Profile

    if (tabParam === "overview") {
      setOverView("overview");
    }
    if (tabParam === "advertisement-features") {
      setOverView("advertisement-features");
    }
    if (tabParam === "language") {
      setOverView("language");
    }
    if (tabParam === "work-history") {
      setOverView("work-history");
    }
    if (tabParam === "portfolio") {
      setOverView("portfolio");
    }
    if (tabParam === "skills-and-expertise") {
      setOverView("skills-and-expertise");
    }
    if (tabParam === "education-certificate") {
      setOverView("education-certificate");
    }
    if (tabParam === "honor-and-awards") {
      setOverView("honor-and-awards");
    }
    if (tabParam === "social-links") {
      setOverView("social-links");
    }
    if (tabParam === "preview-profile") {
      setOverView("preview-profile");
    }
  }, [location]);


  const { shouldRedirect, redirectTo } = useInspectRoute(location.pathname);

  if (shouldRedirect) {
    return <Navigate to={redirectTo} />
  } else {
    return (
      <>
        <div
          className={`pb-14  min-h-[87vh] ${showSecondNav ? "min-w-[230px]" : "min-w-[310px]"
            } bg-[#F2F7FF] h-auto   py-2 max-[900px]:hidden  `}
        >
          <div className="flex items-center gap-1 px-5 py-3 mb-2 title">
            <RiDashboardLine size={30} className="text-devRegDarkBlue" />
            <div className="font-bold text-[25px] text-devRegDarkBlue">
              Dashboard
            </div>
          </div>
          <div className="h-[0.8px] w-full bg-[#e4e7fb] "></div>
          {/* navs */}
          <div className="pl-1 dasboard-nav-cointainter ">
            {/* Messages */}
            <div
              className={`title flex items-center gap-1    ${activeTab === "messages" ? "font-bold" : "font-normal"
                } text-lg py-2 cursor-pointer px-5 `}
              onClick={() => {
                navigate("/dashboard/messages");
              }}
            >
              <BiMessageSquare color="#1C3775" />
              <div className="pl-1  text-[#7E82A3]">Messages</div>
            </div>
            <div className=" text-[#7E82A3]">
              <div
                className={`flex items-center justify-between px-14 py-3 cursor-pointer  ${markTheNav === 2 ? "font-extrabold" : "font-normal"
                  }`}
                onClick={() => {
                  navigate("/dashboard/messages?tab=theregister");
                }}
              >
                <div className="">The Register</div>
                <div className="">99</div>
              </div>
              <div className="h-[0.8px] w-full bg-[#e4e7fb] "></div>
              <div
                className={`flex items-center justify-between px-14 py-3 cursor-pointer  ${markTheNav === 3
                  ? "text-[#7E82A3] font-semibold"
                  : "text-[#7E82A3]"
                  }`}
                onClick={() => {
                  // Navigate to the messages route with the admin tab parameter
                  navigate("/dashboard/messages?tab=admin");
                }}
              >
                <div className="">Admin</div>
                <div className="">99</div>
              </div>
              <div className="h-[0.8px] w-full bg-[#e4e7fb] "></div>
              <div
                className={`flex items-center justify-between px-14 py-3 cursor-pointer  ${markTheNav === 1
                  ? "text-[#7E82A3] font-semibold"
                  : "text-[#7E82A3]"
                  }`}
                onClick={() => {
                  navigate("/dashboard/messages?tab=fullcheckid");
                }}
              >
                <div className="">FullIDCheck</div>
                <div className="">99</div>
              </div>
            </div>
            <div className="h-[0.8px] w-full bg-[#e4e7fb] "></div>

            {/* Earn and Refs */}
            <div
              className="title flex items-center gap-1  text-[#7E82A3] text-lg py-4  px-5 cursor-pointer"
              onClick={() => navigate("/dashboard/earnings-and-referrals")}
            >
              <img
                loading="lazy"
                src={earningsIcon}
                alt="earning logo"
                className={`w-4 h-4 text-[#7E82A3] `}
              />
              <div
                className={`pl-1 ${activeTab === "earnings" ? " font-extrabold" : "font-normal"
                  }  `}
              >
                Earnings and Referrals
              </div>
            </div>

            <div className="h-[0.8px] w-full bg-[#e4e7fb] "></div>

            {/* Professional Profile */}
            <div className="title flex items-center gap-1  text-[#7E82A3] text-lg py-3 px-5 cursor-pointer">
              <IoPersonOutline color="#1C3775" />

              <div
                className={`pl-1  ${activeTab === "editProfile" ? "font-extrabold" : "font-normal "
                  } `}
                onClick={() => navigate("/dashboard/edit-profile")}
              >
                Professional Profile
              </div>
            </div>
            <div className="text-[#7E82A3]">
              <div
                className={`flex items-center  px-14 py-3 ${markTheNav === 4 ? "font-extrabold" : "font-normal"
                  }`}
                onClick={() => navigate("/dashboard/edit-profile")}
              >
                <div className="cursor-pointer">Edit Profile</div>
              </div>
              <div className="h-[0.8px] w-full bg-[#e4e7fb] "></div>
              <div
                className={`flex items-center  py-3 px-14 ${markTheNav === 5
                  ? "text-[#7E82A3] font-semibold"
                  : "text-[#7E82A3]"
                  }`}
                onClick={() => navigate("/dashboard/certificate")}
              >
                <div className="cursor-pointer">Cerificate</div>
              </div>
            </div>
            <div className="h-[0.8px] w-full bg-[#e4e7fb] "></div>

            {/* Forums */}
            <div
              onClick={() => navigate("/dashboard/forum")}
              className="title flex items-center gap-1  text-[#7E82A3] text-lg py-4 cursor-pointer px-5 "
            >
              <TbMessage color="#1C3775" />

              <div
                className={`${activeTab === "forum" ? " font-extrabold" : "font-normal"
                  } pl-1 cursor-pointer`}
              >
                Forum
              </div>
            </div>

            <div className="h-[0.8px] w-full bg-[#e4e7fb] "></div>

            {/* Account Settings */}
            <div
              className={`title text-[#7E82A3] flex items-center gap-1 text-lg py-3 cursor-pointer px-5 
              ${activeTab === "accountSettings"
                  ? " font-extrabold"
                  : "font-normal"
                }`}
              onClick={() => navigate("/dashboard/account-settings")}
            >
              <IoPersonOutline color="#1C3775" />

              <div className="pl-1 cursor-pointer ">Account Settings</div>
            </div>
            <div className="text-[#7E82A3]">
              <div
                className={`flex items-center  px-14 py-3 ${markTheNav === 6 ? "font-extrabold" : "font-normal"
                  }`}
              >
                <div
                  className="cursor-pointer"
                  onClick={() => navigate("/dashboard/account-settings")}
                >
                  Security Setting
                </div>
              </div>
              <div className="h-[0.8px] w-full bg-[#e4e7fb] "></div>
              <div
                className={`flex items-center  py-3 px-14 ${markTheNav === 7 ? "font-extrabold" : "font-normal"
                  } `}
              >
                <div
                  className="cursor-pointer"
                  onClick={() => navigate("/dashboard/help")}
                >
                  Help
                </div>
              </div>
              <div className="h-[0.8px] w-full bg-[#e4e7fb] "></div>
              <div
                className={`flex items-center  py-3 px-14 ${markTheNav === 8
                  ? "text-[#7E82A3] font-semibold"
                  : "text-[#7E82A3]"
                  } `}
              >
                <div onClick={handleLogout} className="cursor-pointer">
                  Log Out
                </div>
              </div>
              {auth.error && (
                <div className="flex items-center py-3 text-red-900 px-14">
                  {auth.error}...
                </div>
              )}
            </div>
            <div className="h-[0.8px] w-full bg-[#e4e7fb] "></div>
          </div>
        </div>
        {showSecondNav && (
          <>
            <div className="min-w-[200px] bg-[#FAFAFA]  shadow-[rgba(0,0,15,0.1)_-10px_0px_20px_2px] max-[900px]:hidden">
              {listTab.map((item, index) => (
                <div
                  key={index}
                  className={`flex items-center justify-start cursor-pointer ${item.title === isActive
                    ? "bg-white font-extrabold"
                    : "bg-[#FAFAFA]"
                    } `}
                  onClick={() => {
                    navigate(item.link);
                    setIsActive(item.title);
                  }}
                >
                  <div
                    className={`h-12 w-[7px] ${overview === item.isActive ? "bg-[#0082BA]" : "bg-[#FAFAFA]"
                      } `}
                  ></div>
                  <div className="pl-4 text-sm text-neutral-700">
                    {item.title}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        
        <Loader loadingText={"Logging out..."} isOpen={auth.logout.loading} />
      </>
    );
  }
}

export default SidebarDashboard;
