import React, { useEffect } from "react";
import SidebarDesktop from "../../components/Dashboard/SidebarDesktop";
import ProfilePic from "../../assets/Profile/ProfilePic.jpg";
import useCertificates from "../../feature/dashboard/professionalProfile/certificates/useCertificates";
import { useSelector } from "react-redux";

function Certificate() {
  const { handlesDownloadButton, error, loading, userData } = useCertificates();

  return (
    <div className="flex flex-col justify-center items-center px-8 max-md:px-5 max-md:mt-6 max-md:max-w-full">
      <div className="self-center text-lg font-bold tracking-tight leading-5 text-blue-900 max-md:max-w-full">
        Download Certificate
      </div>
      <div className="flex gap-5 justify-center pr-3.5 mt-16 max-md:mt-10">
        <img
          alt="img"
          loading="lazy"
          srcSet={userData.profile_photo_url || ProfilePic}
          className="shrink-0 max-w-full rounded-full border-white border-solid aspect-square border-[3px] w-[100px]"
        />
        <img
          alt="img"
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/fbfdcb20d374b08cb7f8143365274d58abbb40898a0df8ffc7b6f58e65f70159?"
          className="shrink-0 aspect-[0.93] w-[93px]"
        />
      </div>
      <div className="mt-6 text-base tracking-tight leading-4 text-neutral-600">
        Download Certificate of Verification
      </div>
      <button onClick={handlesDownloadButton}
        className="flex gap-3 justify-center px-9 py-4 mt-2.5 text-base font-semibold text-white whitespace-nowrap bg-blue-900 rounded-[30px] max-md:px-5">
        <img
          alt="img"
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/8034edf1ccbf2f871fb45b3a79b2b2dce4997ebdc78b6cdc2a7e4b96490a8583?"
          className="shrink-0 self-start aspect-[0.81] fill-white w-[13px]"
        />

        Download
      </button>
      {loading && (
        <div className="flex items-center py-3 text-blue-900 px-14">
          Processing...
        </div>
      )}
      {error && (
        <div className="flex items-center py-3 text-red-900 px-14">
          {error}
        </div>
      )}
    </div>
  );
}

export default Certificate;
