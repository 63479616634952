import React, { useState } from "react";
import BannerProfile from "../../components/Dashboard/BannerProfile";
import SidebarDesktop from "../../components/Dashboard/SidebarDesktop";
import useEarnings from "../../feature/dashboard/earningsAndReferrals/useEarnings";
import ShareButton from "../../components/Dashboard/ShareButton";
import CopyToClipboard from "../../utils/CopyToClipboardHelper";

function EarningAndReferrals() {
  const {
    success, error, loading,
    copied, setCopied,
    userData, earningsData,
  } = useEarnings();

  const [isModalOpen, setIsModalOpen] = useState(false);


  return (
    <div className="min-h-[89vh]">
      <div className="">
        <BannerProfile data={userData} styling={`hidden md:inline-flex`} />
        <div className="flex flex-col max-w-[809px] mt-6">
          <div className="w-full text-base font-bold leading-4 tracking-tight text-neutral-600 max-md:max-w-full">
            Refer and Earn
          </div>
          <div className="flex justify-between items-center gap-5 p-4 mt-4 text-lg leading-5 border border-solid rounded-2xl border-neutral-300 max-md:flex-wrap">
            <div className="underline text-neutral-600 whitespace-wrap">
              {earningsData.referral_link}
            </div>
            <div className="flex gap-4 text-sky-600">
              <button className="flex gap-2"
                onClick={() => {
                  CopyToClipboard(earningsData.referral_link + "&t=c");
                  setCopied(!copied);
                }}
              >
                <img
                  alt="img"
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/69f71e155c9b10b8eb99c162eec1b9b2f9e98a53a32dd6410a4e33f5b1b1dce8?"
                  className="w-4 my-auto shrink-0 aspect-square"
                />
                {copied ? 'Copied' : 'Copy'}
              </button>
              <ShareButton url={earningsData.referral_link + "&t=s"} text='' />
            </div>
          </div>
          <div className="w-full mt-8 text-base font-bold leading-4 tracking-tight text-neutral-600 max-md:max-w-full">
            My Referrals
          </div>
          <div className="flex gap-4 px-5 mt-4 items-center max-md:flex-wrap">
            <div className="flex gap-2 whitespace-nowrap">
              <img
                alt="img"
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/73d29bae426d15d088f7f4f1bc6bd887d2ccbf97652116291b1428f7a1e7f640?"
                className="w-10 my-auto shrink-0 aspect-square"
              />
              <div className="flex flex-col flex-1">
                <div className="text-base leading-4 tracking-tight text-neutral-600">
                  Referral
                </div>
                <div className="text-2xl font-bold leading-7 text-teal-400">
                  {earningsData.referrals || 0}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-base leading-4 tracking-tight text-neutral-600">
                Badge Draw Amount
              </div>
              <div className="text-2xl font-bold leading-7 text-teal-400">
                {earningsData.badge_draw_amount || 0}
              </div>
            </div>
            <div className="flex flex-col justify-center flex-1">
              <div className="text-base leading-4 tracking-tight text-neutral-600">
                Total Amount
              </div>
              <div className="text-2xl font-bold leading-7 text-teal-400">
                {earningsData.referral_amount || 0}
              </div>
            </div>
            <div className="flex flex-1 gap-2">
              <img
                alt="img"
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e53922a16acc76bce8873aed0f1ce7ddc397e3e62ce7cb8faa082c92bbff4660?"
                className="w-10 my-auto shrink-0 aspect-square"
              />
              <div className="flex flex-col flex-1">
                <div className="text-base leading-4 tracking-tight text-neutral-600">
                  Total Earned
                </div>
                <div className="text-2xl font-bold leading-7 text-green-700">
                  {earningsData.total_earned || 0}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-4 text-base font-medium leading-4 tracking-tight text-neutral-600 max-md:max-w-full">
            Terms and Conditions
          </div>
          <div className="w-full mt-4 text-sm font-light leading-5 tracking-tight text-neutral-700 max-md:max-w-full">
            rem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget
            lobortis nulla, accumsan posuere dolor. Sed congue leo eros, nec
            tempor leo rutrum id. Donec lacinia lacus nec neque eleifend
            vestibulum. Donec vel porta purus, ac dignissim purus. Cras maximus
            nec tellus quis auctor. Nulla dignissim elit ut dui porttitor, a
            viverra dolor pellentesque. Curabitur vitae tincidunt neque. Proin
            et efficitur felis, at viverra turpis. Praesent blandit semper nisl
            eu dictum. Pellentesque in augue quis erat dapibus pellentesque a
            non quam. Vestibulum neque nunc, dignissim ornare mi vitae,
            ultricies feugiat elit. Maecenas orci est, pharetra sed dui vitae,
            consectetur finibus felis. Cras eget est tincidunt, imperdiet ligula
            non, pharetra mauris. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Mauris lectus mauris, tincidunt ut fermentum eu,
            egestas mollis dui.
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EarningAndReferrals;
