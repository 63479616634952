import { useState } from "react";
import FormattedDateComponent from "../../FormattedDateComponent";
import { MdExpandLess } from "react-icons/md";
import { MdExpandMore } from "react-icons/md";

const WorkHistoryItem = ({ data, index, onEdit = null, onDelete = null, showControls = true }) => {

  const [showLess, setShowLess] = useState(true);
  const toggleShowMore = () => {
    setShowLess(!showLess);
  }

  return (
    <div className="w-full">
      {/* get Data and loop it here */}
      <div
        key={index}
        className="flex gap-3 justify-between mt-7 max-md:flex-wrap max-md:pr-5 max-md:max-w-full"
      >
        <div className="flex gap-2">
          {/* logo of work  */}
          <img
            alt="img"
            loading="lazy"
            src={data.company_logo || "https://cdn.builder.io/api/v1/image/assets/TEMP/176be2ea66493ab805bd3d9e5c7cd1f706a0de3766487bde8e82a9332aba88cd?"}
            className="shrink-0 aspect-square w-[55px]"
          />
          {/* work header  */}
          <div className="flex flex-col">
            <div className="flex gap-2">
              {/* POSITION  */}
              <div className="text-lg font-bold leading-7 text-devRegDarkBlue">
                {JSON.stringify(data.position)}
              </div>

              {/* TYPE OF WORK */}
              <div className="justify-center p-2 text-xs text-sky-600 border border-sky-600 border-solid bg-sky-600 bg-opacity-10 rounded-[100px]">
                {data.work_arrangement}
              </div>
            </div>
            {/* Company and date joined */}
            <div className="mt-1 text-base leading-6 text-neutral-600">
              {data.company_name} | <FormattedDateComponent datetime={data.date_started} /> - <FormattedDateComponent datetime={data.date_ended} />
            </div>
          </div>
        </div>

        {/* actions in this section  */}
        <div className="flex gap-5 px-0.5 my-auto text-lg font-medium text-devRegDarkBlue">
          {showControls &&
            <><button onClick={() => onEdit(data.id)}>Edit</button> | <button onClick={() => onDelete(data.id)}>Delete</button></>
          }
          {showLess && <MdExpandLess size={30} onClick={() => toggleShowMore()} />}
          {!showLess && <MdExpandMore size={30} onClick={() => toggleShowMore()} />}
        </div>
      </div>

      {/* DESCRIPTION OF WORK */}
      <div className={`mt-4 text-base leading-6 text-neutral-600 max-md:max-w-full ${showLess ? 'inline-block' : 'hidden'}`}>
        {data.job_description}
      </div>

      {(data.projects && data.projects.length > 0) &&
        <div className={`${showLess ? 'inline-block' : 'hidden'}`}>
          <div className="mt-4 text-base font-bold leading-6 text-neutral-600 max-md:max-w-full">
            Projects
          </div>

          {/* PROJECTS showcase */}
          <div className="mt-4 max-md:max-w-full">
            {/* project in this company container main */}
            <div className="grid grid-cols-2 max-md:flex-col max-md:gap-0">
              {/* project loophere */}
              {data.projects.map((project, index) => (
                <div
                  key={index}
                  className="flex flex-col ml-5 max-md:ml-0 max-md:w-full"
                >
                  <div className="flex flex-col grow text-neutral-800 max-md:mt-2.5">
                    <img
                      alt="img"
                      loading="lazy"
                      src={project.thumbnail_path}
                      className="w-full aspect-[1.89]"
                    />
                    <div className="mt-2 text-base font-bold leading-6">
                      {project.title}
                    </div>
                    <div className="text-xs">{project.category}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default WorkHistoryItem;