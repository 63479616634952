import React, { useState } from "react";
import Modal from "react-modal";
import loadng2 from "../assets/loading2.gif";
function Loader({ loadingText, isOpen }) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={!isOpen}
        contentLabel="Example Modal"
        className="modal max-w-[300px] m-auto h-full flex flex-col justify-center items-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <div className=" py-7 bg-white rounded-xl flex justify-center items-start flex-col px-14">
          <div className="m-auto">
            <img src={loadng2} alt="loader" className="w-[120px]" />
            <div className="text-center mt-3 font-bold text-black80 text-sm18">
              {loadingText}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Loader;
