import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { changePassword,setChangePasswordError } from "../../../feature/dashboard/professionalProfile/settings/securitySettingsSlice";

function ChangePassword() {
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isFading, setIsFading] = useState(false);

  const dispatch = useDispatch();
  const securitySettings = useSelector((state) => state.settings.changePassword || {
    success: null,
    error: null,
    loading: false
  });

  useEffect(() => {
    
    if (isFading) {
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isFading]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      setPassword(value);
      setIsLengthValid(value.length >= 8);
      setHasUpperCase(/[A-Z]/.test(value));
      setHasLowerCase(/[a-z]/.test(value));
      setHasNumber(/\d/.test(value));
      setHasSpecialChar(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value));
      setPasswordsMatch(value === confirmPassword);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    }
  };

  const handleChangePasswordRequest = async (e) => {
    e.preventDefault();

    if(oldPassword === password){
      await dispatch(setChangePasswordError("New password cannot be the same with old password"));
      return;
    }
    
    const formData = {
      password: oldPassword,
      new_password: password,
      new_password_confirmation: confirmPassword,
    };

    const uData = JSON.parse(localStorage.getItem("user"));
    try {
      const response = await dispatch(changePassword({ id: uData.id, data: formData }));
      if (response.payload?.message === 'Password changed successfully') {
        setIsFading(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="min-h-[89vh]">
      <div className="w-full">
        <div className="flex flex-col px-8 max-md:px-5 max-md:mt-8 max-md:max-w-full">
          <div className="flex flex-row items-center justify-between">
            <div className="text-base leading-4 tracking-tight text-neutral-600 max-md:max-w-full">
              <span onClick={() => navigate("/dashboard/account-settings")} className="cursor-pointer hover:underline hover:text-blue-500">
                Security Settings
              </span> &gt; Change Password
            </div>
            {securitySettings.success && isFading && (
              <div className="flex flex-row justify-start font-bold text-green-700">
                <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
                &nbsp;Password Saved
              </div>
            )}
          </div>
          <div className="mt-6 text-base leading-4 tracking-tight text-neutral-600 max-md:max-w-full">
            Password
          </div>
          <div className="mt-6 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-2/5 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col text-base leading-5 grow placeholder-text-stone-300 max-md:mt-6">
                  <form onSubmit={handleChangePasswordRequest}>
                    <input
                      className="items-start justify-center px-4 py-3 border border-solid rounded-xl border-neutral-400 max-md:pr-5"
                      placeholder="Old Password"
                      value={oldPassword}
                      type="password"
                      name="oldPassword"
                      onChange={(e) => setOldPassword(e.target.value)}
                      autoComplete="on"
                    />
                    <input
                      className="items-start justify-center px-4 py-3 mt-4 border border-solid rounded-xl border-neutral-400 max-md:pr-5"
                      placeholder="New Password"
                      value={password}
                      type="password"
                      name="password"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <input
                      className={`${
                        passwordsMatch ? "border-neutral-400" : "border-red-600"
                      } items-start justify-center px-4 py-3 mt-4 border border-solid rounded-xl max-md:pr-5`}
                      placeholder="Confirm New Password"
                      value={confirmPassword}
                      onChange={handleChange}
                      type="password"
                      name="confirmPassword"
                      autoComplete="off"
                    />
                    <div className="flex gap-4 mt-4 text-base font-semibold text-white cursor-pointer">
                      <button
                        type="button"
                        onClick={() => navigate("/dashboard/account-settings")}
                        className="justify-center px-6 py-2 whitespace-nowrap bg-sky-600 rounded-3xl max-md:px-5"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className={`flex-1 justify-center px-6 py-2 bg-blue-900 text-center rounded-3xl max-md:px-5 cursor-pointer ${
                          (passwordsMatch && password.trim() !== '') ? "" : "opacity-50 pointer-events-none"
                        }`}
                        disabled={!passwordsMatch || securitySettings.loading}
                      >
                        Save Password
                      </button>
                    </div>
                  </form>
                  {securitySettings.error && (
                    <div className="mt-2 text-red-600">{securitySettings.error}</div>
                  )}
                  {securitySettings.loading && (
                    <div className="mt-2 text-blue-600">Processing...</div>
                  )}
                </div>
              </div>
              <div className="flex flex-col w-3/5 ml-5 max-md:ml-0 max-md:w-full">
                <div className="w-full mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                  Your password should:
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                    isLengthValid ? "text-green-700" : "text-zinc-600"
                  }`}
                >
                  {isLengthValid ? (
                    <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto">
                    Be at least 8 characters in length
                  </div>
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                    hasUpperCase && hasLowerCase ? "text-green-700" : "text-zinc-600"
                  }`}
                >
                  {hasUpperCase && hasLowerCase ? (
                    <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto">
                    Contain both upper and lowercase alphabetic characters (e.g. A-Z, a-z)
                  </div>
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                    hasNumber ? "text-green-700" : "text-zinc-600"
                  }`}
                >
                  {hasNumber ? (
                    <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto max-md:max-w-full">
                    Have at least one numerical character (e.g. 0-9)
                  </div>
                </div>
                <div
                  className={`flex justify-center align-center gap-2 py-px pr-5 mt-2 text-sm ${
                    hasSpecialChar ? "text-green-700" : "text-zinc-600"
                  }`}
                >
                  {hasSpecialChar ? (
                    <CiCircleCheck size={21} className="min-w-[20px] max-w-[20px]" />
                  ) : (
                    <div className="shrink-0 rounded-full border border-red-600 border-solid max-h-[16px] min-h-[16px] stroke-[1px] min-w-[16px] max-w-[16px] ml-[2px] mt-[3px]" />
                  )}
                  <div className="flex-auto">
                    Have at least one special character (e.g. ~!@#$%^&*()_-+=)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
