import { useNavigate } from "react-router-dom";

const SuccessMessage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center pt-6 bg-white">
      <div className="flex justify-center items-center px-16 mt-36 max-w-full text-2xl font-bold tracking-tight leading-7 text-blue-900 w-[709px] max-md:px-5 max-md:mt-10">
        <div className="flex gap-4 max-md:flex-wrap">
          <img
            alt="img"
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/130a6fcb17e55e04e07bba886a25c39897912092fad3cee535277136d7410811?apiKey=3ae96302e69649f5be368f89230b0596&"
            className="shrink-0 my-auto w-6 aspect-square"
          />
          <div>Additional Requirements Uploaded</div>
        </div>
      </div>
      <div className="mt-4 text-base leading-6 text-center text-neutral-600 max-md:max-w-full">
        You successfully uploaded your additional requirements.
      </div>
      <div className="shrink-0 mt-4 max-w-full h-px border border-solid bg-neutral-100 border-neutral-100 w-[411px]" />
      <div
        onClick={() => navigate("/")}
        className="cursor-pointer justify-center px-6 py-3 mt-4 text-base font-semibold text-white bg-blue-900 rounded-2xl max-md:px-5"
      >
        Go to Homepage
      </div>
    </div>
  )
}

export default SuccessMessage