import { CiCircleAlert } from "react-icons/ci";

const FailureIndicator = ({ message, styling = "", max = null }) => {


  return (
    <div className={`flex flex-row ml-auto gap-2 pr-px text-base font-medium tracking-tight leading-5 text-red-700 max-md:flex-wrap max-md:pl-5 ${styling}`}>
      <CiCircleAlert className="shrink-0 my-auto w-4 aspect-square" />
      <div>{(max !== null && message.length >= max) ? message.slice(0, max) + '...' : message}</div>
    </div>
  )
}

export default FailureIndicator;