import React, { useEffect, useState } from "react";
import useSkills from "../../../feature/dashboard/professionalProfile/profileEdit/skills/useSkills";
import useSkillsValidation from "../../../feature/dashboard/professionalProfile/profileEdit/skills/useSkillsValidation";
import SuccessIndicator from "../../../components/SuccessIndicator";
import FailureIndicator from "../../../components/FailureIndicator";
import Bubble from "../../../components/Bubble";
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputValidationMessage from "../../../components/InputValidationMessage";

function SkillsExpertise() {

  const {
    success, error, loading,
    typed, setTyped,
    displayedSkills,
    handleOnload,
    handleAddbutton,
    handleRemoveButton,
    handleTyping,
  } = useSkills();

  const { validationErrorMessage, showNotif, isSafeToSubmit, validateSkill, } = useSkillsValidation();

  useEffect(() => {
    handleOnload();
  }, []);

  return (
    <>
      <div className="flex flex-col pb-20 max-md:mt-8 max-md:max-w-full">
        <div className="flex justify-between gap-4 text-base tracking-tight max-md:flex-wrap">
          <div className="text-neutral-600">
            Add Skills and Expertise
          </div>
          <div className="flex justify-end items-center">
            {
              showNotif && (
                <>
                  {loading && (<LoadingIndicator loading={loading} styling="text-base" />)}
                  {!loading && success && <SuccessIndicator message="Account updated." />}
                  {!loading && !success && error && <FailureIndicator message={error || "Account not updated."} />}
                </>
              )
            }
          </div>
        </div>
        <div className="flex flex-wrap content-start p-4 mt-4 text-sm leading-4 rounded-2xl border border-solid border-neutral-400 text-neutral-600 max-md:pr-5 max-md:max-w-full">
          <input className="justify-center px-5 py-2 rounded-2xl bg-neutral-100"
            placeholder="Add Skill"
            type="text"
            autoComplete="on"
            value={typed}
            onChange={(event) => {
              handleTyping(event.target.value);
              validateSkill(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && isSafeToSubmit === true) {
                handleAddbutton(event.target.value);
                setTyped("");
              }
            }}
          />
          {Array.isArray(displayedSkills) &&
            displayedSkills.map((e, i) => {
              return <Bubble key={i} title={e.name} handleRemove={() => handleRemoveButton(e.id)}></Bubble>
            })
          }
        </div>
        <InputValidationMessage showNotif={showNotif} message={validationErrorMessage?.skill?.errorMessage} />
      </div>
    </>
  );
}

export default SkillsExpertise;
