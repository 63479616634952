import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMenu, IoClose } from "react-icons/io5";
import logonav from "../assets/logo.svg";
import CustomLink from "./CustomLink";

const navLinks = [
  { text: "Why Register?", to: "/why-register" },
  { text: "Benefit Page", to: "/benefits" },
  { text: "About Profile", to: "/about-profile" },
  { text: "Requirements", to: "/requirements" },
  { text: "About Us", to: "/about-us" },
  { text: "FAQ", to: "/faq" },
  { text: "Contact", to: "/contact" },
  // { text: "Forum", to: "/forum" },
];

function Navbar({ hideNavLinks }) {
  const [showNavLinks, setShowNavLinks] = useState(false);
  const navigate = useNavigate();
  const toggleNavLinks = () => {
    setShowNavLinks(!showNavLinks);
  };

  return (
    <>
      <header className="max-w-[1366px] m-auto flex justify-between items-center max-md:flex-wrap max-md:max-w-full max-sm:px-2 max-sm:py-2 px-6 py-3">
        <img
          loading="lazy"
          src={logonav}
          alt="Company logo"
          className="my-auto max-w-full w-[220px] cursor-pointer max-sm:w-[170px] max-[378px]:w-[120px]"
          onClick={() => navigate("/")}
        />
        {!hideNavLinks && (
          <>
            <nav className="max-md:flex-wrap flex flex-row max-sm:hidden max-[900px]:hidden cursor-pointer font-semibold px-4">
              {navLinks.map((link, index) => (
                <CustomLink
                  key={index}
                  to={link.to}
                  className="px-3 text-base text-center cursor-pointer max-lg:text-sm max-2xl:text-md"
                >
                  {link.text}
                </CustomLink>
              ))}
            </nav>
            <div className="flex gap-4 max-sm:gap-2">
              <CustomLink
                to="/login"
                className="my-auto text-lg text-blue-900 font-bold cursor-pointer max-sm:text-[14px] max-[378px]:text-[12px]"
              >
                Login
              </CustomLink>
              <button
                className="text-white bg-[#1C3775] max-sm:px-3 max-sm:py-1 max-sm:text-[14px] px-4 py-2 rounded-full font-semibold max-[378px]:text-[12px]"
                onClick={() => navigate("/register")}
              >
                Register Now!
              </button>
              <div className="self-stretch my-auto text-lg text-blue-900 font-bold min-[900px]:hidden ">
                {showNavLinks ? (
                  <IoClose size={27} onClick={() => toggleNavLinks()} />
                ) : (
                  <IoMenu size={27} onClick={() => toggleNavLinks()} />
                )}
              </div>
            </div>
          </>
        )}
      </header>

      {!hideNavLinks && (
        <>
          {showNavLinks && (
            <div className="w-full bg-white absolute z-[1000] py-4 transition-transform delay-150 ease-in min-[900px]:hidden">
              <nav
                className={`flex flex-col gap-4 cursor-pointer font-normal text-md pl-6`}
              >
                {navLinks.map((link, index) => (
                  <CustomLink
                    key={index}
                    to={link.to}
                    className="cursor-pointer"
                    onClick={() => toggleNavLinks()}
                  >
                    {link.text}
                  </CustomLink>
                ))}
              </nav>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Navbar;
