import React, { useEffect, useState } from "react";
import { FaImage } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";

import Modal from "react-modal";
import { MdUploadFile } from "react-icons/md";
import useUploadDocument from "../../api/useUploadDocument";
import FacialRecognition from "./FacialRecognition";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import Loader from "../../components/Loader";

function UploadDocuments() {
  const {
    isModalOpen,
    isCheckboxChecked,
    setIsCheckboxChecked,
    isCheckboxChecked2,
    setIsCheckboxChecked2,
    openModal,
    closeModal,
    fileListID,
    fileListUtilityBill,
    fileListSelfie,
    onFileIdDrop,
    onFileUtilityBillDrop,
    onFileSelfieDrop,
    removeSelectedFileID,
    removeUtilityBillFile,
    removeSelfieFile,
    handleNextBtn,
    openFacialRecognition,
    qrCodeDataUser,
    handleBackBtn,
    docType,
    isModalOpen2,
  } = useUploadDocument();

  const auth = useSelector((state) => state.auth);
  const [isFading, setIsFading] = useState(false);

  const [isFadingID, setIsFadingID] = useState(false);
  const [isFadingDOC, setIsFadingDOC] = useState(false);
  const [isFadingSP, setIsFadingSP] = useState(false);

  const [cmpError, setCmpError] = useState(null);
  const [iShouldRedirect,setIShouldRedirect] = useState(false);

  useEffect(() => {
    setIsFading(true);

    setTimeout(() => {
      setIsFading(false);
    }, 15000);

    if (isFadingID && auth.file.upload.error) {
      setTimeout(() => {
        setIsFadingID(false);
      }, 7000);
    }

    if (isFadingDOC && auth.file.upload.error) {
      setTimeout(() => {
        setIsFadingDOC(false);
      }, 7000);
    }

    if (isFadingSP && auth.file.upload.error) {
      setTimeout(() => {
        setIsFadingSP(false);
      }, 7000);
    }

    const uData = JSON.parse(localStorage.getItem('user'));
    if(!auth.loading && +uData.is_document_uploaded === 1){
      setIShouldRedirect(true);
    }

  }, [auth.error, auth.file.upload.error,auth.loading]);

  const { shouldRedirect, redirectTo } = useInspectRoute(
    "/register/upload-documents"
  );
  if (shouldRedirect) {
    return <Navigate to={redirectTo} />;
  }
  if (iShouldRedirect || openFacialRecognition) {
    return <Navigate to="/register/face-recognition" />;
  }

  return (
    <>
      {!openFacialRecognition && (
        <>
          <div className="flex flex-col mx-auto mt-8 mb-12 max-w-[750px] max-sm:px-10">
            <div className="px-10">
              <div className="font-bold text-[#1c3775] text-2xl whitespace-nowrap">
                Document Requirements
              </div>

              <div className="mt-3 text-lg font-bold text-neutral-600">
                Two valid goverment IDs
              </div>
              <div className="mt-2 text-md text-neutral-700">
                Provide Passport, Driver's License, or National ID.
              </div>
              <div className="container relative mt-2 border-2 border-neutral-400 rounded-2xl hover:opacity-80">
                <div className="py-4 text-neutral-400">
                  <div className="flex items-center justify-center text-lg text-center">
                    Drag & Drop or{" "}
                    <span className="text-sky-500"> &nbsp; choose &nbsp;</span>{" "}
                    image to upload <MdUploadFile className="text-[40px]" />
                  </div>
                </div>
                <input
                  type="file"
                  value=""
                  onChange={(e) => {
                    setIsFadingID(true);
                    onFileIdDrop(e);
                  }}
                  className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                />
              </div>
              <div className="mt-2 text-neutral-400">{`Note: (India) Aadhaar Card must be used.`}</div>

              <div className="flex items-center gap-4 mt-2">
                {fileListID.length > 0 &&
                  fileListID.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center bg-[#f8f8f8] px-4 py-2 gap-4 rounded-md"
                    >
                      <FaImage className="text-[#0d88be]" />
                      <div>
                        {file.file.name.length > 12
                          ? `${file.file.name.slice(0, 12)}...`
                          : file.file.name}
                      </div>
                      <IoMdClose
                        onClick={() =>
                          removeSelectedFileID(index, "VALID_ID", file.id)
                        }
                        className="cursor-pointer"
                      />
                    </div>
                  ))}
                {docType === "VALID_ID" && auth.file.upload.loading && (
                  <p className="text-sky-500">Uploading...</p>
                )}
                {docType === "VALID_ID" &&
                  auth.file.upload.error &&
                  isFadingID && (
                    <p className="text-red-500">{auth.file.upload.error}</p>
                  )}
                {docType === "VALID_ID" && auth.file.delete.loading && (
                  <p className="text-sky-500">Removing...</p>
                )}
                {docType === "VALID_ID" && auth.file.delete.error && (
                  <p className="text-red-500">{auth.file.delete.error}</p>
                )}
              </div>

              {/* Utility Bill */}
              <div className="mt-12 text-lg font-bold text-neutral-600">
                Utility Bill
              </div>
              <div className="mt-2 text-md text-neutral-700">
                Submit recent utility bills or statements with your address (no
                PO Boxes), dated within the last three months.
              </div>
              <div className="container relative mt-2 border-2 border-neutral-400 rounded-2xl hover:opacity-80">
                <div className="py-4 text-neutral-400">
                  <div className="flex items-center justify-center text-lg text-center">
                    Drag & Drop or{" "}
                    <span className="text-sky-500"> &nbsp; choose &nbsp;</span>{" "}
                    image to upload <MdUploadFile className="text-[40px]" />
                  </div>
                </div>
                <input
                  type="file"
                  value=""
                  onChange={(e) => {
                    setIsFadingDOC(true);
                    onFileUtilityBillDrop(e);
                  }}
                  className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                />
              </div>

              <div className="flex items-center gap-4 mt-2">
                {fileListUtilityBill.length > 0 &&
                  fileListUtilityBill.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center bg-[#f8f8f8] px-4 py-2 gap-4 rounded-md"
                    >
                      <FaImage className="text-[#0d88be]" />
                      <div>
                        {file.file.name.length > 12
                          ? `${file.file.name.slice(0, 12)}...`
                          : file.file.name}
                      </div>
                      <IoMdClose
                        onClick={() =>
                          removeUtilityBillFile(index, "DOCUMENT", file.id)
                        }
                        className="cursor-pointer"
                      />
                    </div>
                  ))}

                {docType === "DOCUMENT" && auth.file.upload.loading && (
                  <p className="text-sky-500">Uploading...</p>
                )}
                {docType === "DOCUMENT" &&
                  auth.file.upload.error &&
                  isFadingDOC && (
                    <p className="text-red-500">{auth.file.upload.error}</p>
                  )}
                {docType === "DOCUMENT" && auth.file.delete.loading && (
                  <p className="text-sky-500">Removing...</p>
                )}
                {docType === "DOCUMENT" && auth.file.delete.error && (
                  <p className="text-red-500">{auth.file.delete.error}</p>
                )}
              </div>
              {/*selfie */}

              <div className="mt-12 text-lg font-bold text-neutral-600">
                Selfie
              </div>
              <div className="mt-2 text-md text-neutral-700">
                Upload a selfie, ensuring the image is clear and high-quality.
              </div>
              <div className="container relative mt-2 border-2 border-neutral-400 rounded-2xl hover:opacity-80">
                <div className="py-4 text-neutral-400">
                  <div className="flex items-center justify-center text-lg text-center">
                    Drag & Drop or{" "}
                    <span className="text-sky-500"> &nbsp; choose &nbsp;</span>{" "}
                    image to upload <MdUploadFile className="text-[40px]" />
                  </div>
                </div>
                <input
                  type="file"
                  value=""
                  onChange={(e) => {
                    setIsFadingSP(true);
                    onFileSelfieDrop(e);
                  }}
                  className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                />
              </div>

              <div className="flex items-center gap-4 mt-2">
                {fileListSelfie.length > 0 &&
                  fileListSelfie.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center bg-[#f8f8f8] px-4 py-2 gap-4 rounded-md"
                    >
                      <FaImage className="text-[#0d88be]" />
                      <div>
                        {file.file.name.length > 12
                          ? `${file.file.name.slice(0, 12)}...`
                          : file.file.name}
                      </div>
                      <IoMdClose
                        onClick={() =>
                          removeSelfieFile(index, "SELFIE_PHOTO", file.id)
                        }
                        className="cursor-pointer"
                      />
                    </div>
                  ))}

                {docType === "SELFIE_PHOTO" && auth.file.upload.loading && (
                  <p className="text-sky-500">Uploading...</p>
                )}
                {docType === "SELFIE_PHOTO" &&
                  auth.file.upload.error &&
                  isFadingSP && (
                    <p className="text-red-500">{auth.file.upload.error}</p>
                  )}
                {docType === "SELFIE_PHOTO" && auth.file.delete.loading && (
                  <p className="text-sky-500">Removing...</p>
                )}
                {docType === "SELFIE_PHOTO" && auth.file.delete.error && (
                  <p className="text-red-500">{auth.file.delete.error}</p>
                )}
              </div>
              {/* terms */}
              <div className="flex mt-4">
                <input
                  type="checkbox"
                  className="w-10 "
                  checked={isCheckboxChecked}
                  onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                />
                <div className="flex-1 ml-3">
                  I have read and accepted all{" "}
                  <span
                    className="text-[#0082BA] cursor-pointer"
                    onClick={() => {
                      openModal();
                    }}
                  >
                    Terms & Condition and Honesty and Transparency
                  </span>{" "}
                </div>
              </div>

              <div className="flex items-center justify-end w-full mt-10 ">
                {auth.loading && (
                  <p className="mr-auto text-blue-900">Please wait while we are verifying your documents...</p>
                )}
                {auth.error && isFading && (
                  <p className="mr-auto text-red-900">{auth.error}</p>
                )}
                <button
                  disabled={!isCheckboxChecked}
                  onClick={() => {
                    if (fileListID.length < 2) {
                      setCmpError("Please upload 2 valid ids");
                      return;
                    }
                    if (fileListUtilityBill.length === 0) {
                      setCmpError("Please upload utility billing document");
                      return;
                    }
                    if (fileListSelfie.length === 0) {
                      setCmpError("Please upload your selfie photo");
                      return;
                    }
                    handleNextBtn();
                  }}
                  className={`${
                    isCheckboxChecked ? "opacity-100" : "opacity-80"
                  } cursor-pointer items-center bg-[#1c3775] text-white px-20 py-2 rounded-full font-semibold text-lg`}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
              className="modal max-w-[700px] m-auto h-full flex flex-col justify-center items-center"
              overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            >
              <>
                <div className="flex flex-col px-9 py-9 mx-auto bg-white rounded-2xl shadow-lg max-w-[599px] max-md:px-5">
                  <div className="text-lg font-bold leading-7 tracking-tight text-blue-900 max-md:max-w-full">
                    Terms & Condition and Honesty and Transparency
                  </div>
                  <div className="flex flex-col p-4 mt-5 border border-solid rounded-2xl border-zinc-200 max-md:max-w-full">
                    <div className="text-sm font-bold leading-5 tracking-tight text-black text-opacity-80 max-md:max-w-full">
                      Terms & Condition
                    </div>
                    <div className="mt-2 text-sm font-light leading-4 tracking-tight text-neutral-600 max-md:max-w-full">
                      Integer vitae nisl sit amet odio elementum ultrices in
                      eget sem. Nunc consectetur pellentesque metus mattis
                      venenatis. Integer vitae nisl sit amet odio elementum
                      ultrices in eget sem. Nunc consectetur pellentesque metus
                      mattis venenatis. Integer vitae nisl sit amet odio
                      elementum ultrices in eget sem. Nunc consectetur
                      pellentesque metus mattis venenatis. Integer vitae nisl
                      sit amet odio elementum ultrices in eget sem. Nunc
                      consectetur pellentesque metus mattis venenatis. Integer
                      vitae nisl sit amet odio elementum ultrices in eget sem.
                      Nunc consectetur pellentesque metus mattis venenatis.
                      Integer vitae nisl sit amet odio elementum ultrices in
                      eget sem. Nunc consectetur pellentesque metus mattis
                      venenatis.Integer vitae nisl sit amet odio elementum
                      ultrices in eget sem. Nunc consectetur pellentesque metus
                      mattis venenatis. Integer vitae nisl sit amet
                    </div>
                  </div>
                  <div className="flex justify-center gap-2 mt-4 max-md:flex-wrap">
                    <div className="flex flex-col self-start justify-center">
                      <input
                        type="checkbox"
                        checked={isCheckboxChecked2}
                        name=""
                        id=""
                        className="w-8 mt-1 rounded-md shrink-0 bg-zinc-300"
                        onChange={() =>
                          setIsCheckboxChecked2(!isCheckboxChecked2)
                        }
                      />
                    </div>
                    <div className="flex-1 text-base tracking-tight leading-6max-md:max-w-full">
                      I have read the accepted all
                      <span className="text-sky-600">
                        {" "}
                        Terms & Condition and Honesty and Transparency
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-center gap-4 py-4 mt-2 text-base font-semibold text-white px-11 whitespace-nowrap max-md:flex-wrap max-md:px-5">
                    <div
                      onClick={closeModal}
                      className="flex-1 text-center px-4 py-2 bg-blue-900 rounded-[30px] max-md:px-5 cursor-pointer"
                    >
                      Close
                    </div>
                    <div
                      onClick={closeModal}
                      className="flex-1 text-center px-4 py-2 bg-blue-900 rounded-[30px] max-md:px-5 cursor-pointer"
                    >
                      Decline
                    </div>
                    <div
                      onClick={() => {
                        closeModal();
                        setIsCheckboxChecked(true);
                      }}
                      disabled={!isCheckboxChecked2}
                      className={`${
                        isCheckboxChecked2 ? "opacity-100" : "opacity-80"
                      } flex-1 text-center px-4 py-2 bg-blue-900 rounded-[30px] max-md:px-5 cursor-pointer`}
                    >
                      Accept
                    </div>
                  </div>
                </div>
              </>
            </Modal>
          )}
        </>
      )}

      <Loader loadingText={"Processing..."} isOpen={isModalOpen2} />
    </>
  );
}

export default UploadDocuments;
