import Footer from "../../components/Footer";
import useAccessKey from "../../feature/dashboard/uploadDocuments/accessKey/useAccessKey";
import VerifyAccessKey from "./verifyAccessKey";
import UploadAdditionalDocument from "./uploadAdditionalDocument";
import SuccessMessage from "./successMessage";

function AccessKey() {
  const {
    hasAccessKey, accessKeyAccepted,
    success, error, loading,
    accessKey, setAccessKey,
    addedIdToInput, addedBillingToInput,
    uploadedAll,
    handleApplyButton, handleContinueButton
  } = useAccessKey();

  return (
    <>
      {hasAccessKey && !accessKeyAccepted &&
        <VerifyAccessKey
          error={error}
          loading={loading}
          accessKey={accessKey}
          setAccessKey={setAccessKey}
          submitAccessKey={handleApplyButton} />
      }
      {(accessKeyAccepted && (!success || error?.length > 0)) &&
        <UploadAdditionalDocument
          succuss={success}
          error={error}
          loading={loading}
          uploadedAll={uploadedAll}
          addedIdToInput={addedIdToInput}
          addedBillingToInput={addedBillingToInput}
          handleContinue={handleContinueButton}
        />
      }
      {success && error?.length === 0 && (
        <SuccessMessage />
      )}
      <Footer />
    </>
  );
}

export default AccessKey;
