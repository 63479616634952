import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa";
import useLogin from "../../api/customHook/useLogin";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../feature/authSlice";
import Loader from "../../components/Loader";
// import useInspectRoute from "../../api/customHook/useInspectRoute";
function Login() {
  const navigate = useNavigate();
  const {
    mainImg,
    logoImg,
    fullName,
    setFullName,
    loginPass,
    setLoginPass,
    toggleEye,
    handleToggleEye,
    handleForgotPassword,
    handleSignUp,
    handleLogin,
    resendVerificationEmail,
    ismodalOpen,
  } = useLogin();

  const auth = useSelector((state) => state.auth);
  const [isFading, setIsFading] = useState(false);
  const dispatch = useDispatch();
  // const {shouldRedirect, redirectTo} = useInspectRoute('/login');

  useEffect(() => {
    dispatch(setError(null));
  }, [dispatch]);

  useEffect(() => {
    setIsFading(true);
    const timer = setTimeout(() => {
      setIsFading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [auth.error]);

  // if (shouldRedirect) {
  //   return <Navigate to={redirectTo} />;
  // }

  return (
    <div className="absolute flex flex-col items-center justify-center min-w-full min-h-screen py-12 main-container bg-sky-600">
      <div className="flex justify-center items-center px-18 max-md:py-10 py-6 bg-sky-600 max-md:px-3 max-sm:px-2.5 max-lg:px-2.5  max-sm:h-auto">
        <div className="mt-5 w-full rounded-3xl shadow-lg max-w-[1100px] max-md:max-w-full">
          {/* container */}
          <div className="flex max-md:flex-col max-md:gap-0">
            {/* login image */}
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full ">
              <img
                alt="img"
                loading="lazy"
                srcSet={mainImg}
                className="grow w-full max-sm:aspect-[1] max-md:aspect-[1.07] max-lg:aspect-[.8] max-md:max-w-full max-md:rounded-tr-[23px] max-md:rounded-tl-[23px] min-[768px]:rounded-bl-[25px] rounded-tl-[25px]"
              />
            </div>

            {/* Login Form */}
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full ">
              <div className="flex flex-col grow self-stretch px-12 py-12  w-full max-sm:py-6 rounded-br-[25px] min-[768px]:rounded-tr-[25px]  text-base bg-white max-md:px-5 max-md:max-w-full max-md:rounded-bl-[23px] max-md:rounded-br-[23px]">
                <img
                  onClick={() => navigate("/")}
                  alt="img"
                  loading="lazy"
                  src={logoImg}
                  className="self-center cursor-pointer max-w-full aspect-[3.85] w-[282px]"
                />

                <div
                  className="flex items-center justify-center gap-1 px-2 mt-8 font-semibold cursor-pointer text-sky-600"
                  onClick={() => {
                    navigate("/");
                    window.scrollTo(0, 0);
                  }}
                >
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/427a6dfb50348309db0c360c9956276b741614a052067d396517a639612574c7?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 self-start w-2.5 aspect-[0.63] fill-sky-600 m-auto "
                  />
                  <div className="flex-1">Back to Homepage</div>
                </div>
                <div className="mt-6 text-xl font-bold text-neutral-600">
                  Welcome to Full ID Check
                </div>
                <div className="mt-4 max-sm:mt-1 text-neutral-600">
                  Log in to your account by filling in the details below.
                </div>

                {auth.resend.success && (
                  <span className="text-sm text-green-900">
                    <br />
                    &nbsp;{auth.resend.success}
                  </span>
                )}
                {((auth.error && auth.error.match(/verified/gi) !== null) ||
                  auth.resend.error) &&
                  !auth.resend.success && (
                    <div className="mt-2 text-sm text-red-500">
                      {auth.error}
                      <span
                        className="border-b border-gray-500 cursor-pointer text-sky-600 "
                        onClick={resendVerificationEmail}
                      >
                        {" "}
                        resend link
                      </span>
                      {auth.resend.loading && (
                        <span className="text-sky-600">
                          <br />
                          &nbsp;Resending...
                        </span>
                      )}
                      {auth.resend.error && (
                        <span className="text-red-600">
                          <br />
                          &nbsp;{auth.resend.error}
                        </span>
                      )}
                    </div>
                  )}
                <form onSubmit={handleLogin}>
                  <input
                    className="justify-center items-start px-4 py-3 mt-4 rounded-xl border w-full border-solid border-neutral-400 leading-[121%] placeholder-text-stone-300 max-md:pr-5"
                    placeholder="Email"
                    value={fullName}
                    onChange={(fullName) => setFullName(fullName.target.value)}
                  />

                  <div className="relative w-full ">
                    <div
                      className="absolute right-3 top-[45%] cursor-pointer "
                      onClick={handleToggleEye}
                    >
                      {toggleEye ? (
                        <FaEye size={25} color="#BCBCBC" />
                      ) : (
                        <FaEyeSlash size={25} color="#BCBCBC" />
                      )}
                    </div>
                    <input
                      className="  px-5 py-2.5 mt-4 text-base leading-5 whitespace-nowrap rounded-xl border border-solid border-neutral-400 text-neutral-600 w-full"
                      placeholder="Password"
                      value={loginPass}
                      onChange={(pass) => setLoginPass(pass.target.value)}
                      type={toggleEye ? "text" : "password"}
                      autoComplete="on"
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={auth.loading}
                    className="items-center justify-center w-full py-3 mt-4 text-base font-semibold text-center text-white bg-blue-900 cursor-pointer px-9 whitespace-nowrap rounded-2xl max-md:px-5"
                  >
                    Login
                  </button>
                  {auth.loading && (
                    <p className="mt-1 text-blue-900">Logging in...</p>
                  )}
                  {auth.error && isFading && (
                    <p className="text-red-600">{auth.error}</p>
                  )}
                </form>
                <div
                  onClick={handleSignUp}
                  className="mt-4 text-sm text-center text-black cursor-pointer"
                >
                  Don’t have an account?
                  <span className="font-bold text-teal-400">Sign-up</span>
                </div>
                <div
                  className="justify-center items-center self-center px-16 py-0.5 mt-4 max-w-full text-sm text-center text-black text-opacity-80 w-[248px] max-md:px-5 max-sm:pb-10 cursor-pointer"
                  onClick={handleForgotPassword}
                >
                  Forgot Password?
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loadingText={"Logging in..."} isOpen={ismodalOpen} />
    </div>
  );
}

export default Login;
